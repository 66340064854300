import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import * as Text from 'components/_styles/Text.style';

export const Container = styled('div')`
  align-items: center;
  display: flex;
  gap: 8px;
  max-width: 600px;
  width: 100%;
`;

export const Cell = styled('div')`
  border-radius: 100px;
  flex: 1;
  height: 10px;
`;

export const CellComplete = styled(Cell)`
  background-color: ${colours.primary[500]};
`;

export const CellCurrent = styled(Cell)`
  background-color: ${colours.primary[500]};
  display: flex;
  height: unset;
  justify-content: center;
  padding: 5px;

  @media (max-width: ${device.mobileL}) {
    height: 10px;
    padding: 0;
  }
`;

export const CellCurrentText = styled(Text.CaptionSemiboldCap)`
  color: ${colours.shade[0]};

  @media (max-width: ${device.mobileL}) {
    display: none;
  }
`;

export const CellIncomplete = styled(Cell)`
  background-color: ${colours.primary[50]};
`;

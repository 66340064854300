import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { atcb_action as atcbAction } from 'add-to-calendar-button';
import { useTranslation } from 'react-i18next';

// assets
import { Time } from 'untitledui-js';

// styles
import * as Buttons from 'components/_styles/Buttons.style';

function SubscriptionConfirmButton({ icsFile }) {
  const { t } = useTranslation();

  const start = dayjs().format('YYYY-MM-DD');

  const handleClick = () => {
    atcbAction({
      name: 'Nurau Calendar',
      startDate: start,
      subscribe: true,
      icsFile,
      location: 'https://app.nurau.com',
      label: t('buttonCalendarSubscription:calendarLabel'),
      options: [
        'Apple',
        'Google',
        'iCal',
        'Microsoft365',
        'MicrosoftTeams',
        'Outlook.com',
        'Yahoo',
      ],
      timeZone: 'America/Montreal',
      trigger: 'click',
      iCalFileName: 'Reminder-Event',
    });
  };

  return (
    <Buttons.Secondary onClick={handleClick}>
      <Time.CalenderPlus01 />
      {t('buttonCalendarSubscription:calendarLabel')}
    </Buttons.Secondary>
  );
}

SubscriptionConfirmButton.propTypes = {
  icsFile: PropTypes.string.isRequired,
};

export default SubscriptionConfirmButton;

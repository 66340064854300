import React from 'react';
import PropTypes from 'prop-types';

// assets
import CheckIcon from '@mui/icons-material/CheckRounded';
import EventAvailableIcon from '@mui/icons-material/EventAvailableRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import StarIcon from '@mui/icons-material/Star';
import VideocamOutlineIcon from '@mui/icons-material/VideocamOutlined';

// styles
import * as Styled from 'components/progressIndicators/_styles/StatusIcon.style';

function StatusIcon({ label, status }) {
  const getStatus = () => {
    switch (status) {
      case 'pink':
        return (
          <Styled.PinkIcon>
            <MoreHorizIcon sx={{ color: 'white', width: '100%' }} />
          </Styled.PinkIcon>
        );
      case 'yellow':
        return (
          <Styled.YellowIcon>
            <MoreHorizIcon sx={{ color: 'white', width: '100%' }} />
          </Styled.YellowIcon>
        );
      case 'green':
        return (
          <Styled.GreenIcon>
            <CheckIcon sx={{ color: 'white', width: '100%' }} />
          </Styled.GreenIcon>
        );
      default:
        return null;
    }
  };
  const getIcon = () => {
    if (['book', 'booked', 'in progress', 'completed'].includes(label))
      return <EventAvailableIcon sx={{ width: '30px' }} />;
    if (['attend', 'attended'].includes(label))
      return <VideocamOutlineIcon sx={{ width: '30px' }} />;
    if (['review', 'reviewed'].includes(label))
      return <StarIcon sx={{ width: '30px' }} />;
    return null;
  };

  return (
    <Styled.Container>
      <Styled.IconsWrapper>
        {getIcon()}
        {getStatus()}
      </Styled.IconsWrapper>
      <Styled.Label>{label}</Styled.Label>
    </Styled.Container>
  );
}

StatusIcon.propTypes = {
  label: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default StatusIcon;

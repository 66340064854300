import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';

// components
import DetailsCard from 'components/pageCalendar/DetailsCard';

// helpers & hooks
import checkIfLive from 'helpers/checkIfLive';
import useUserLanguage from 'hooks/useUserLanguage';

function DetailsCardSupportAdmin({ details }) {
  const lang = useUserLanguage();
  const startDate = details.starts_at.toString();
  const isLive = checkIfLive(startDate);

  return (
    <DetailsCard
      bookingId={details.id}
      caption={details.timetable?.organization.name}
      dateString={dayjs(details.starts_at).locale(lang).format('LLL')}
      noCalendarOnPage={true}
      isLive={isLive}
      journeyDescription={
        details.microlearning.journeys[0][`description_${lang}`]
      }
      journeyId={details.microlearning.journeys[0].id}
      language={details.language}
      microlearningId={details.microlearning.id}
      microlearningTitle={details.microlearning[`title_${lang}`]}
      startDate={startDate}
    />
  );
}

export default DetailsCardSupportAdmin;

DetailsCardSupportAdmin.propTypes = {
  details: PropTypes.object.isRequired,
};

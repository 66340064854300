import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// components
import ActiveCardOwlLive from 'components/pageJourneyList/owlView/ActiveCardOwlLive';
import ActiveCardOwlUpcoming from 'components/pageJourneyList/owlView/ActiveCardOwlUpcoming';
import ApiLoading from 'components/_pageLayout/ApiLoading';
import InactiveCard from 'components/pageJourneyList/InactiveCard';

// helpers
import checkIfLive from 'helpers/checkIfLive';
import isFinished from 'helpers/isFinished';

// hooks
import useApi from 'hooks/useApi';

function ActiveCardOwl({ journey }) {
  const { data, callApi } = useApi();

  useEffect(() => {
    callApi(`/owls/journeys/${journey.id}`);
  }, [callApi, journey.id]);

  if (journey.microlearnings.length === 0) return null;
  if (data.status === 'loading') return <ApiLoading />;
  if (data.response.active_timetables.length === 0)
    return <InactiveCard journey={data.response} />;
  return <ActiveCardView journey={data.response} />;
}

ActiveCardOwl.propTypes = {
  journey: PropTypes.object,
};

export default ActiveCardOwl;

function ActiveCardView({ journey }) {
  const [isLive, setIsLive] = useState(false);

  const compare = (a, b) => new Date(a.starts_at) - new Date(b.starts_at);
  const schedules = journey.active_timetables
    .map((activeTimetable) =>
      activeTimetable.schedules.map((schedule) => {
        schedule.organization = activeTimetable.organization?.name; // eslint-disable-line no-param-reassign
        return schedule;
      })
    )
    .flat()
    .sort(compare);

  const futureSchedules = schedules.filter(
    (schedule) => isFinished(schedule.starts_at) === false
  );

  useEffect(() => {
    if (checkIfLive(futureSchedules[0]?.starts_at)) setIsLive(true);
  }, [setIsLive, futureSchedules]);

  if (isLive)
    return (
      <ActiveCardOwlLive journey={journey} schedule={futureSchedules[0]} />
    );
  return (
    <ActiveCardOwlUpcoming journey={journey} schedules={futureSchedules} />
  );
}

ActiveCardView.propTypes = {
  journey: PropTypes.object,
};

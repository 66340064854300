import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { Maps, Time } from 'untitledui-js';

// components
import JoinLobbyButton from 'components/buttonJoinLobby/_index';

// constants
import userRoles from 'constants/userRoles.json';

// hooks
import { useUser } from 'contexts/UserContext';

// styles
import * as Styled from 'components/pageCalendar/_styles/DetailsCard.style';
import * as Text from 'components/_styles/Text.style';

function DetailsCard(props) {
  const {
    bookingCount,
    bookingId,
    caption,
    dateString,
    noCalendarOnPage,
    isLive,
    journeyId,
    language,
    microlearningId,
    microlearningTitle,
  } = props;
  const { userRole } = useUser();
  const { t } = useTranslation();

  return (
    <Styled.Card noCalendarOnPage={noCalendarOnPage}>
      <Styled.TopSection>
        {caption && (
          <Text.CaptionSemiboldCap>{caption}</Text.CaptionSemiboldCap>
        )}
        {microlearningTitle && <Text.H5Bold>{microlearningTitle}</Text.H5Bold>}
        {userRole === userRoles.OWL && bookingCount()}
        {isLive && (
          <Styled.LiveIndicator>
            <Time.Clock />
            <Text.P2Semibold>{`${t(
              'pageCalendar:live'
            )} | ${dateString}`}</Text.P2Semibold>
          </Styled.LiveIndicator>
        )}
        {!isLive && dateString && (
          <Styled.DetailRow>
            <Time.Clock />
            <Text.P2Semibold>{`${dateString} (${dayjs.tz.guess()})`}</Text.P2Semibold>
          </Styled.DetailRow>
        )}
        {language && (
          <Styled.DetailRow>
            <Maps.Globe02 />
            <Text.P2Semibold>
              {language === 'en' ? 'English' : 'Français'}
            </Text.P2Semibold>
          </Styled.DetailRow>
        )}
      </Styled.TopSection>
      {isLive && (
        <JoinLobbyButton
          bookingId={bookingId}
          journeyId={journeyId}
          microlearningId={microlearningId}
          microlearningTitle={microlearningTitle}
        />
      )}
    </Styled.Card>
  );
}

export default DetailsCard;

DetailsCard.propTypes = {
  bookingCount: PropTypes.func,
  bookingId: PropTypes.string,
  caption: PropTypes.string,
  dateString: PropTypes.string,
  noCalendarOnPage: PropTypes.bool,
  isLive: PropTypes.bool,
  journeyId: PropTypes.string,
  language: PropTypes.string,
  microlearningId: PropTypes.string,
  microlearningTitle: PropTypes.string,
};

import { usePostHog } from 'posthog-js/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// components
import Chatbar from 'components/pageECoach/chatbar/Chatbar';
import ChatBubble from 'components/pageECoach/ChatBubble';
import ClickableInput from 'components/pageECoach/ClickableInput';
import Loader from 'components/pageECoach/Loader';

// context & hooks
import { useSessionContext } from 'contexts/ECoachSessionContext';
import useWebsocketSetup from 'hooks/useWebsocketSetup';

// styles
import * as ChatBubbleStyled from 'components/pageECoach/_styles/ChatBubble.style';
import * as InputStyle from 'components/pageECoach/_styles/MessageInputs.style';
import * as Text from 'components/_styles/Text.style';

// utils
import routesConfig from 'constants/routesConfig.json';
import {
  ChallengeVerificationState,
  SessionProgressChoices,
  OutputType,
  UserInputType,
  BinaryChoice,
} from './utils';

export default function ECoachChallengeVerification() {
  // #region Setup hooks call
  const { t } = useTranslation('pageEcoach');
  const posthog = usePostHog();

  const { session, updateSession } = useSessionContext();
  const [steps, setSteps] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stepsLoaded, setStepsLoaded] = useState(false);
  const [verificationState, setVerificationState] = useState(
    ChallengeVerificationState.IDLE
  );

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebsocketSetup(
    routesConfig.ECOACH.CHALLENGE_VERIFICATION_WS
  );
  // #endregion Setup hooks call

  const sendUserInput = (message, inputType) => {
    const userInputData = {
      message,
      input_type: inputType,
    };

    sendJsonMessage({
      action: 'evaluate',
      request_id: Date.now(),
      pk: session.id,
      data: {
        user_input: userInputData,
      },
    });

    // Temporarily add new user input as a step in order to display it while loading
    setSteps((prevSteps) => [...prevSteps, { user_inputs: [userInputData] }]);

    setIsLoading(true);
    setVerificationState(ChallengeVerificationState.IDLE);
  };

  // #region Initial prompt sending
  useEffect(() => {
    if (
      session?.progress !== SessionProgressChoices.CHALLENGE_VERIFICATION ||
      !stepsLoaded ||
      steps.length > 0 ||
      !session?.context?.description
    ) {
      return;
    }

    sendUserInput(session.context.description, UserInputType.INITIAL_PROMPT);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepsLoaded, session?.context?.description, sendJsonMessage, session.id]);
  // #endregion Initial prompt sending

  // #region Initial conversation loading
  useEffect(() => {
    if (readyState === WebSocket.OPEN && session?.id) {
      setIsLoading(true);

      sendJsonMessage({
        action: 'list',
        request_id: Date.now(),
        pk: session.id,
      });
    }
  }, [readyState, sendJsonMessage, session?.id]);
  // #endregion Initial conversation loading

  // #region Receive bot response
  useEffect(() => {
    if (lastJsonMessage === null) {
      return;
    }

    const { action, data } = lastJsonMessage;

    if (action === 'list') {
      setSteps(data);
      setStepsLoaded(true);
    } else if (action === 'evaluate') {
      setSteps((prevSteps) => {
        // Remove inputs where output was not received as it will be included with output
        const filteredSteps = prevSteps.filter(
          (step) => step.outputs !== undefined
        );
        return [...filteredSteps, data];
      });
    }

    setIsLoading(false);
  }, [lastJsonMessage]);
  // #endregion Receive bot response

  const lastOutput = useMemo(() => {
    for (let i = steps.length - 1; i >= 0; i -= 1) {
      if (steps[i]?.outputs) {
        return steps[i].outputs[0];
      }
    }
    return undefined;
  }, [steps]);

  useEffect(() => {
    if (session?.progress !== SessionProgressChoices.CHALLENGE_VERIFICATION) {
      setVerificationState(ChallengeVerificationState.IDLE);
    } else if (
      lastOutput?.output_type === OutputType.CHALLENGE_DESCRIPTION &&
      !isLoading
    ) {
      setVerificationState(ChallengeVerificationState.ASK_FOR_CONFIRMATION);
    } else if (
      lastOutput?.output_type === OutputType.CHALLENGE_VERIFICATION_QUESTION
    ) {
      setVerificationState(ChallengeVerificationState.ASK_FOR_ANSWER);
    } else if (
      lastOutput?.output_type ===
      OutputType.CHALLENGE_VERIFICATION_CONFIRMATION_DECLINE_FOLLOW_UP
    ) {
      setVerificationState(ChallengeVerificationState.ASK_FOR_FEEDBACK);
    }
  }, [session?.progress, lastOutput?.output_type, isLoading]);

  const onConfirm = () => {
    sendUserInput(
      BinaryChoice.YES,
      UserInputType.CHALLENGE_VERIFICATION_CONFIRMATION_ANSWER
    );
    updateSession(session?.id, {
      context: { description: lastOutput.message },
      progress: SessionProgressChoices.SUGGESTIONS,
    });
    setVerificationState(ChallengeVerificationState.IDLE);
    posthog?.capture('user_confirms_eCoach_challenge_description');
  };

  const onDecline = () => {
    sendUserInput(
      BinaryChoice.NO,
      UserInputType.CHALLENGE_VERIFICATION_CONFIRMATION_ANSWER
    );
    setVerificationState(ChallengeVerificationState.ASK_FOR_FEEDBACK); // TODO: We might not need this line anymore.
    posthog?.capture('user_declines_eCoach_challenge_description');
  };

  const onUserFollowUpQuestionAnswer = (message) => {
    sendUserInput(message, UserInputType.CHALLENGE_VERIFICATION_ANSWER);
    posthog?.capture('user_answers_eCoach_challenge_follow_up_question');
  };

  const onFeedbackSend = (message) => {
    sendUserInput(message, UserInputType.CHALLENGE_DESCRIPTION_FEEDBACK);
    posthog?.capture('user_sends_eCoach_challenge_description_feedback');
  };

  const messageCards = useMemo(
    () =>
      steps.map((step) => {
        const userInputCards = [];
        if (step.user_inputs) {
          userInputCards.push(
            Object.values(step.user_inputs).map((userInput, index) => (
              <ChatBubble
                key={index}
                simpleString={
                  userInput.input_type ===
                  UserInputType.CHALLENGE_VERIFICATION_CONFIRMATION_ANSWER
                    ? t(userInput.message) // Translate yes/no answer
                    : userInput.message
                }
                pointer={true}
                sentByUser
              />
            ))
          );
        }

        const outputCards = [];
        if (step.outputs) {
          outputCards.push(
            Object.values(step.outputs).map((output) => (
              <ChatBubble key={output.id} pointer={true} divId={output.id}>
                {output.output_type === OutputType.CHALLENGE_DESCRIPTION ? (
                  <>
                    <ChatBubbleStyled.DescriptionText>
                      {t('challengeVerification.challengeDescription')}
                    </ChatBubbleStyled.DescriptionText>
                    <Text.P2>{output.message}</Text.P2>
                  </>
                ) : (
                  <ChatBubbleStyled.DescriptionText>
                    {output.message}
                  </ChatBubbleStyled.DescriptionText>
                )}
              </ChatBubble>
            ))
          );
        }

        return [...userInputCards, ...outputCards];
      }),
    [steps, t]
  );

  const userControl = useMemo(() => {
    switch (verificationState) {
      case ChallengeVerificationState.ASK_FOR_ANSWER:
        return (
          <Chatbar
            autoFocus
            initialInput=""
            onSubmit={onUserFollowUpQuestionAnswer}
          />
        );
      case ChallengeVerificationState.ASK_FOR_CONFIRMATION:
        return (
          <ChatBubble pointer={true} sentByUser>
            <InputStyle.ButtonsColumn>
              <ClickableInput
                inputAction={onConfirm}
                inputText={t('Yes')}
                inputType="light"
              />
              <ClickableInput
                inputAction={onDecline}
                inputText={t('No')}
                inputType="light"
              />
            </InputStyle.ButtonsColumn>
          </ChatBubble>
        );
      case ChallengeVerificationState.ASK_FOR_FEEDBACK:
        return <Chatbar initialInput="" onSubmit={onFeedbackSend} />;
      default:
        return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationState]);

  return (
    <>
      {messageCards}
      {isLoading ? (
        <ChatBubble pointer={true} divId={'challengeVerificationLoader'}>
          <ChatBubbleStyled.DescriptionText>
            <Loader inline />
          </ChatBubbleStyled.DescriptionText>
        </ChatBubble>
      ) : (
        userControl
      )}
    </>
  );
}

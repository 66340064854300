import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import MemberFormAdd from 'components/pageOrganization/MemberFormAdd';

// styles
import Button from 'components/pageOrganization/_styles/AddMemberButton.style';
import * as Modal from 'components/_styles/Modal.style';

function AddMemberButton({ setAlert, setRefreshMembersList }) {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const handleAddMemberClick = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  return (
    <>
      <Button onClick={handleAddMemberClick} variant="contained">
        {t('pageOrganization:AddMemberButton')}
      </Button>
      {modalOpen && (
        <Modal.Background onClose={handleModalClose} open={modalOpen}>
          <div>
            <MemberFormAdd
              handleModalClose={handleModalClose}
              setAlert={setAlert}
              setRefreshMembersList={setRefreshMembersList}
            />
          </div>
        </Modal.Background>
      )}
    </>
  );
}

AddMemberButton.propTypes = {
  setAlert: PropTypes.func,
  setRefreshMembersList: PropTypes.func,
};

export default AddMemberButton;

import React from 'react';
import PropTypes from 'prop-types';

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : <>{children}</>;

ConditionalWrapper.propTypes = {
  condition: PropTypes.bool,
  wrapper: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default ConditionalWrapper;

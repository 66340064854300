import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import MemberFormDelete from 'components/pageOrganization/MemberFormDelete';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Forms from 'components/_styles/Forms.style';
import * as MemberCardStyled from 'components/pageOrganization/_styles/MemberCard.style';

const MemberFormUpdate = (props) => {
  const { deleteCallApi, hideEditInput, member, updateCallApi } = props;

  const { t } = useTranslation();

  const [firstName, setfirstName] = useState(member.user.first_name);
  const [lastName, setlastName] = useState(member.user.last_name);
  const [jobTitle, setJobTitle] = useState(member.job_title);

  const handleFirstNameChange = (event) => setfirstName(event.target.value);
  const handleLastNameChange = (event) => setlastName(event.target.value);
  const handleJobTitleChange = (event) => setJobTitle(event.target.value);

  const updateMember = (event) => {
    const params = {
      job_title: jobTitle,
      user: {
        email: member.user.email,
        first_name: firstName,
        last_name: lastName,
      },
    };
    updateCallApi(`/members/${member.id}/`, 'put', params);
    hideEditInput();
    event.preventDefault();
  };

  return (
    <MemberCardStyled.EditForm>
      <Forms.InlineLayout>
        <Forms.InlineInputWrapper>
          <Forms.InlineInputField
            label={t('pageOrganization:firstName')}
            onChange={handleFirstNameChange}
            placeholder={member.user.first_name}
            value={firstName}
          />
          <Forms.InlineInputField
            label={t('pageOrganization:lastName')}
            onChange={handleLastNameChange}
            placeholder={member.user.last_name}
            value={lastName}
          />
          <Forms.InlineInputField
            label={t('pageOrganization:jobTitle')}
            onChange={handleJobTitleChange}
            placeholder={member.job_title}
            value={jobTitle}
          />
        </Forms.InlineInputWrapper>
        <Forms.InlineActionArea>
          {firstName && lastName ? (
            <Buttons.Primary onClick={updateMember}>
              {t('pageOrganization:save')}
            </Buttons.Primary>
          ) : (
            <Buttons.PrimaryDisabled>
              {t('pageOrganization:save')}
            </Buttons.PrimaryDisabled>
          )}
          <MemberFormDelete deleteCallApi={deleteCallApi} member={member} />
        </Forms.InlineActionArea>
      </Forms.InlineLayout>
    </MemberCardStyled.EditForm>
  );
};

MemberFormUpdate.propTypes = {
  deleteCallApi: PropTypes.func,
  hideEditInput: PropTypes.func,
  member: PropTypes.object,
  updateCallApi: PropTypes.func,
};

export default MemberFormUpdate;

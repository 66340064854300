import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// assets
import Award from 'assets/award.png';

// components
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';

// context
import { useUser } from 'contexts/UserContext';

// constants
import routesConfig from 'constants/routesConfig.json';
import userRoles from 'constants/userRoles.json';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageJourneyList/_styles/ActiveCard.style';
import * as Text from 'components/_styles/Text.style';

function ActiveCard(props) {
  const {
    cta,
    currentMicrolearning,
    evaluationView,
    featuredInfo,
    journey,
    progressBar,
  } = props;
  const { t } = useTranslation();
  const lang = useUserLanguage();
  const { userRole } = useUser();

  const cover = () => {
    if (userRole === userRoles.MEMBER)
      return (
        <Styled.CoverLink
          to={{ pathname: `${routesConfig.JOURNEY_ID_PAGE}/${journey.id}` }}
        >
          <Styled.CoverImage src={journey.cover} />
        </Styled.CoverLink>
      );
    return (
      <Styled.Cover>
        <Styled.CoverImage src={journey.cover} />
      </Styled.Cover>
    );
  };
  const owlTitles = () => <Text.H2Bold>{journey[`title_${lang}`]}</Text.H2Bold>;
  const memberTitles = () => (
    <>
      <Styled.Caption evaluationview={evaluationView?.toString()}>
        <Link
          to={{ pathname: `${routesConfig.JOURNEY_ID_PAGE}/${journey.id}` }}
        >
          {evaluationView
            ? t('pageJourneyList:evaluationCardHeader')
            : journey[`title_${lang}`]}
        </Link>
      </Styled.Caption>
      <Styled.Header evaluationview={evaluationView?.toString()}>
        <Link
          to={{ pathname: `${routesConfig.JOURNEY_ID_PAGE}/${journey.id}` }}
        >
          {evaluationView
            ? journey[`title_${lang}`]
            : currentMicrolearning[`title_${lang}`]}
        </Link>
      </Styled.Header>
    </>
  );

  if (journey.microlearnings.length === 0) return null;
  return (
    <ErrorBoundary>
      <Styled.Containter
        evaluationview={evaluationView?.toString()}
        userrole={userRole}
      >
        {cover()}
        <Styled.Content>
          <Styled.InfoSection>
            <Styled.Title>
              <Styled.MicrolearningInfo>
                <Styled.Titles>
                  {userRole === userRoles.MEMBER ? memberTitles() : owlTitles()}
                </Styled.Titles>
              </Styled.MicrolearningInfo>
              {progressBar && progressBar()}
            </Styled.Title>
          </Styled.InfoSection>
          {featuredInfo && (
            <Styled.SessionInfo>{featuredInfo()}</Styled.SessionInfo>
          )}
          {evaluationView && <Styled.Image src={Award} />}
          {cta && <Styled.CtaSection>{cta()}</Styled.CtaSection>}
        </Styled.Content>
      </Styled.Containter>
    </ErrorBoundary>
  );
}

ActiveCard.propTypes = {
  cta: PropTypes.elementType,
  currentMicrolearning: PropTypes.object,
  evaluationView: PropTypes.bool,
  featuredInfo: PropTypes.elementType,
  journey: PropTypes.object,
  progressBar: PropTypes.elementType,
};

export default ActiveCard;

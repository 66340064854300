import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';

// components
import DayCell from 'components/pageCalendar/DayCell';

// helpers & hooks
import monthsIndex from 'helpers/twelveMonthIndex';
import { usePartialWeek } from 'hooks/useDaysOfWeek';
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageCalendar/_styles/Calendar.style';

function CalendarCard({ scheduledDates, selectedDate, setFilter }) {
  const lang = useUserLanguage();
  const daysOfWeek = usePartialWeek(lang);
  const startMonth = dayjs().month();
  const twelveMonths = monthsIndex.slice(startMonth, startMonth + 12);

  const getDay = (day, month, year) => (
    <DayCell
      day={day}
      month={month}
      scheduledDates={scheduledDates}
      selectedDate={selectedDate}
      setFilter={setFilter}
      year={year}
    />
  );

  const getDaysOfMonth = (month) =>
    Array.from(
      { length: dayjs().set('month', month).daysInMonth() },
      (_, i) => i + 1
    );

  const getMonth = (monthIndex) => {
    const month = Number(monthIndex.split('-')[0]);
    const year = Number(monthIndex.split('-')[1]);
    const fullYear = dayjs().add(year, 'year').year();
    const firstOfMonth = dayjs()
      .set('year', fullYear)
      .set('month', month)
      .set('date', 1);
    const startOfWeekBuffer = firstOfMonth.day();
    const daysOfMonth = getDaysOfMonth(month, year);

    return (
      <div>
        <Styled.MonthRow>
          <Styled.MonthLabel>
            {firstOfMonth.locale(lang).format('MMMM')}
          </Styled.MonthLabel>
        </Styled.MonthRow>
        <Styled.GridContainer>
          {[...Array(startOfWeekBuffer)].map((e, i) => (
            <Styled.DayBuffer key={i} />
          ))}
          {daysOfMonth.map((day) => (
            <div key={day}>{getDay(day, month, fullYear)}</div>
          ))}
        </Styled.GridContainer>
      </div>
    );
  };

  return (
    <Styled.Calendar>
      <Styled.GridContainerWeeks>
        {daysOfWeek.map((day) => (
          <Styled.Day key={day}>
            <Styled.DayInner>
              <Styled.DayText className="DayText">
                <Styled.WeekLabel key={day}>{day}</Styled.WeekLabel>
              </Styled.DayText>
            </Styled.DayInner>
          </Styled.Day>
        ))}
      </Styled.GridContainerWeeks>
      <div style={{ height: '100%', width: '100%', overflow: 'auto' }}>
        {twelveMonths.map((month) => (
          <div key={month}>{getMonth(month)}</div>
        ))}
      </div>
    </Styled.Calendar>
  );
}

CalendarCard.propTypes = {
  scheduledDates: PropTypes.array,
  selectedDate: PropTypes.object,
  setFilter: PropTypes.func,
};
export default CalendarCard;

import React, { useEffect, Suspense, useCallback } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import i18n from 'i18n';
import PropTypes from 'prop-types';

// components
import ApiLoading from 'components/_pageLayout/ApiLoading';
import DemoRegistration from 'components/pageDemoRegistration/_index';
import LoginPage from 'components/pageLogin/_index';
import PageLayout from 'components/_pageLayout/PageLayout';

// context
import { useUser } from 'contexts/UserContext';
import StyleProvider from 'contexts/ThemeContext';

// hooks & helpers
import routesConfig from 'constants/routesConfig.json';

// styles
import * as Container from 'components/_pageLayout/_styles/PageLayout.style';

const AppContent = ({ user, handleDefaultLanguage }) => {
  const location = useLocation();

  let path = location.pathname;

  if (path.startsWith(routesConfig.DEMO_REGISTRATION) && !user?.id) {
    if (path.endsWith('/')) {
      path = path.substring(0, path.length - 1);
    }
    const lastIndex = path.lastIndexOf('/');
    handleDefaultLanguage(path.substring(lastIndex + 1, path.length));

    return (
      <Container.ScreenContainer id="screen">
        <Container.MediumPageContainer>
          <DemoRegistration />
        </Container.MediumPageContainer>
      </Container.ScreenContainer>
    );
  }

  if (user == null) return <ApiLoading />;
  if (user.status === 401) return <LoginPage />;

  return (
    <Suspense fallback="loading">
      <PageLayout />
    </Suspense>
  );
};

AppContent.propTypes = {
  handleDefaultLanguage: PropTypes.func,
  user: PropTypes.object,
};

function App() {
  const { data: user } = useUser();

  const handleDefaultLanguage = useCallback(
    (override = null) => {
      if (override) {
        i18n.changeLanguage(override);
        return;
      }

      if (user != null && user.status !== 401)
        i18n.changeLanguage(user.language);
    },
    [user]
  );

  useEffect(() => {
    handleDefaultLanguage();
  }, [handleDefaultLanguage, user]);

  return (
    <StyleProvider>
      <Router>
        <AppContent user={user} handleDefaultLanguage={handleDefaultLanguage} />
      </Router>
    </StyleProvider>
  );
}

export default App;

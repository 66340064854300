import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// components
import * as Cards from 'components/_styles/Cards.style';

// constants
import userRoles from 'constants/userRoles.json';

// helpers
import device from 'constants/devices';
import colours from 'constants/colours';

// styles
import * as Text from 'components/_styles/Text.style';

export const Caption = styled(Text.CaptionSemiboldCap)`
  a {
    color: ${(props) =>
      props.evaluationview === 'true' ? colours.shade[0] : colours.shade[100]};
    text-decoration: none;
  }
`;

export const Containter = styled(Cards.BasicCard)`
  flex-direction: row;
  max-width: unset;
  min-height: ${(props) =>
    props.userrole === userRoles.MEMBER ? '740px' : '560px'};
  overflow: hidden;
  padding: unset;

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
    min-height: 664px;
  }

  ${(props) =>
    props.evaluationview === 'true'
      ? css`
          background: linear-gradient(
            278.76deg,
            #1a2634 1.35%,
            #36475a 102.58%
          );
        `
      : colours.shade[0]}
`;

export const Content = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  padding: 60px;
  width: 50%;

  @media (max-width: ${device.tablet}) {
    padding: 30px;
    width: 100%;
  }
`;

export const Cover = styled('div')`
  flex: 1;
  width: 50%;

  @media (max-width: ${device.tablet}) {
    max-height: 240px;
    overflow-y: clip;
    width: 100%;
  }
`;

export const CoverLink = styled(Link)`
  flex: 1;
  width: 50%;

  @media (max-width: ${device.tablet}) {
    max-height: 240px;
    overflow-y: clip;
    width: 100%;
  }
`;

export const CoverImage = styled('img')`
  border-radius: 50px 0 0 50px;
  height: 100%;
  object-fit: cover;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    border-radius: 50px 50px 0 0;
    width: 100%;
  }
`;

export const CtaSection = styled('div')`
  align-items: center;
  display: flex;
  gap: 30px;
`;

export const Header = styled(Text.H1Bold)`
  a {
    color: ${(props) =>
      props.evaluationview === 'true' ? colours.shade[0] : colours.shade[100]};
    text-decoration: none;
  }
`;

export const Image = styled('img')`
  align-items: center;
  display: flex;
  height: 240px;
  justify-content: center;
  object-fit: contain;

  @media (max-width: ${device.tablet}) {
    height: 120px;
  }
`;

export const InfoSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 60px;

  @media (max-width: ${device.tablet}) {
    gap: 30px;
  }
`;

export const MicrolearningInfo = styled('div')`
  align-items: flex-start;
  display: flex;
  gap: 10px;
  justify-content: space-between;

  @media (max-width: ${device.tablet}) {
    gap: 0;
  }
`;

export const SessionInfo = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 25px;

  @media (max-width: ${device.tablet}) {
    gap: 15px;
  }
`;

export const Title = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: ${device.tablet}) {
    gap: 30px;
  }
`;

export const Titles = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

import styled from 'styled-components';
import * as PageLayout from 'components/_pageLayout/_styles/PageLayout.style';
import * as Text from 'components/_styles/Text.style';
import colours from 'constants/colours';
import device from 'constants/devices';

export const Page = styled(PageLayout.Page)`
  gap: 60px;
  overflow: unset;
  padding: unset;

  @media (max-width: ${device.laptop}) {
    flex-direction: column;
  }
`;

export const CalendarColumn = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  max-width: 900px;

  @media (max-width: ${device.laptop}) {
    height: 400px;
    margin-bottom: 30px;
  }
`;

export const CalendarHeader = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: space-between;
`;

export const CardColumnHeaderArea = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CardColumnHeader = styled(Text.H1)`
  text-transform: capitalize;
`;

export const DateCardColumn = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex-basis: 500px;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  overflow: auto;

  @media (max-width: ${device.laptop}) {
    gap: 15px;
    overflow: unset;
  }
`;

export const DateCardPage = styled(DateCardColumn)`
  align-content: start;
  flex-basis: unset;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: ${device.laptop}) {
    gap: 15px;
    padding: 30px;
  }
`;

export const EmptyCard = styled('div')`
  background-color: ${colours.neutralCool[100]};
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 40px;

  @media (max-width: ${device.laptop}) {
    gap: 15px;
  }
`;

export const EmptyCardText = styled(Text.H5Bold)`
  color: ${colours.neutralCool[500]};
  text-align: center;
`;

export const ResetFilter = styled('div')`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 12px 0;
`;

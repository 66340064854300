import React from 'react';
import PropTypes from 'prop-types';

import { Arrow } from 'untitledui-js';
import Loader from 'components/pageECoach/Loader';
import * as Styled from 'components/pageECoach/_styles/LoadingAwareItem.style';

export default function LoadingAwareItem({
  itemId,
  loadingId,
  children,
  handleClick,
  ...props
}) {
  const active = itemId === loadingId;
  const disabled = loadingId !== undefined && !active;

  return (
    <Styled.LoadingAwareItem
      key={itemId}
      disabled={disabled}
      onClick={() => !disabled && handleClick()}
      {...props}
    >
      {children}
      <div>{active ? <Loader inline /> : <Arrow.ChevronRight />} </div>
    </Styled.LoadingAwareItem>
  );
}

LoadingAwareItem.propTypes = {
  itemId: PropTypes.string,
  loadingId: PropTypes.string,
  children: PropTypes.node,
  handleClick: PropTypes.func,
  props: PropTypes.object,
};

import styled from 'styled-components';
import Button from '@mui/material/Button';

export const ButtonSelect = styled(Button)`
  background-color: #f4f4f2;
  border-radius: 50px;
  color: black;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 900;
  height: 60px;
  opacity: 0.6;
  padding: 0 30px;
  text-transform: uppercase;

  :hover {
    background-color: #f4f4f2;
    color: black;
    opacity: 1;
  }
`;

export const SelectedButton = styled(ButtonSelect)`
  background-color: #ff4d52;
  color: white;
  opacity: 1;

  :hover {
    background-color: #ff4d52;
    color: white;
    opacity: 0.8;
  }
`;

export const ThemeArea = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

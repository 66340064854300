const Belonging = {
  evaluationId: 'Belonging',
  questions: [
    {
      questionNumber: 1,
      question_en:
        'You know that no one in the team really likes Jill because she sometimes makes inappropriate comments. When you assign Peter and Jill to work together, Peter approaches you to tell you he is uncomfortable working with Jill.',
      question_fr:
        "Vous savez que personne dans l'équipe n'aime vraiment Ginette parce qu'elle fait parfois des commentaires déplacés. Lorsque vous confiez à Pierre et à Ginette le soin de travailler ensemble, Pierre vous fait savoir qu'il n'est pas à l'aise avec Ginette.",
      solution: 1,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Ask Peter for specifics of why he feels uncomfortable working with Jill. If the problem lies with Jill’s behaviour, have a one-on-one chat with Jill around expectations regarding employee behaviour in the company.',
          option_fr:
            "Demandez à Pierre de préciser pourquoi il ne se sent pas à l'aise de travailler avec Ginette. Si le problème réside dans le comportement de Ginette, discutez en tête-à-tête avec elle des attentes générales concernant le comportement des employé(e)s dans l'entreprise.",
          feedback_en:
            'Yes, it is best to have direct communication with Jill around her behaviour if it is a persistent problem that is affecting team dynamics.',
          feedback_fr:
            "Oui, il est préférable de communiquer directement avec Ginette au sujet de son comportement s'il s'agit d'un problème persistant qui affecte la dynamique de l'équipe.",
        },
        {
          optionNumber: 2,
          option_en: 'Ignore the situation, it’ll get better.',
          option_fr: 'Ignorez la situation, elle va se résoudre naturellement.',
          feedback_en:
            'Ignoring her might cause resentment to build between Peter and Jill.',
          feedback_fr:
            "En ignorant la situation, cela risque d'accroître le ressentiment entre Pierre et Ginette.",
        },
        {
          optionNumber: 3,
          option_en:
            'Quietly reassign Jill to work with someone other than Peter.',
          option_fr:
            "Réaffectez discrètement Ginette pour qu'elle travaille avec quelqu'un d'autre que Pierre.",
          feedback_en:
            'This will only shift the problem to another colleague without solving it. Furthermore, if Jill senses rejection, she will feel unsafe in the team.',
          feedback_fr:
            "Cela ne fera que déplacer le problème vers un(e) autre collègue sans le résoudre. En outre, si Ginette se sent rejetée, elle ne se sentira pas en sécurité au sein de l'équipe.",
        },
      ],
    },
    {
      questionNumber: 2,
      question_en:
        'In your conversation with Jill, she is surprised that her comments were seen as inappropriate when in her eyes, she was only joking. She says she will try to be more aware of making such comments.',
      question_fr:
        "Lors de votre conversation avec Ginette, celle-ci s'étonne que ses commentaires aient été jugés inappropriés alors qu'à ses yeux, elle ne faisait que plaisanter. Elle dit qu'elle essaiera d'être plus attentive à ce genre de commentaires.",
      solution: 1,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Talk to Peter and see if he feels comfortable working with Jill given her increased awareness of the situation.',
          option_fr:
            "Parlez à Pierre et voyez s'il se sent à l'aise de travailler avec Ginette, étant donné qu'elle est de plus en plus consciente de la situation.",
          feedback_en:
            'Yes, given that Jill is open to change, you can communicate this to Peter, while also reassuring him that you will monitor the situation and make sure both of them feel comfortable and supported in their work.',
          feedback_fr:
            "En effet, étant donné que Ginette est ouverte au changement, vous pouvez en faire part à Pierre, tout en le rassurant sur le fait que vous surveillerez la situation et veillerez à ce qu'ils se sentent tous deux à l'aise et soutenus dans leur travail.",
        },
        {
          optionNumber: 2,
          option_en:
            'Try to explain to Jill why her comments were inappropriate.',
          option_fr:
            "Essayez d'expliquer à Ginette pourquoi ses remarques étaient déplacées.",
          feedback_en:
            'This conversation may be more unproductive than helpful. You can monitor the situation and follow up with Jill again if you receive additional complaints about her behaviour.',
          feedback_fr:
            "Cette conversation risque d'être plus contre-productive que bénéfique. Vous pouvez surveiller la situation et intervenir auprès de Ginette si vous recevez d'autres plaintes concernant son comportement.",
        },
        {
          optionNumber: 3,
          option_en:
            'Call a meeting with Peter and Jill so Peter can express how her comments were interpreted.',
          option_fr:
            'Organisez une réunion avec Pierre et Ginette afin que Pierre puisse lui expliquer comment ses remarques ont été perçues.',
          feedback_en:
            'At this point, a meeting with the two of them is probably unnecessary as there was not outright conflict. Monitor the situation to see if it gets resolved now that Jill has been made aware.',
          feedback_fr:
            "À ce stade, il n'est probablement pas nécessaire de les réunir tous les deux pour un entretien, car il n'y a pas eu de conflit manifeste. Surveillez la situation pour voir si elle s'améliore maintenant que Ginette a été informée.",
        },
      ],
    },
    {
      questionNumber: 3,
      question_en:
        'During team meetings, you sense a drop in morale. Your team seems disconnected and everyone leaves the meeting immediately when it finishes. No one shares when you ask an open-ended question.',
      question_fr:
        "Lors des réunions d'équipe, vous ressentez une baisse de moral. Votre équipe semble déconnectée et tout le monde quitte la réunion immédiatement après sa fin. Personne ne partage lorsque vous posez une question à réponse libre.",
      solution: 3,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Start a vulnerability practice at the beginning of the meeting to get everyone to share about what was most challenging for them during the week.',
          option_fr:
            'Au début de la réunion, mettez en place une pratique de vulnérabilité en demandant à chacun(e) de partager ce qui a été le plus difficile à surmonter au cours de la semaine.',
          feedback_en:
            'Encouraging vulnerability is a good step, however, if your team is already reluctant to share, this tactic may misfire. Vulnerability is most powerful when it starts with you.',
          feedback_fr:
            "Encourager la vulnérabilité est une bonne initiative, cependant, si votre équipe est déjà réticente à partager, cette approche peut ne pas fonctionner comme prévu. La vulnérabilité est plus efficace lorsqu'elle est initiée par vous-même.",
        },
        {
          optionNumber: 2,
          option_en:
            'Have a one on one check-in with members on your team to see if there are personal issues that are causing this drop in morale.',
          option_fr:
            "Prenez le temps d'avoir des entretiens individuels avec les membres de votre équipe pour voir s'il y a des problèmes personnels qui pourraient être à l'origine de cette baisse de moral.",
          feedback_en:
            'One on ones are good for sensitive, personal issues, however, this seems to be more of a team-level problem and should be addressed to the team as a whole.',
          feedback_fr:
            "Les entretiens individuels sont bénéfiques pour aborder des problèmes personnels et délicats, mais dans ce cas, il semble que le problème concerne l'équipe dans son ensemble et devrait être traité collectivement.",
        },
        {
          optionNumber: 3,
          option_en:
            'Acknowledge that you are sensing a drop in morale and ask your team for input on how you can be leading meetings better.',
          option_fr:
            'Reconnaissez que vous ressentez une baisse de moral et demandez à votre équipe des suggestions sur la façon dont vous pouvez mieux diriger les réunions.',
          feedback_en:
            'By being vulnerable first as a leader, you are setting the example for your team and are opening the door for them to also be vulnerable.',
          feedback_fr:
            "En montrant votre vulnérabilité en tant que leader, vous montrez l'exemple à votre équipe et encouragez les autres à faire de même.",
        },
      ],
    },
    {
      questionNumber: 4,
      question_en:
        'Your team members start to share that they are losing sight of the team’s purpose and find the team meetings a waste of time.',
      question_fr:
        "Les membres de votre équipe commencent à exprimer qu'ils perdent de vue l'objectif de l'équipe et considèrent les réunions comme une perte de temps.",
      solution: 1,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Thank them for their input and spend time during that meeting to brainstorm your team’s top priorities. State that you want everyone to feel aligned and acknowledge your part in not clarifying your team’s purpose.',
          option_fr:
            "Remerciez-les pour leurs commentaires et profitez de cette réunion pour faire une séance de remue-méninges sur les principales priorités de votre équipe. Faites savoir que vous souhaitez que chacun se sente impliqué et reconnaissez votre part de responsabilité dans le manque de clarté quant à l'objectif de l'équipe.",
          feedback_en:
            'Yes, re-establishing a common purpose is crucial to building belonging in your team. By starting during that meeting, you are acting quickly on your team’s feedback which builds trust.',
          feedback_fr:
            "En effet, rétablir un objectif commun est essentiel pour renforcer le sentiment d'appartenance au sein de l'équipe. En agissant rapidement lors de cette réunion, vous répondez aux préoccupations de l'équipe et renforcez la confiance.",
        },
        {
          optionNumber: 2,
          option_en:
            'Brainstorm practical ways to make the team meetings more efficient (e.g. have an agenda beforehand).',
          option_fr:
            "Engagez une réflexion sur des moyens pratiques d'améliorer l'efficacité des réunions d'équipe (par exemple, en établissant un ordre du jour au préalable).",
          feedback_en:
            'Although logistics do need to be talked about, they do not address the more fundamental issue which is the lack of purpose.',
          feedback_fr:
            "Bien que les aspects logistiques soient importants à aborder, ils ne traitent pas du problème plus fondamental qui est le manque de clarté quant à l'objectif.",
        },
        {
          optionNumber: 3,
          option_en:
            'Bring up your company’s vision statement  and reiterate it to your team to make sure everyone understands.',
          option_fr:
            'Rappelez la mission de votre entreprise et réaffirmez-la auprès de votre équipe pour vous assurer que chacun la comprend bien.',
          feedback_en:
            'Your company’s vision may not be exactly the same as your team’s vision. Your team wants to know how the company vision translates to your team.',
          feedback_fr:
            "Il est possible que la mission de votre entreprise diffère légèrement de celle de votre équipe. Celle-ci cherche à savoir comment la mission de l'entreprise se traduit concrètement au sein de leur travail.",
        },
      ],
    },
    {
      questionNumber: 5,
      question_en:
        'During the sharing, you notice that Sarah, one of your team members, is quiet as others keep talking over her.',
      question_fr:
        "Pendant les échanges, vous remarquez que Sarah, l'une des membres de votre équipe, est silencieuse alors que les autres continuent de l'ignorer.",
      solution: 2,
      options: [
        {
          optionNumber: 1,
          option_en:
            'You do not want to embarrass her if she has nothing to say, so you leave the situation as is and trust that she will speak up when she needs to.',
          option_fr:
            "Vous ne voulez pas la mettre mal à l'aise si elle n'a rien à dire, donc vous laissez la situation telle quelle en espérant qu'elle prendra la parole quand elle en ressentira le besoin.",
          feedback_en:
            'Creating a culture of safety means that you create space for everyone to talk. As the facilitator of the conversation, you sometimes need to take charge more to make sure this happens.',
          feedback_fr:
            "Créer une culture de sécurité signifie offrir à chacun l'espace pour s'exprimer. En tant que facilitateur de la conversation, il vous arrive parfois de devoir prendre davantage le contrôle pour vous assurer que cela se réalise.",
        },
        {
          optionNumber: 2,
          option_en:
            'Before someone else jumps in, say, “I want to make sure everyone’s voice has been heard. Sarah, did you have anything to share?”',
          option_fr:
            "Avant qu'une autre personne n'intervienne, dites : « Je veux m'assurer que la voix de tous a été entendue. Sarah, avez-vous quelque chose à partager? »",
          feedback_en:
            'Yes, this is an open-ended question that invites her to share if she wants to. Making sure each team member is heard is one of the most valuable things you can do to maintain a strong sense of belonging.',
          feedback_fr:
            "En effet, c'est une question ouverte qui l'invite à s'exprimer, si elle le souhaite. Veiller à ce que chaque membre de l'équipe soit entendu(e) est l'une des choses les plus précieuses que vous puissiez faire pour maintenir un fort sentiment d'appartenance.",
        },
        {
          optionNumber: 3,
          option_en:
            'Pull Sarah aside after the team meeting and ask her if there was anything she wanted to share with you privately.',
          option_fr:
            "Après la réunion d'équipe, prenez Sarah à part et demandez-lui si elle avait quelque chose à partager avec vous en privé.",
          feedback_en:
            'Although this may give her a chance to express her opinions to you, she needs to feel like her opinion is valued in team meetings as well.',
          feedback_fr:
            "Bien que cela puisse lui donner l'occasion d'exprimer ses opinions à votre égard, elle doit aussi sentir que ses opinions sont valorisées lors des réunions d'équipe.",
        },
      ],
    },
  ],
};

export default Belonging;

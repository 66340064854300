// evaluations
import Belonging from 'components/pageEvaluation/evaluations/Belonging';
import Diversity from 'components/pageEvaluation/evaluations/Diversity';
import PsychologicalSafety from 'components/pageEvaluation/evaluations/PsychologicalSafety';
import RemoteWork from 'components/pageEvaluation/evaluations/RemoteWork';
import SupportingOthers from 'components/pageEvaluation/evaluations/SupportingOthers';
import SupportingOurselves from 'components/pageEvaluation/evaluations/SupportingOurselves';

const getQuiz = (evaluationId) => {
  const evaluations = [
    Belonging,
    Diversity,
    PsychologicalSafety,
    RemoteWork,
    SupportingOthers,
    SupportingOurselves,
  ];

  const evaluationsFiltered = evaluations.filter(
    (quiz) => quiz.evaluationId === evaluationId
  );

  return evaluationsFiltered[0];
};

export default getQuiz;

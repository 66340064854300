import React from 'react';
import PropTypes from 'prop-types';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageJourneyId/_styles/MicrolearningCard.style';
import * as Text from 'components/_styles/Text.style';

function MicrolearningCardOwl({ cover, microlearning, order }) {
  const lang = useUserLanguage();

  return (
    <Styled.Row>
      <Styled.Card>
        <Styled.Cover>
          <Styled.CoverImage src={cover} />
        </Styled.Cover>
        <Styled.InfoSection>
          <Text.H5Bold>
            {`${order + 1}. ${microlearning[`title_${lang}`]}`}
          </Text.H5Bold>
          <Styled.Details>
            <Text.P2Semibold>
              {microlearning[`description_${lang}`]}
            </Text.P2Semibold>
          </Styled.Details>
        </Styled.InfoSection>
      </Styled.Card>
    </Styled.Row>
  );
}

MicrolearningCardOwl.propTypes = {
  cover: PropTypes.string,
  microlearning: PropTypes.object,
  order: PropTypes.number,
};

export default MicrolearningCardOwl;

import React from 'react';
import PropTypes from 'prop-types';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Modal from 'components/_styles/Modal.style';

const ConfirmDialog = (props) => {
  const {
    cancelLabel,
    contentText,
    handleClose,
    isOpen,
    successCallback,
    successLabel,
    title,
  } = props;

  return (
    <Modal.Container
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={handleClose}
      open={isOpen}
      PaperComponent={Modal.Card}
    >
      <Modal.Title id="alert-dialog-title">{title}</Modal.Title>
      <Modal.Content style={{ marginBottom: '30px' }}>
        <Modal.ContentText id="alert-dialog-description">
          {contentText}
        </Modal.ContentText>
      </Modal.Content>
      <Modal.Actions sx={{ justifyContent: 'flex-start' }}>
        {successCallback && (
          <Buttons.Primary onClick={successCallback}>
            {successLabel}
          </Buttons.Primary>
        )}
        <Buttons.Secondary onClick={handleClose}>
          {cancelLabel}
        </Buttons.Secondary>
      </Modal.Actions>
    </Modal.Container>
  );
};

ConfirmDialog.propTypes = {
  contentText: PropTypes.string,
  cancelLabel: PropTypes.string,
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool,
  successCallback: PropTypes.func,
  successLabel: PropTypes.string,
  title: PropTypes.string,
};

export default ConfirmDialog;

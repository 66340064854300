import styled from 'styled-components';
import * as Buttons from 'components/_styles/Buttons.style';
import * as Cards from 'components/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';
import device from 'constants/devices';

export const AboutDescription = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const AboutSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ActionArea = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Card = styled(Cards.BasicCard)`
  gap: 30px;
  padding: 60px;
  width: 570px;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (max-width: ${device.tablet}) {
    background-color: white;
    border-radius: 30px;
    box-shadow: none;
    gap: 30px;
    padding: 30px;
    width: 100%;
  }
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
`;

export const Disclaimer = styled(Text.P1Underline)`
  cursor: pointer;
`;

export const InstructionRow = styled('div')`
  align-items: center;
  display: flex;
  gap: 10px;

  svg {
    height: 30px;
    min-width: 30px;
    width: 30px;
  }
`;

export const InstructionSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const StartButton = styled(Buttons.Primary)`
  flex-grow: unset;

  @media (max-width: ${device.tablet}) {
    width: 100%;
  }
`;

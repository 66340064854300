import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// components
import * as SimpleProgress from 'components/progressIndicators/SimpleProgress';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Cards from 'components/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';

function MicrolearningCardAdmin({ microlearning }) {
  const { t } = useTranslation();
  const lang = useUserLanguage();

  return (
    <Cards.AccordianCard>
      <Cards.AccordionOverview
        aria-controls="panel1a-content"
        expandIcon={<ExpandMoreIcon />}
        id="panel1a-header"
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text.CaptionCap>
              {t('pageJourneyId:microlearning')}
            </Text.CaptionCap>
            <Text.H2>{microlearning[`title_${lang}`]}</Text.H2>
            <SimpleProgress.Chip numMembers={microlearning.completion_count} />
          </div>
          <SimpleProgress.Circle numMembers={microlearning.completion_count} />
        </div>
      </Cards.AccordionOverview>
      <AccordionDetails style={{ borderTop: '1px solid #c4c4c4' }}>
        <div style={{ padding: '0 20px' }}>
          <Text.P1>{microlearning[`description_${lang}`]}</Text.P1>
        </div>
      </AccordionDetails>
    </Cards.AccordianCard>
  );
}

MicrolearningCardAdmin.propTypes = {
  microlearning: PropTypes.object,
};

export default MicrolearningCardAdmin;

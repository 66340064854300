import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';

export const MarkdownContainer = styled.div`
  color: ${colours.neutralCool[800]};
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    margin-top: 1em;
    margin-bottom: 0.5em;
    line-height: 1.2;
  }

  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
  }
  h3:first-of-type:not(p + h3) {
    margin-top: 0;
  }
  h4 {
    font-size: 18px;
    font-weight: 700;
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
  }
  h6 {
    font-size: 14px;
    font-weight: 700;
  }

  p {
    line-height: 1.5;
    font-weight: 300;
    margin-bottom: 1em;
  }

  /* Links */
  a {
    color: ${colours.blue[500]};
    text-decoration: none;
    transition: color 0.3s ease;
  }

  a:hover {
    color: ${colours.blue[800]};
    text-decoration: underline;
  }

  /* Lists */
  ul,
  ol {
    font-weight: 300;
    margin: 0.5em 0 1.5em 1.5em;
    padding-left: 1em;
  }

  li {
    font-weight: 300;
    margin-bottom: 0.5em;
  }

  /* Blockquotes */
  blockquote {
    margin: 0 0 1.5em;
    padding: 0.5em 1em;
    border-left: 5px solid #3498db;
    background-color: ${colours.neutralCool[200]};
    font-style: italic;
    color: ${colours.neutralCool[800]};
  }

  /* Code blocks */
  pre,
  code {
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 0.9em;
    background: ${colours.neutralCool[200]};
    border-radius: 3px;
  }

  pre {
    padding: 1em;
    overflow-x: auto;
    border: 1px solid #ddd;
  }

  code {
    padding: 0.2em 0.4em;
  }

  /* Horizontal rule */
  hr {
    border: 0;
    border-top: 1px solid ${colours.neutralCool[200]};
    margin: 2em 0;
  }

  /* Tables */
  table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1.5em;
  }

  th,
  td {
    text-align: left;
    padding: 0.5em;
    border-bottom: 1px solid ${colours.neutralCool[500]};
  }

  th {
    background-color: ${colours.neutralCool[200]};
    font-weight: bold;
  }

  /* Images */
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1.5em auto;
  }

  /* Responsive design */
  @media (max-width: ${device.tablet}) {
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.75em;
    }
    h3 {
      font-size: 1.5em;
    }
    h4 {
      font-size: 1.25em;
    }
    h5 {
      font-size: 1.1em;
    }
    h6 {
      font-size: 1em;
    }
  }

  /* Accessibility */
  :focus {
    outline: 3px solid ${colours.blue[300]};
    outline-offset: 2px;
  }
`;

export default MarkdownContainer;

import styled from 'styled-components';
import * as Buttons from 'components/_styles/Buttons.style';
import * as Cards from 'components/_styles/Cards.style';
import colours from 'constants/colours';
import device from 'constants/devices';

export const Block = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Card = styled(Cards.BasicCard)`
  gap: 30px;
  border-radius: 30px;
  padding: 30px;
  max-width: unset;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    box-shadow: none;
  }
`;

export const Content = styled('div')`
  display: flex;
  gap: 60px;
  justify-content: space-between;

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
  }
`;

export const ECoachCard = styled('div')`
  background-color: ${colours.neutralCool[200]};
  border-radius: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 15px;
  max-height: 600px;
  overflow: auto;
  padding: 20px;
`;

export const ECoachLogo = styled('div')`
  svg {
    height: 30px;
    width: 30px;
  }
  svg path {
    fill: ${colours.neutralCool[800]};
  }
`;

export const ChatSection = styled('div')`
  padding-left: ${(props) => (props.type === 'user' ? '0' : '12px')};
  padding-right: ${(props) => (props.type === 'eCoach' ? '0' : '12px')};
`;

export const HowToRow = styled('div')`
  align-items: start;
  display: flex;
  gap: 10px;

  svg {
    height: 30px;
    min-width: 30px;
    width: 30px;
  }
`;

export const HowToRows = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const HowToText = styled('div')`
  align-items: center;
  display: flex;
  height: 100%;
`;

export const LeftSide = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 60px;
`;

export const LogoIcon = styled('div')`
  display: none;

  svg {
    height: 22px;
    width: 22px;
  }

  @media (max-width: ${device.tablet}) {
    display: block;
    height: 22px;
    padding: 12px;
  }
`;

export const LogoText = styled('div')`
  box-sizing: unset;
  height: 22px;
  padding: 12px;

  svg {
    height: 22px;
    width: unset;
  }

  @media (max-width: ${device.tablet}) {
    display: none;
  }
`;

export const PromptButton = styled('div')`
  background-color: ${colours.neutralCool[100]};
  border-radius: 10px;
  color: ${colours.neutralCool[800]};
  justify-content: unset;
  line-height: unset;
  padding: 10px;
  text-align: left;
  text-transform: none;
`;

export const RightSide = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
`;

export const TemplateText = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TemplateTextRow = styled('div')`
  background-color: ${colours.neutralCool[200]};
  border-radius: 20px;
  height: 9px;
`;

export const TextBox = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TopBar = styled('div')`
  align-items: center;
  border-bottom: 1px solid ${colours.neutralCool[100]};
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;

  p {
    color: ${colours.neutralCool[500]};
  }
`;

export const TryItButton = styled(Buttons.Primary)`
  max-width: 400px;
  width: 100%;
`;

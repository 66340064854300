import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';
import ChatStart from 'components/pageECoach/chatStart/_index';
import ConvoPrepCard from 'components/pageECoach/convoPrep/ConvoPrepCard';
import ECoachChallengeVerification from 'components/pageECoach/ECoachChallengeVerification';
import ECoachIntroMessage from 'components/pageECoach/chatStart/ECoachIntroMessage';
import SuggestionsCard from 'components/pageECoach/suggestions/_index';
import { Stack } from '@mui/material';

// contexts
import { useSessionContext } from 'contexts/ECoachSessionContext';

// helpers & hooks
import {
  SessionProgressChoices,
  UserFeatureFlagType,
} from 'components/pageECoach/utils';

// styles
import { useFeatureFlagEnabled } from 'posthog-js/react';
import ECoachBackAndForth from './ECoachBackAndForth';

export default function ECoachSession({ chatGuideData, setChatGuideData }) {
  const { session } = useSessionContext();

  const hasSuggestions =
    session?.context?.description &&
    session?.progress >= SessionProgressChoices.SUGGESTIONS;

  useEffect(() => {
    console.log('Session updated:', session);
  }, [session]);

  const isChallengeClarificationEnabled = useFeatureFlagEnabled(
    UserFeatureFlagType.ECOACH_CHALLENGE_CLARIFICATION
  );

  const renderSession = () => {
    const sessionPageview = [];

    sessionPageview.push(<ECoachIntroMessage key="ECoachIntroMessage" />);

    sessionPageview.push(
      <ChatStart
        key="start_chat"
        chatGuideData={chatGuideData}
        setChatGuideData={setChatGuideData}
      />
    );

    if (
      isChallengeClarificationEnabled &&
      session?.progress >= SessionProgressChoices.CHALLENGE_VERIFICATION
    ) {
      sessionPageview.push(
        <ECoachChallengeVerification key="challenge_verification" />
      );
    } else if (session?.context?.description) {
      sessionPageview.push(
        <ChatBubble
          key={session?.context?.description}
          simpleString={session?.context?.description}
          pointer={true}
          sentByUser
        />
      );
    }

    if (
      session?.progress >= SessionProgressChoices.CONVERSATION_PLAN &&
      !hasSuggestions
    ) {
      sessionPageview.push(
        <ConvoPrepCard chatGuideData={chatGuideData} key="convo_plan" />
      );
    }

    if (hasSuggestions) {
      sessionPageview.push(<SuggestionsCard key="suggestions_card" />);
    }

    if (session?.progress >= SessionProgressChoices.BACK_AND_FORTH) {
      sessionPageview.push(<ECoachBackAndForth key="back_and_forth" />);
    }

    return (
      <Stack spacing={3} style={{ flex: 1, justifyContent: 'space-between' }}>
        {sessionPageview}
      </Stack>
    );
  };

  return <>{renderSession()}</>;
}

ECoachSession.propTypes = {
  chatGuideData: PropTypes.object,
  setChatGuideData: PropTypes.func,
};

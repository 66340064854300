const RemoteWork = {
  evaluationId: 'RemoteWork',
  questions: [
    {
      questionNumber: 1,
      question_en:
        'You manage a fully remote team that doesn’t check in with you. Even though they are very knowledgeable, you feel like they still do need managing to be meeting project deadlines. You do not know how to encourage group cohesion remotely and how you should check in with your remote reports without being invasive.',
      question_fr:
        "Vous gérez une équipe entièrement à distance dont les membres ne prennent pas l'initiative de vous contacter. Bien qu'ils soient très compétents, vous estimez qu'ils ont besoin d'être encadrés pour respecter les délais des projets. Vous vous demandez comment favoriser la cohésion du groupe à distance et comment prendre des nouvelles de vos subordonné(e)s sans être envahissant(e).",
      solution: 3,
      options: [
        {
          optionNumber: 1,
          option_en:
            'At the next team meeting, announce you are always available for them and encourage your team to reach out to you if they have any questions.',
          option_fr:
            "Lors de la prochaine réunion d'équipe, informez-les que vous êtes toujours disponible et encouragez votre équipe à vous contacter pour toute question..",
          feedback_en:
            'Unless you give specific structure, team members who are feeling disconnected are unlikely to respond to a vague and open invitation despite your good intent.',
          feedback_fr:
            "Malgré vos bonnes intentions et en absence d’une structure spécifique, les membres de l'équipe se sentant déconnectés et ne répondront probablement pas à une invitation vague.",
        },
        {
          optionNumber: 2,
          option_en:
            'Start organizing a weekly lunch hour where you can talk about non-work-related topics to encourage team bonding.',
          option_fr:
            "Envisagez d'organiser un déjeuner hebdomadaire pour discuter de sujets non liés au travail afin de renforcer la cohésion de l'équipe.",
          feedback_en:
            'This is a great idea for team bonding and group cohesion; however you might still feel like you are disconnected from their work.',
          feedback_fr:
            "C'est une excellente idée pour consolider l'esprit d'équipe et favoriser la cohésion, mais vous pourriez vous sentir déconnecté de leur travail.",
        },
        {
          optionNumber: 3,
          option_en:
            'Start having biweekly 1 on 1s with your team members to check in with their work but also to get to know them individually. In your meetings, clearly communicate expectations about work objectives.',
          option_fr:
            'Mettez en place des rencontres individuelles bihebdomadaires avec les membres de votre équipe pour faire le point sur leur travail et apprendre à les connaître individuellement. Lors de ces réunions, communiquez clairement vos attentes concernant les objectifs de travail.',
          feedback_en:
            'Yes, once you have check-ins with clear communication and expectations, you can allow them more autonomy to work and not be “micro-managed”. This will also help them feel more connected to you.',
          feedback_fr:
            "En effectuant des contrôles avec une communication et des attentes claires, vous pouvez leur laisser plus d'autonomie pour travailler sans être « micro-géré(e)s ». Cela les aidera également à se sentir plus proches de vous.",
        },
      ],
    },
    {
      questionNumber: 2,
      question_en:
        'You’ve been holding fully remote meetings since the pandemic. Lately, you’ve noticed that everyone is on mute during the meetings with their videos off. When you ask a question, no one answers.',
      question_fr:
        'Depuis la pandémie, vous organisez toutes les réunions à distance. Récemment, vous avez remarqué que tous les participants sont en sourdine pendant les réunions et que leurs caméras sont éteintes. Lorsque vous posez une question, personne ne répond.',
      solution: 3,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Embrace the silence and wait until someone answers your question.',
          option_fr:
            "Acceptez le silence et attendez que quelqu'un réponde à votre question.",
          feedback_en:
            'This solution does not address the deeper problem of low team engagement. If the remote situation is going to be sustainable for you, you will need to find an alternate way of holding meetings.',
          feedback_fr:
            "Cependant, cette solution ne résout pas le problème sous-jacent du manque d'engagement des membres de l'équipe. Si la situation à distance doit perdurer, vous devrez trouver un autre moyen d'organiser des réunions.",
        },
        {
          optionNumber: 2,
          option_en:
            'Say, “I’m just hearing myself talk and I can’t see anyone right now. Would you all please turn on your videos, so I know you’re there?”',
          option_fr:
            "Dites : « Je n'entends que moi parler et je ne vois personne pour le moment. Pourriez-vous s'il vous plaît allumer vos caméras pour que je sache que vous êtes là? ».",
          feedback_en:
            'While you can periodically encourage your employees to have their camera on, it is ultimately their choice on what makes them feel most comfortable.',
          feedback_fr:
            "Bien que vous puissiez encourager périodiquement vos employé(e)s à activer leur caméra, c'est finalement à eux de choisir ce qui les met le plus à l'aise.",
        },
        {
          optionNumber: 3,
          option_en:
            'Acknowledge that it’s tiring to be on Zoom all the time but clarify the importance of active participation in meetings. Ask your team if they have any suggestions on how the meetings could be improved (e.g. gamifying the meeting using tools such as polls/whiteboards etc.)',
          option_fr:
            "Reconnaissez la fatigue liée à l'utilisation constante de Zoom, mais insistez sur l'importance d'une participation active aux réunions. Demandez à votre équipe si elle a des suggestions pour améliorer les réunions (par exemple, en utilisant des outils tels que des sondages, des tableaux blancs, etc.).",
          feedback_en:
            'Yes, remote work comes with a whole set of different challenges. It is helpful to acknowledge this and solicit advice on how your team can adapt to the new situation.',
          feedback_fr:
            "En effet, le télétravail présente une série de défis différents. Il est utile de le reconnaître et de demander des avis sur la manière dont votre équipe peut s'adapter à cette situation.",
        },
      ],
    },
  ],
};

export default RemoteWork;

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import { Arrow } from 'untitledui-js';

// components
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import QuizEndCard from 'components/pageEvaluation/QuizEndCard';
import QuizFeedback from 'components/pageEvaluation/QuizFeedback';
import QuizOptions from 'components/pageEvaluation/QuizOptions';

// hooks
import useApi from 'hooks/useApi';
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageEvaluation/_styles/_index.style';
import * as Text from 'components/_styles/Text.style';

function QuizCard({ journeyId, journeyTitle, quiz }) {
  const { t } = useTranslation();
  const { callApi: postScore } = useApi();
  const lang = useUserLanguage();

  const [currentQuestion, setCurrentQuestion] = useState(quiz.questions[0]);
  const [feedbackView, setFeedbackView] = useState(false);
  const [questionNum, setQuestionNum] = useState(1);
  const [score, setScore] = useState(0);
  const [selection, setSelection] = useState(0);
  const [showScoreCard, setShowScoreCard] = useState(false);

  const selectOption = (optionNumber) => setSelection(optionNumber);

  const showFeedback = () => {
    if (feedbackView === false && selection === currentQuestion.solution)
      setScore((prev) => prev + 1);
    setFeedbackView(true);
  };

  const nextQuestion = () => {
    setCurrentQuestion(quiz.questions[questionNum - 1]);
    setSelection(0);
    setQuestionNum((prev) => prev + 1);
    setFeedbackView(false);
  };

  const submit = useCallback(() => {
    const params = { evaluation_score: score };
    postScore(`/journeys/${journeyId}/scores/`, 'put', params);
    setFeedbackView(false);
    setShowScoreCard(true);
  }, [journeyId, postScore, score]);

  const getButton = () => {
    const getButtonAction = () => {
      if (feedbackView === false && selection !== 0) return showFeedback;
      if (questionNum === quiz.questions.length && selection !== 0)
        return submit;
      if (feedbackView && selection === currentQuestion.solution)
        return nextQuestion;
      return null;
    };

    const buttonAction = getButtonAction();

    if (buttonAction === null)
      return (
        <Styled.NextButtonDisabled>
          {t('pageEvaluation:next')}
          <Arrow.ArrowNarrowRight />
        </Styled.NextButtonDisabled>
      );

    return (
      <Styled.NextButton onClick={buttonAction}>
        {t('pageEvaluation:next')}
        <Arrow.ArrowNarrowRight />
      </Styled.NextButton>
    );
  };

  if (showScoreCard) return <QuizEndCard journeyTitle={journeyTitle} />;
  return (
    <ErrorBoundary>
      <Styled.Card>
        <Text.CaptionSemibold>
          {`${questionNum}/${quiz.questions.length}`}
        </Text.CaptionSemibold>
        <Text.H5Semibold>{currentQuestion[`question_${lang}`]}</Text.H5Semibold>
        {feedbackView === true && (
          <QuizFeedback
            currentQuestion={currentQuestion}
            selectedOption={selection}
          />
        )}
        <Text.H5Semibold>{t('pageEvaluation:instruction')}</Text.H5Semibold>
        <QuizOptions
          feedbackView={feedbackView}
          options={currentQuestion.options}
          selection={selection}
          selectOption={selectOption}
          solution={currentQuestion.solution}
        />
        {getButton()}
      </Styled.Card>
    </ErrorBoundary>
  );
}

QuizCard.propTypes = {
  journeyId: PropTypes.string,
  journeyTitle: PropTypes.string,
  quiz: PropTypes.object,
};

export default QuizCard;

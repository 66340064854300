import styled from 'styled-components';
import * as Cards from 'components/_styles/Cards.style';
import device from 'constants/devices';
import colours from 'constants/colours';
import shadows from 'constants/dropShadows';

export const Card = styled(Cards.BasicCard)`
  border-radius: 40px;
  box-shadow: ${shadows.M};
  box-sizing: unset;
  flex-direction: unset;
  flex-grow: 1;
  justify-content: unset;
  max-width: unset;
  min-height: 328px;
  overflow: hidden;
  padding: unset;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    border-radius: 30px;
    flex-direction: column;
  }
`;

export const Cover = styled('div')`
  width: 35%;

  @media (max-width: ${device.tablet}) {
    height: 160px;
    width: 100%;
  }
`;

export const CoverImage = styled('img')`
  border-radius: 38px 0 0 38px;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    border-radius: 38px 38px 0 0;
  }
`;

export const DateSection = styled('div')`
  align-items: center;
  display: flex;
  gap: 13px;
  padding: 5px 10px 5px 0;
`;

export const Details = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const InfoSection = styled('div')`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 30px;
  padding: 40px 40px 40px 40px;
  width: 65%;

  @media (max-width: ${device.tablet}) {
    gap: 20px;
    padding: 30px;
    width: 100%;
  }

  @media (max-width: ${device.mobileL}) {
    padding: 20px;
  }
`;

export const LiveChip = styled('div')`
  align-items: center;
  background-color: ${colours.primary[500]};
  border-radius: 30px;
  display: flex;
  gap: 13px;
  justify-content: center;
  padding: 5px 10px;
  width: fit-content;

  p {
    color: white;
  }

  svg path {
    stroke: ${colours.shade[0]};
  }
`;

export const Row = styled('div')`
  align-items: center;
  display: flex;
  gap: 15px;

  @media (max-width: ${device.tablet}) {
    gap: 10px;
  }
`;

export const StatusContainer = styled('div')`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 54px;

  @media (max-width: ${device.tablet}) {
    width: 40px;
  }
`;

export const StatusIcon = styled('div')`
  align-items: center;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  padding: 10px;

  @media (max-width: ${device.tablet}) {
    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

export const StatusComplete = styled(StatusIcon)`
  background-color: ${colours.primary[500]};

  svg path {
    stroke: ${colours.shade[0]};
  }
`;

export const StatusIncomplete = styled(StatusIcon)`
  background-color: ${colours.neutralWarm[200]};

  svg path {
    stroke: ${colours.neutralCool[800]};
  }
`;

export const TwoButtons = styled('div')`
  display: flex;
  gap: 30px;

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const Button2Mobile = styled('div')`
  display: none;

  @media (max-width: ${device.tablet}) {
    display: flex;
  }
`;
export const Button2Desktop = styled('div')`
  display: flex;

  @media (max-width: ${device.tablet}) {
    display: none;
  }
`;

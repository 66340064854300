import Dialog from '@mui/material/Dialog';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { Time } from 'untitledui-js';

// components
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import SubscriptionCard from 'components/buttonCalendarSubscription/SubscriptionCard';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Styled from 'components/buttonCalendarSubscription/_styles/_index.style';

function CalendarSubscriptionButton() {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleFeedbackClick = () => setModalOpen(true);

  return (
    <>
      <Buttons.Secondary onClick={handleFeedbackClick} style={{ flex: 'none' }}>
        <Time.CalenderPlus01 />
        {t('buttonCalendarSubscription:syncButton')}
      </Buttons.Secondary>
      <Dialog
        open={modalOpen || false}
        onClose={handleModalClose}
        onClick={(e) => e.stopPropagation()}
        PaperComponent={Styled.Card}
      >
        <ErrorBoundary>
          <SubscriptionCard handleModalClose={handleModalClose} />
        </ErrorBoundary>
      </Dialog>
    </>
  );
}

export default CalendarSubscriptionButton;

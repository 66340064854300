const SupportingOurselves = {
  evaluationId: 'SupportingOurselves',
  questions: [
    {
      questionNumber: 1,
      question_en:
        'You highly value relationships with your team and try to stop by each of their desks in the morning to check in one on one with them. However, whenever you stop by David’s desk, you never know what mood you’ll find him in. He spends 30 min or more talking to you about his personal problems. You find he is taking a toll on your energy and find yourself drained by the end of this check-in.',
      question_fr:
        "Vous attachez une grande importance aux relations avec votre équipe et vous prenez le temps de passer dans chaque bureau le matin pour échanger quelques mots. Cependant, lorsque vous vous rendez au bureau de David, vous ne savez jamais dans quel état d'esprit vous allez le trouver. Il passe 30 minutes ou plus à vous confier ses problèmes personnels. Vous remarquez que cela pèse sur votre énergie et vous vous sentez épuisé(e) à la fin de cette visite.",
      solution: 3,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Continue stopping by David’s desk, but when he starts to talk past 5 min, tell him that you have other tasks to attend to.',
          option_fr:
            "Poursuivez vos visites au bureau de David, mais lorsque la conversation dépasse 5 minutes, informez-le que vous avez d'autres tâches à accomplir.",
          feedback_en:
            'Though it may seem easier to avoid the problem, David might start to feel ignored. This will be counter-productive to your relationship with him.',
          feedback_fr:
            "Même s'il paraît plus simple d'éviter le problème, David pourrait commencer à se sentir négligé. Cela nuirait à votre relation avec lui.",
        },
        {
          optionNumber: 2,
          option_en:
            'Do an assessment on how the check-ins are affecting your mental health. Try to find an alternative way to check in with your team like hosting office hours with fixed time slots.',
          option_fr:
            "Évaluez l'impact que ces courtes conversations ont sur votre bien-être mental. Essayez de trouver une autre manière de prendre des nouvelles de votre équipe, par exemple en organisant des heures de bureau à des horaires fixes.",
          feedback_en:
            'It is a good idea to revisit the idea of one-on-one check-ins. Having office hours could be a good way of balancing your boundaries with care for your team. However, it is important to not stop checking in on David in order to not single him out.',
          feedback_fr:
            "C'est une bonne idée de repenser le concept d’entretiens en personne. Avoir des heures de bureau pourrait être un bon moyen d'équilibrer vos limites et l'attention que vous accordez à votre équipe. Toutefois, il est important de continuer à prendre des nouvelles de David afin de ne pas le marginaliser.",
        },
        {
          optionNumber: 3,
          option_en:
            'Have a one on one in person meeting with David to let him know you care about him as a person but also to communicate boundaries of how you can or cannot support him.',
          option_fr:
            "Planifiez un entretien individuel avec David afin de lui montrer que vous vous souciez de lui en tant qu'individu, mais également pour lui préciser les limites dans lesquelles vous pouvez ou ne pouvez pas l'assister.",
          feedback_en:
            'Yes, this is the most direct way of addressing the issue. Boundaries are a way of protecting both David and you from burnout.',
          feedback_fr:
            "Effectivement, c'est la manière la plus directe d'aborder le problème. Les limites servent à protéger aussi bien David que vous-même de l'épuisement.",
        },
      ],
    },
    {
      questionNumber: 2,
      question_en:
        'David tells you he knows you can’t handle all his problems, but he is so lonely and has no one else to talk to.',
      question_fr:
        "David vous confie qu'il est conscient que vous ne pouvez pas prendre en charge tous ses problèmes, mais qu'il se sent extrêmement seul et qu'il n'a personne d'autre avec qui échanger.",
      solution: 1,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Thank David for sharing with you. Express that loneliness is a very common human experience. Brainstorm ways with David to build community at the workplace (e.g. team-building events, personal check-ins at meetings)',
          option_fr:
            "Remerciez David d'avoir partagé son ressenti avec vous. Expliquez-lui que la solitude est une expérience humaine naturelle. Réfléchissez ensemble avec David aux moyens de créer une communauté au travail (par exemple, des activités d’équipe, des moments conviviaux lors de réunions).",
          feedback_en:
            'Yes, loneliness at work is very pervasive and destructive. This is a good chance for you to think about how to restructure team rituals and systems to decrease workplace loneliness.',
          feedback_fr:
            "Oui, la solitude au travail est très répandue et nuisible. C'est une excellente occasion pour vous de repenser la manière de restructurer les pratiques et les processus de l'équipe afin de diminuer la solitude au sein de votre groupe.",
        },
        {
          optionNumber: 2,
          option_en:
            'Tell some other colleagues you know that David is lonely and would really appreciate someone else to talk to. Encourage them to give David more support.',
          option_fr:
            "Informez d'autres collègues que vous êtes au courant que David se sent seul et qu'il aimerait sincèrement avoir quelqu'un d'autre avec qui échanger. Encouragez-les à apporter davantage de soutien à David.",
          feedback_en:
            'Although your intention is good, this is a breach of confidentiality. You do not want to perpetuate the negative stigma of loneliness by talking behind David’s back.',
          feedback_fr:
            'Même si votre intention est louable, cela constitue une violation de la confidentialité. Vous ne souhaitez pas renforcer la stigmatisation négative de la solitude en parlant de David à son insu.',
        },
        {
          optionNumber: 3,
          option_en:
            'Assign David to work closely with some other team members on a project. You hope this will give him the social interaction he needs.',
          option_fr:
            "Demandez à David de collaborer étroitement avec d'autres membres de l'équipe sur un projet. Vous espérez que cela lui procurera l'interaction sociale dont il a besoin.",
          feedback_en:
            'This could be helpful for David but looking at how the workplace in general can decrease loneliness is a more holistic approach to addressing the issue.',
          feedback_fr:
            'Cela pourrait être bénéfique pour David, mais une approche plus globale du problème consisterait à étudier comment le lieu de travail dans son ensemble peut contribuer à réduire la solitude.',
        },
      ],
    },
    {
      questionNumber: 3,
      question_en:
        'At the next team meeting, you say, “I’d like to start our meeting with short check-ins from everyone to share how they are doing.” But no one starts sharing and they all look awkwardly around.',
      question_fr:
        "Lors de la prochaine réunion d'équipe, vous déclarez : « J'aimerais débuter notre réunion par de brèves interventions de chacun, afin de partager sa situation ». Cependant, personne ne se porte volontaire et tout le monde se regarde, visiblement mal à l'aise.",
      solution: 2,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Wait until someone else shares, no matter how long it takes.',
          option_fr:
            "Patientez jusqu'à ce qu’un membre de votre équipe se décide à partager, même si cela prend du temps.",
          feedback_en:
            'As the manager, everyone will be looking to you to give direction. You should give some guidance in starting this new initiative.',
          feedback_fr:
            'En tant que gestionnaire, tout le monde attend de vous des directives. Vous devez fournir des conseils pour initier cette nouvelle démarche.',
        },
        {
          optionNumber: 2,
          option_en:
            'Acknowledge that this is a new thing but share your desire to build a community at work where you can support each other even on non-work issues. Start with sharing yourself.',
          option_fr:
            "Reconnaissez qu'il s'agit de quelque chose de nouveau, mais exprimez votre volonté de créer une communauté au travail où vous pouvez vous soutenir mutuellement, même sur des sujets non professionnels. Commencez par vous confier.",
          feedback_en:
            'Yes, it is good to call out potential barriers instead of ignoring them. Being vulnerable yourself is a great way to start.',
          feedback_fr:
            'Oui, il est judicieux de mentionner les obstacles potentiels plutôt que de les ignorer. Montrer sa propre vulnérabilité est un excellent point de départ.',
        },
        {
          optionNumber: 3,
          option_en:
            'See this as a sign that this practice will not work for your team and call it off.',
          option_fr:
            'Envisagez cela comme un signe que cette pratique ne conviendra pas à votre équipe et annulez-la.',
          feedback_en:
            'Building work community takes a long time. Don’t be discouraged if there is slow uptake or people take a while to warm up to the idea.',
          feedback_fr:
            "La création d'une communauté de travail demande du temps. Ne vous découragez pas si l'adhésion est lente ou si les gens mettent du temps à s'adapter à l'idée.",
        },
      ],
    },
    {
      questionNumber: 4,
      question_en:
        'You got feedback yesterday from your supervisor that a few anonymous employees claimed that you come across as abrasive. You keep ruminating on this feedback and can’t help but think your whole team is against you. You find yourself wondering constantly which of your employees thought this of you.',
      question_fr:
        "Hier, votre supérieur vous a informé que quelques employés ont déclaré, sous couvert d'anonymat, que vous sembliez agressif.ve. Vous réfléchissez à ces informations et ne pouvez vous empêcher de penser que toute votre équipe est contre vous. Vous vous demandez sans cesse lesquels de vos collaborateurs ont eu cette impression.",
      solution: 1,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Call a team meeting and address the issue directly. Tell your team that if they have something to bring up, they can approach you first before going to your supervisor.',
          option_fr:
            "Organisez une réunion d'équipe et abordez le problème directement. Dites à votre équipe que si elle a quelque chose à mentionner, elle peut d'abord s'adresser à vous avant de s'adresser à votre gestionnaire.",
          feedback_en:
            'Yes, your team should know that you are aware of your weakness and are intent on change. It will build team psychological safety for them to know you are receptive to direct feedback.',
          feedback_fr:
            "En effet, votre équipe doit savoir que vous êtes conscient(e) de vos faiblesses et que vous avez l'intention de changer. Le fait de savoir que vous êtes réceptif(ive) à un retour d'information direct renforcera la sécurité psychologique de l'équipe.",
        },
        {
          optionNumber: 2,
          option_en:
            'Recognize you are jumping to conclusions. Some people may think that of you, but not everyone. Try to be more aware of your tone when speaking to your employees but also know you cannot please everyone.',
          option_fr:
            "Reconnaissez que vous tirez des conclusions hâtives. Certaines personnes peuvent penser cela de vous, mais pas toutes. Essayez d'être plus attentif(ive) au ton que vous employez lorsque vous parlez à vos employés, tout en sachant que vous ne pouvez pas plaire à tout le monde.",
          feedback_en:
            'It is good to recognize your cognitive distortions and reframe this as not entirely your fault. However, this is a good opportunity to increase your team’s psychological safety by acknowledging a mistake and opening up channels for them to directly give you feedback next time.',
          feedback_fr:
            "Il est judicieux de reconnaître vos distorsions cognitives et de recontextualiser la situation comme n'étant pas entièrement de votre faute. Cependant, c'est une bonne occasion d'accroître la sécurité psychologique de votre équipe en admettant une erreur et en ouvrant des canaux pour qu'ils puissent vous faire part directement de leurs commentaires la prochaine fois.",
        },
        {
          optionNumber: 3,
          option_en:
            'Meet with each of your employees and ask them directly if they find you abrasive. Ask them for specific examples so you know how to improve.',
          option_fr:
            "Rencontrez vos employé(e)s individuellement et demandez-leur directement s'ils vous perçoivent comme agressif(ive). Demandez des exemples précis afin de comprendre comment vous améliorer.",
          feedback_en:
            'This can come across as too confrontational and accusatory. You might also become defensive during these one-on-one talks which will reduce psychological safety.',
          feedback_fr:
            "Cela peut donner l'impression d'une confrontation et d'une accusation. Vous risquez également de vous montrer sur la défensive lors de ces entretiens individuels, ce qui pourrait affecter votre sécurité psychologique.",
        },
      ],
    },
    {
      questionNumber: 5,
      question_en:
        'During the team meeting, you are honest about how the feedback made you feel. You then open up the floor to feedback from your team. They are awkward at first, but some of them start to provide specific examples of when your tone of voice was abrasive and demanding. You immediately feel defensive.',
      question_fr:
        "Lors de la réunion de votre équipe, vous exprimez ouvertement ce que vous avez ressenti suite au retour d'information. Vous donnez ensuite la parole aux membres de votre équipe pour qu'ils partagent leurs commentaires. Ils sont d'abord gênés, mais certains d'entre eux commencent à donner des exemples précis de situations où votre ton de voix était agressif et exigeant. Vous vous sentez immédiatement sur la défensive.",
      solution: 1,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Observe how your body is reacting when you become defensive. Bring to mind statements of self-compassion, “You don’t have to be perfect. You are doing the best job you can.”',
          option_fr:
            "Observez votre réaction corporelle lorsque vous êtes sur la défensive. Rappelez-vous les affirmations d’autocompassion : « Vous n'avez pas besoin d'être parfait(e). Vous faites de votre mieux. »",
          feedback_en:
            'Yes, this is a great opportunity to practice mindfulness. It is normal to react this way instinctively but you can observe this reaction without acting on it right away.',
          feedback_fr:
            "Oui, c'est une excellente occasion de pratiquer le principe de la pleine conscience. Il est normal de réagir ainsi instinctivement, mais vous pouvez observer cette réaction sans agir immédiatement.",
        },
        {
          optionNumber: 2,
          option_en:
            'Think about ways you could be improving in detecting these signs of discomfort earlier. As a manager, you should be more attentive to when your team is feeling this way.',
          option_fr:
            "Réfléchissez aux moyens que vous pourriez mettre en œuvre pour détecter plus tôt ces signes d'inconfort. En tant que chef(fe) d’équipe, vous devriez être plus attentif(ive) aux moments où votre équipe ressent ce genre de choses.",
          feedback_en:
            'This may be an automatic thought but jumping to what you did wrong and what you should have done instead will only increase rumination and anxiety of messing up in the future.',
          feedback_fr:
            "Il est possible que ce soit une pensée automatique, mais se demander ce que l'on a fait de mal et ce que l'on aurait dû faire à la place ne fera qu'augmenter la rumination et l'anxiété de se tromper à l'avenir.",
        },
        {
          optionNumber: 3,
          option_en:
            'Apologize to each team member who speaks up for the way you came across. Ask what you could have said instead for the next time.',
          option_fr:
            "Présentez vos excuses à chaque membre de l'équipe qui partage ses préoccupations concernant la manière dont vous vous êtes exprimé(e). Demandez ce que vous pourriez faire pour vous améliorer.",
          feedback_en:
            'Given that you feel defensive, you might not be in the right state of mind to apologize yet. If the apology is not authentic, it will show through and undermine what you are trying to do.',
          feedback_fr:
            "Si vous vous sentez sur la défensive, vous n'êtes peut-être pas encore dans le bon état d'esprit pour vous excuser. Si les excuses ne sont pas authentiques, cela se verra et compromettra ce que vous essayez de faire.",
        },
      ],
    },
    {
      questionNumber: 6,
      question_en:
        'During your time of mindfulness, you notice your body is still tense and you do not feel you can respond appropriately to your team’s comments.',
      question_fr:
        'Lors de votre moment de pleine conscience, vous remarquez que votre corps est toujours tendu et que vous ne vous sentez pas capable de répondre de manière appropriée aux commentaires de votre équipe.',
      solution: 1,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Thank the team for their input. Honestly acknowledge that you are still taking everything in and need time to process. You will follow up with them in a few days.',
          option_fr:
            'Remerciez les membres de votre équipe pour leurs contributions. Reconnaissez honnêtement que vous êtes encore en train de prendre en compte toutes les informations et que vous avez besoin de temps pour les assimiler. Vous ferez un suivi avec eux dans quelques jours.',
          feedback_en:
            'Yes, even if you cannot give a full reply, you can still thank the team and continue the conversation when you have spent more time thinking through your response. In your follow-up with your team when you are feeling less defensive, you can then apologize for specific ways you have been abrasive and communicate your intentions to improve.',
          feedback_fr:
            "En effet, même si vous ne pouvez pas apporter une réponse complète, vous pouvez toujours remercier l'équipe et poursuivre la conversation lorsque vous aurez pris le temps de réfléchir à votre réponse. Lors du suivi avec votre équipe, alors que vous vous sentez moins sur la défensive, vous pouvez vous excuser pour les manières spécifiques dont vous avez été agressif(ive) et exprimer votre intention de vous améliorer.",
        },
        {
          optionNumber: 2,
          option_en:
            'End the meeting on that note and re-open the subject when you have gathered your thoughts.',
          option_fr:
            'Terminez la réunion sur cette note et rouvrez le sujet lorsque vous aurez rassemblé vos idées.',
          feedback_en:
            'It was also vulnerable of your employees to have shared their honest feedback with you. Even though it is difficult, you should acknowledge how this process made you feel but that you welcome this sort of input.',
          feedback_fr:
            "Vos employés ont également fait preuve de vulnérabilité en vous faisant part de leurs commentaires honnêtes. Même si c'est difficile, vous devriez reconnaître que ce processus vous a fait éprouver des émotions, mais que vous accueillez favorablement ce type de contribution.",
        },
        {
          optionNumber: 3,
          option_en:
            'Ask one of your team members to lead the rest of the meeting while you quietly excuse yourself and leave the situation to calm down.',
          option_fr:
            "Demandez à l'un des membres de votre équipe de prendre en charge le reste de la réunion pendant que vous vous excusez discrètement de la réunion pour vous calmer.",
          feedback_en:
            'Leaving the situation could be helpful for you, but it leaves the team feeling uncomfortable for having shared honestly with you.',
          feedback_fr:
            "Quitter la réunion peut être bénéfique pour vous, mais l'équipe risque de se sentir mal à l'aise d'avoir partagé honnêtement avec vous.",
        },
      ],
    },
    {
      questionNumber: 7,
      question_en:
        'You were recently promoted to being a manager but find that you are quickly burning out. The managers above you are unavailable, and you do not have the support or training you need.',
      question_fr:
        'Vous avez récemment été promu(e) au poste de gestionnaire, mais vous vous rendez compte que vous vous épuisez rapidement. Vos supérieurs ne sont pas disponibles et vous ne bénéficiez pas du soutien ou de la formation dont vous avez besoin.',
      solution: 1,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Spend time reflecting on what this job is doing for you on a personal and professional level. If you are given more than you can handle, speak to your supervisors about a role change.',
          option_fr:
            "Prenez le temps de réfléchir à ce que ce travail vous apporte sur le plan personnel et professionnel. Si l'on vous donne plus que vous ne pouvez gérer, parlez à vos supérieurs d'un changement de rôle.",
          feedback_en:
            'Yes, it is important to begin reflecting on whether this change suits your capacities and needs, and whether you are given enough support to handle it. While this might be a phase, starting to reflect now will give you time to know if this is a chronic and persistent problem before it is too late. [SI1]',
          feedback_fr:
            "Oui, il est important de commencer à réfléchir pour déterminer si ce changement correspond à vos capacités et à vos besoins, et si vous bénéficiez d'un soutien suffisant pour y faire face. Bien qu'il puisse s'agir d'une phase, le fait de commencer à réfléchir dès maintenant vous donnera le temps de savoir s'il s'agit d'un problème chronique et persistant avant qu'il ne soit trop tard.",
        },
        {
          optionNumber: 2,
          option_en:
            'Delegate as much as possible to other people and stop holding yourself to such high expectations. Do what is minimal until you find you can keep your head above water.',
          option_fr:
            "Déléguez autant que possible à d'autres personnes et cessez d'avoir des attentes trop élevées. Faites le minimum jusqu'à ce que vous puissiez garder la tête hors de l'eau.",
          feedback_en:
            'This may be a short-term solution to an overwhelming workload. However, this will not be sustainable in the long term. If you do not find the situation improving, a role change or a discussion with your supervisors is necessary.',
          feedback_fr:
            "Ceci peut être une solution à court terme pour faire face à une charge de travail écrasante. Toutefois, elle ne sera pas viable à long terme. Si la situation ne s'améliore pas, un changement de rôle ou une discussion avec vos supérieurs est nécessaire.",
        },
        {
          optionNumber: 3,
          option_en:
            'Tough it out – this learning phase is normal for new managers and will soon pass.',
          option_fr:
            "Tenez bon - cette phase d'apprentissage est normale pour les nouveaux gestionnaires et prendra fin bientôt.",
          feedback_en:
            'Although it is true that all new managers go through a learning phase, it is not healthy or normal to be in a constant state of burnout without support. Burnout can have drastic impacts on your mental health and productivity, and it needs to be addressed early if you are unable to cope.',
          feedback_fr:
            "S'il est vrai que tous les nouveaux gestionnaires passent par une phase d'apprentissage, il n'est ni sain, ni normal, de se trouver dans un état constant d'épuisement professionnel sans soutien. L'épuisement professionnel peut avoir des conséquences importantes sur votre santé mentale et votre productivité et doit être abordé rapidement si vous n'êtes pas en mesure de le gérer.",
        },
      ],
    },
  ],
};

export default SupportingOurselves;

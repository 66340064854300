import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routesConfig from 'constants/routesConfig.json';
import userRoles from 'constants/userRoles.json';

// assets
import { Arrow, MediaDevices } from 'untitledui-js';

// components
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';

// context
import { useUser } from 'contexts/UserContext';

// hooks
import useApi from 'hooks/useApi';

// styles
import * as Buttons from 'components/_styles/Buttons.style';

function JoinLobbyButton(props) {
  const { bookingId, icon, journeyId, microlearningId, microlearningTitle } =
    props;
  const { data: chatroomData, callApi: joinChatroom } = useApi();
  const history = useHistory();
  const { t } = useTranslation();
  const { userRole } = useUser();

  const handleButtonClick = useCallback(() => {
    history.push({
      pathname: routesConfig.CHATROOM_PAGE,
      state: {
        bookingId,
        journeyId,
        microlearningId,
        microlearningTitle,
        roomId: chatroomData.response.room_id,
      },
    });
  }, [
    bookingId,
    chatroomData,
    history,
    journeyId,
    microlearningId,
    microlearningTitle,
  ]);

  useEffect(() => {
    if (userRole === userRoles.OWL)
      joinChatroom(`/owls/schedules/${bookingId}/chatroom/join/`);
    if (userRole === userRoles.SUPPORT_ADMIN)
      joinChatroom(`/support_admins/schedules/${bookingId}/chatroom/join/`);
    if (userRole === userRoles.MEMBER)
      joinChatroom(`/bookings/${bookingId}/chatroom/join/`);
  }, [bookingId, joinChatroom, userRole]);

  if (icon)
    return (
      <ErrorBoundary
        fallback={
          <Buttons.PrimaryIconDisabled>
            <MediaDevices.VideoRecorder />
          </Buttons.PrimaryIconDisabled>
        }
      >
        <Buttons.PrimaryIcon onClick={handleButtonClick}>
          <MediaDevices.VideoRecorder />
        </Buttons.PrimaryIcon>
      </ErrorBoundary>
    );

  return (
    <ErrorBoundary
      fallback={
        <Buttons.PrimaryDisabled>
          <Arrow.ArrowNarrowUp />
          {t('buttonJoinLobby:error')}
        </Buttons.PrimaryDisabled>
      }
    >
      <Buttons.Primary onClick={handleButtonClick}>
        <MediaDevices.VideoRecorder />
        {t('buttonJoinLobby:joinCall')}
      </Buttons.Primary>
    </ErrorBoundary>
  );
}

JoinLobbyButton.propTypes = {
  bookingId: PropTypes.string,
  icon: PropTypes.bool,
  journeyId: PropTypes.string,
  microlearningId: PropTypes.string,
  microlearningTitle: PropTypes.string,
};

export default JoinLobbyButton;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { useTranslation } from 'react-i18next';

// assets
import { Close } from '@mui/icons-material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { IconButton } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

// hooks
import useApi from 'hooks/useApi';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Forms from 'components/_styles/Forms.style';
import * as Text from 'components/_styles/Text.style';

const MemberFormAdd = ({
  handleModalClose,
  setAlert,
  setRefreshMembersList,
}) => {
  const { t } = useTranslation();
  const { data: submission, callApi } = useApi();

  const firstNameRef = useRef('');
  const lastNameRef = useRef('');
  const emailRef = useRef('');
  const jobTitleRef = useRef('');

  const [invalidEmail, setInvalidEmail] = useState();
  const [language, setLanguage] = useState('en');

  const handleLanguageChange = (event) => setLanguage(event.target.value);

  function handleSave() {
    if (!validator.isEmail(emailRef.current.value)) {
      setInvalidEmail(true);
    } else {
      const params = {
        user: {
          email: emailRef.current.value,
          first_name: firstNameRef.current.value,
          language,
          last_name: lastNameRef.current.value,
        },
        job_title: jobTitleRef.current.value,
      };
      callApi('/members/', 'post', params);
      emailRef.current = '';
      firstNameRef.current = '';
      jobTitleRef.current = '';
      lastNameRef.current = '';
    }
  }

  useEffect(() => {
    if (submission.status === 'success') {
      setAlert(t('pageOrganization:addMemberSuccess'));
      setRefreshMembersList(true);
    }
    if (submission.status === 'error')
      setAlert(t('pageOrganization:addMemberError'));
    if (['success', 'error'].includes(submission.status)) handleModalClose();
  }, [submission.status, t, setAlert, handleModalClose, setRefreshMembersList]);

  return (
    <>
      <Forms.FormCard>
        <Forms.CloseButtomArea>
          <IconButton
            aria-label={t('pageOrganization:close')}
            onClick={handleModalClose}
          >
            <Close />
          </IconButton>
        </Forms.CloseButtomArea>
        <Forms.InfoArea style={{ paddingTop: 0 }}>
          <Text.H2>{t('pageOrganization:addMember')}</Text.H2>
        </Forms.InfoArea>
        <Forms.ResponseArea>
          <Text.CaptionCap>{t('pageOrganization:firstName')}</Text.CaptionCap>
          <Forms.InputField
            label={t('pageOrganization:firstName')}
            placeholder={t('pageOrganization:firstName')}
            ref={firstNameRef}
          />
          <Text.CaptionCap>{t('pageOrganization:lastName')}</Text.CaptionCap>
          <Forms.InputField
            label={t('pageOrganization:lastName')}
            placeholder={t('pageOrganization:lastName')}
            ref={lastNameRef}
          />
          <Text.CaptionCap>{t('pageOrganization:email')}</Text.CaptionCap>
          {invalidEmail && (
            <Text.P1 style={{ color: 'red', margin: 0 }}>
              {t('pageOrganization:invalidEmail')}
            </Text.P1>
          )}
          <Forms.InputField
            label={t('pageOrganization:email')}
            placeholder="Email"
            ref={emailRef}
            type="email"
          />
          <Text.CaptionCap>{t('pageOrganization:jobTitle')}</Text.CaptionCap>
          <Forms.InputField
            label={t('pageOrganization:jobTitle')}
            placeholder={t('pageOrganization:jobTitle')}
            ref={jobTitleRef}
          />
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            onChange={handleLanguageChange}
            row
            sx={{ padding: '10px' }}
            value={language}
          >
            <FormControlLabel
              control={<Radio />}
              label={t('pageOrganization:english')}
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 36,
                },
              }}
              value="en"
            />
            <FormControlLabel
              control={<Radio />}
              label={t('pageOrganization:french')}
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 36,
                },
              }}
              value="fr"
            />
          </RadioGroup>
        </Forms.ResponseArea>
        <Forms.ActionArea>
          {firstNameRef && lastNameRef && emailRef ? (
            <Buttons.Primary onClick={handleSave}>
              {t('pageOrganization:save')}
            </Buttons.Primary>
          ) : (
            <Buttons.PrimaryDisabled>
              {t('pageOrganization:save')}
            </Buttons.PrimaryDisabled>
          )}
        </Forms.ActionArea>
      </Forms.FormCard>
    </>
  );
};

export default MemberFormAdd;

MemberFormAdd.propTypes = {
  handleModalClose: PropTypes.func,
  setAlert: PropTypes.func,
  setRefreshMembersList: PropTypes.func,
};

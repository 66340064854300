import React from 'react';
import PropTypes from 'prop-types';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageJourneyId/_styles/DetailsCard.style';
import * as Text from 'components/_styles/Text.style';

function DetailsCard({ journey }) {
  const lang = useUserLanguage();
  const journeyDescription = journey[`description_${lang}`];

  return (
    <Styled.Card>
      {journeyDescription && (
        <Text.P3Semibold>{journeyDescription}</Text.P3Semibold>
      )}
    </Styled.Card>
  );
}

export default DetailsCard;

DetailsCard.propTypes = {
  journey: PropTypes.object,
};

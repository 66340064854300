import React from 'react';
import PropTypes from 'prop-types';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageEvaluation/_styles/_index.style';

function QuizFeedback({ currentQuestion, selectedOption }) {
  const lang = useUserLanguage();

  const feedbackText =
    currentQuestion.options[selectedOption - 1][`feedback_${lang}`];
  if (selectedOption === currentQuestion.solution) {
    return (
      <Styled.CorrectBlock>
        <Styled.CorrectText>{feedbackText}</Styled.CorrectText>
      </Styled.CorrectBlock>
    );
  }
  return (
    <Styled.IncorrectBlock>
      <Styled.IncorrectText>{feedbackText}</Styled.IncorrectText>
    </Styled.IncorrectBlock>
  );
}

QuizFeedback.propTypes = {
  currentQuestion: PropTypes.object,
  selectedOption: PropTypes.number,
};

export default QuizFeedback;

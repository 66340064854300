import React, { useState } from 'react';
import PropTypes from 'prop-types';

// components
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import OwlProfileModal from 'components/buttonOwl/OwlProfileModal';

// styles
import * as Modal from 'components/_styles/Modal.style';
import * as Text from 'components/_styles/Text.style';

function OwlLink({ owlInfo }) {
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleOwlClick = () => setModalOpen(true);
  const owlName = `${owlInfo.user.first_name} ${owlInfo.user.last_name}`;

  return (
    <>
      <Text.P1 onClick={handleOwlClick}>{owlName}</Text.P1>
      {modalOpen && (
        <Modal.Background onClose={handleModalClose} open={modalOpen}>
          <div>
            <ErrorBoundary>
              <OwlProfileModal
                handleModalClose={handleModalClose}
                owlInfo={owlInfo}
              />
            </ErrorBoundary>
          </div>
        </Modal.Background>
      )}
    </>
  );
}

OwlLink.propTypes = {
  owlInfo: PropTypes.object.isRequired,
};

export default OwlLink;

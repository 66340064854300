import React from 'react';
import PropTypes from 'prop-types';

// components
import MicrolearningCardButtons from 'components/pageJourneyId/memberView/MicrolearningCardButtons';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageJourneyId/_styles/MicrolearningCard.style';
import * as Text from 'components/_styles/Text.style';

function MicrolearningCardMember({ cover, journey, microlearning }) {
  const lang = useUserLanguage();
  const microlearningTitle = microlearning[`title_${lang}`];
  const microlearningDescription = microlearning[`description_${lang}`];

  return (
    <Styled.Row>
      <Styled.Card>
        <Styled.Cover>
          <Styled.CoverImage src={cover} />
        </Styled.Cover>
        <Styled.InfoSection>
          {microlearningTitle && (
            <Text.H5Bold>{microlearningTitle}</Text.H5Bold>
          )}
          <Styled.Details>
            {microlearningDescription && (
              <Text.P2Semibold>{microlearningDescription}</Text.P2Semibold>
            )}
          </Styled.Details>
          <MicrolearningCardButtons
            cover={cover}
            journey={journey}
            microlearning={microlearning}
          />
        </Styled.InfoSection>
      </Styled.Card>
    </Styled.Row>
  );
}

MicrolearningCardMember.propTypes = {
  cover: PropTypes.string,
  journey: PropTypes.object,
  microlearning: PropTypes.object,
};

export default MicrolearningCardMember;

import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';

export const Section = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Content = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 40em;
  padding: 60px;
  width: 100%;

  h3,
  p {
    text-align: center;
  }

  svg {
    height: 80px;
    width: 80px;
  }

  @media (max-width: ${device.tablet}) {
    padding: 10px;
  }
`;

export const SuccessCard = styled(Content)`
  h3 {
    color: ${colours.mint[700]};
  }
`;

export const ErrorCard = styled(Content)`
  h3 {
    color: ${colours.error[800]};
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

// assets
import { ReactComponent as RedXIcon } from 'assets/red-x.svg';

// styles
import * as Styled from 'components/pageDemoRegistration/_styles/RegistrationFormSubmitted.style';
import * as Text from 'components/_styles/Text.style';
import { Link } from '@mui/material';
import { Trans } from 'react-i18next';

const RegistrationErrorCard = ({ t }) => (
  <Styled.Section>
    <Styled.ErrorCard>
      <RedXIcon />
      <Text.H3>{t('Sorry, something went wrong!')}</Text.H3>
      <Text.P3>
        <Trans
          i18nKey="reachUsError"
          components={[
            <Link key="0" href="mailto:info@nurau.com" underline="none" />,
          ]}
          ns="pageDemoRegistration"
        />
      </Text.P3>
    </Styled.ErrorCard>
  </Styled.Section>
);

export default RegistrationErrorCard;

RegistrationErrorCard.propTypes = {
  t: PropTypes.func.isRequired,
};

import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';
import * as Text from 'components/_styles/Text.style';

export const Card = styled('div')`
  background-color: ${colours.shade[0]};
  border-radius: 40px;
  box-shadow: ${shadows.XS};
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

export const ActionArea = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
    height: 125px;
    justify-content: flex-start;
  }
`;

export const DataCol = styled('div')`
  flex: 2;
  padding: 30px;

  @media (max-width: ${device.tablet}) {
    padding: 0 0 30px 0;
  }
`;

export const EditActionArea = styled(ActionArea)`
  justify-content: space-between;
`;

export const Image = styled('img')`
  border-radius: 20px;
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const ImageContainer = styled('div')`
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  flex-shrink: 0;
  height: 200px;
  margin-right: 30px;
  width: 200px;
`;

export const Input = styled('input')`
  display: none;
`;

export const Label = styled(Text.P1)`
  font-weight: 700;
  margin: 0;
`;

export const LabelCol = styled('div')`
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  flex: 1;
  padding: 30px 30px 30px 0;

  @media (max-width: ${device.tablet}) {
    border: none;
    padding: 0 0 15px 0;
  }
`;

export const LabelDescription = styled(Text.P1)`
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;

  @media (max-width: ${device.tablet}) {
    margin-top: 5px;
  }
`;

export const PhotoCol = styled(DataCol)`
  align-items: initial;
  display: flex;
  flex: 2;
  justify-content: space-between;
  padding: 30px;

  @media (max-width: ${device.tablet}) {
    padding: 0 0 30px 0;
  }
`;

export const Row = styled('div')`
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  display: flex;

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
    margin-bottom: 30px;
  }
`;

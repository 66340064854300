import React from 'react';
import PropTypes from 'prop-types';
import * as Text from 'components/_styles/Text.style';
import { Arrow } from 'untitledui-js';
import { Stack, Divider } from '@mui/material';
import ConditionalWrapper from 'components/conditionalWrapper/_index';
import { LoadingStatus } from '../../hooks/useEcoachItemsConsumer';
import * as Styled from './_styles/Report.style';
import LoadingAwareWrapper from './LoadingAwareItem';

export default function ClickableListCard({
  initialText,
  isNumbered,
  ItemDetailsComponent,
  items,
  ListItemComponent,
  listTitle,
  selectedItem,
  selectItem,
}) {
  const handleSelectItem = (itemId) => {
    selectItem(itemId);
  };

  if (selectedItem.status !== LoadingStatus.LOADED) {
    return (
      <>
        <Text.CaptionSemiboldCap>{listTitle || 'List'}</Text.CaptionSemiboldCap>
        {initialText && <Text.P2>{initialText}</Text.P2>}
        <Stack divider={<Divider flexItem />}>
          {items.map((item, index) => (
            <Styled.ActionItem key={item.id}>
              <LoadingAwareWrapper
                itemId={item.id}
                loadingId={selectedItem.data?.id}
                handleClick={() => handleSelectItem(item.id)}
              >
                <ConditionalWrapper
                  condition={isNumbered}
                  wrapper={(children) => (
                    <Styled.NumberedContent>{children}</Styled.NumberedContent>
                  )}
                >
                  {isNumbered && (
                    <Styled.NumberBox>
                      <Styled.Number>{index + 1}</Styled.Number>
                    </Styled.NumberBox>
                  )}
                  <Styled.Content>
                    <ListItemComponent item={item} />
                  </Styled.Content>
                </ConditionalWrapper>
              </LoadingAwareWrapper>
            </Styled.ActionItem>
          ))}
        </Stack>
      </>
    );
  }

  // TODO: Here we could add some state for selected item gen error from Status ERROR
  return (
    <>
      <Styled.BackButton onClick={() => selectItem(null)}>
        <Arrow.ChevronLeft size={'15'} />
        {listTitle || 'Back to List'}
      </Styled.BackButton>
      <Text.SubheadingSemibold>
        {selectedItem.data.title}
      </Text.SubheadingSemibold>
      <ItemDetailsComponent item={selectedItem.data} />
    </>
  );
}

ClickableListCard.propTypes = {
  initialText: PropTypes.string,
  isNumbered: PropTypes.bool,
  items: PropTypes.array,
  ItemDetailsComponent: PropTypes.elementType.isRequired,
  ListItemComponent: PropTypes.elementType.isRequired,
  listTitle: PropTypes.string,
  selectedItem: PropTypes.object,
  selectItem: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function StyleProvider({ children }) {
  const theme = createTheme();

  theme.palette.primary = {
    main: '#ff6b61',
    contrastText: 'white',
  };

  theme.palette.text.primary = 'rgba(0, 0, 0, 0.8)';

  theme.palette.background.default = '#f9f9f9';

  theme.typography.h2.fontSize = '18px';

  theme.typography.h3 = {
    fontSize: '16px',
    fontWeight: '700',
  };

  theme.typography.h4 = {
    fontSize: '14px',
    fontWeight: 500,
  };

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

StyleProvider.propTypes = {
  children: PropTypes.node,
};

export default StyleProvider;

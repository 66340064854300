import React from 'react';
import PropTypes from 'prop-types';

// components
import ChatGuide from 'components/pageECoach/chatStart/ChatGuide';
import ChatLanding from 'components/pageECoach/chatStart/ChatLanding';
import ConvoPrepForm from 'components/pageECoach/convoPrep/ConvoPrepForm';
import PromptHelper from 'components/pageECoach/promptHelper/_index';
import SampleCases from 'components/pageECoach/chatStart/SampleCases';
import { usePostHog, useFeatureFlagEnabled } from 'posthog-js/react';

// context & helpers
import {
  SessionProgressChoices,
  UserFeatureFlagType,
} from 'components/pageECoach/utils';
import { useSessionContext } from 'contexts/ECoachSessionContext';

export default function ChatStart({ chatGuideData, setChatGuideData }) {
  const { session, updateSession } = useSessionContext();
  const posthog = usePostHog();

  const isChallengeClarificationEnabled = useFeatureFlagEnabled(
    UserFeatureFlagType.ECOACH_CHALLENGE_CLARIFICATION
  );

  const openChatGuide = () => {
    const updates = { inProgress: true, chatGuideClicked: true };
    setChatGuideData((prev) => ({ ...prev, ...updates }));
    posthog?.capture('user_clicks_chat_guide');
  };

  const openPromptHelper = () => {
    const updates = {
      displayConvoPrep: false,
      displayPromptHelper: true,
      displaySampleCases: false,
      chatStartEnded: false,
    };

    setChatGuideData((prev) => ({ ...prev, ...updates }));
    posthog?.capture('user_clicks_prompt_helper');
  };

  const openConvoPrep = () => {
    const updates = {
      displayConvoPrep: true,
      displayPromptHelper: false,
      displaySampleCases: false,
      chatStartEnded: false,
    };

    setChatGuideData((prev) => ({ ...prev, ...updates }));
    posthog?.capture('user_clicks_convo_prep_form');
  };

  const openSampleCases = () => {
    const updates = { displayPromptHelper: false, displaySampleCases: true };
    setChatGuideData((prev) => ({ ...prev, ...updates }));
  };

  const onChallengeSubmission = (userInput) => {
    const nextProgress = isChallengeClarificationEnabled
      ? SessionProgressChoices.CHALLENGE_VERIFICATION
      : SessionProgressChoices.SUGGESTIONS;

    updateSession(session?.id, {
      context: { description: userInput },
      progress: nextProgress,
    });
    posthog?.capture('user_submits_eCoach_challenge');

    const updates = {
      inProgress: false,
      chatStartEnded: true,
    };

    setChatGuideData((prev) => ({ ...prev, ...updates }));
  };

  if (chatGuideData.displayConvoPrep)
    return (
      <ConvoPrepForm
        chatGuideData={chatGuideData}
        setChatGuideData={setChatGuideData}
      />
    );

  if (chatGuideData.displayPromptHelper)
    return (
      <PromptHelper
        chatGuideData={chatGuideData}
        onSubmit={onChallengeSubmission}
        setChatGuideData={setChatGuideData}
      />
    );

  if (chatGuideData.displaySampleCases)
    return (
      <SampleCases
        chatGuideData={chatGuideData}
        onSubmit={onChallengeSubmission}
      />
    );

  if (chatGuideData.chatGuideClicked)
    return (
      <ChatGuide
        openConvoPrep={openConvoPrep}
        openPromptHelper={openPromptHelper}
        openSampleCases={openSampleCases}
      />
    );

  return (
    <>
      {!session && (
        <ChatLanding
          onSubmit={onChallengeSubmission}
          openChatGuide={openChatGuide}
        />
      )}
    </>
  );
}

ChatStart.propTypes = {
  chatGuideData: PropTypes.object,
  setChatGuideData: PropTypes.func,
};

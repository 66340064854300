export function useFullWeek(lang = 'en') {
  const translations = {
    en: {
      sunday: 'sunday',
      monday: 'monday',
      tuesday: 'tuesday',
      wednesday: 'wednesday',
      thursday: 'thursday',
      friday: 'friday',
      saturday: 'saturday',
    },
    fr: {
      sunday: 'dimanche',
      monday: 'lundi',
      tuesday: 'mardi',
      wednesday: 'mercredi',
      thursday: 'jeudi',
      friday: 'vendredi',
      saturday: 'samedi',
    },
  };

  return [
    translations[lang].sunday,
    translations[lang].monday,
    translations[lang].tuesday,
    translations[lang].wednesday,
    translations[lang].thursday,
    translations[lang].friday,
    translations[lang].saturday,
  ];
}

export function usePartialWeek(lang = 'en') {
  const translations = {
    en: {
      sun: 'sun',
      mon: 'mon',
      tue: 'tue',
      wed: 'wed',
      thu: 'thu',
      fri: 'fri',
      sat: 'sat',
    },
    fr: {
      sun: 'dim',
      mon: 'lun',
      tue: 'mar',
      wed: 'mer',
      thu: 'jeu',
      fri: 'ven',
      sat: 'sam',
    },
  };
  return [
    translations[lang].sun,
    translations[lang].mon,
    translations[lang].tue,
    translations[lang].wed,
    translations[lang].thu,
    translations[lang].fri,
    translations[lang].sat,
  ];
}

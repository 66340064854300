import React from 'react';
import PropTypes from 'prop-types';

import { MenuItem } from '@mui/material';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import useCompanySizeChoices from 'hooks/demo/useCompanySizeChoices';

export default function CompanySizeField({ currentLanguage, t }) {
  const companySizeChoices = useCompanySizeChoices(currentLanguage);

  const companySizeChoicesOptions = React.useMemo(() => {
    if (!companySizeChoices || companySizeChoices.length === 0) {
      return [
        <MenuItem key="loading" value="loading">
          Loading...
        </MenuItem>,
      ];
    }
    return companySizeChoices.map((companySize) => (
      <MenuItem key={companySize.code} value={companySize.code}>
        {companySize.display}
      </MenuItem>
    ));
  }, [companySizeChoices]);

  return (
    <Field
      component={Select}
      name="additional_infos.company_size"
      label={t('Company Size')}
    >
      {companySizeChoicesOptions}
    </Field>
  );
}

CompanySizeField.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

import React from 'react';
import { useLocation } from 'react-router-dom';

// assets
import LogoText from 'assets/logoText.svg';

// components
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import QuizCard from 'components/pageEvaluation/QuizCard';

// constants
import routesConfig from 'constants/routesConfig.json';

// hooks
import getQuiz from 'components/pageEvaluation/evaluations/_getQuiz';

// styles
import * as Styled from 'components/pageEvaluation/_styles/_index.style';
import * as StyledLogo from 'components/navbar/_styles/Navbar.style';
import * as StyledTopbar from 'components/navbar/_styles/Topbar.style';

function EvaluationPage() {
  const { state } = useLocation();
  const { evaluationId, journeyId, journeyTitle } = state;
  const quiz = getQuiz(evaluationId);

  return (
    <ErrorBoundary>
      <Styled.Page>
        <StyledTopbar.ContainerStatic>
          <StyledTopbar.TopbarLogo
            to={{ pathname: routesConfig.JOURNEY_LIST_PAGE }}
          >
            <StyledLogo.LogoText src={LogoText} />
          </StyledTopbar.TopbarLogo>
        </StyledTopbar.ContainerStatic>
        <Styled.CardSection>
          <QuizCard
            journeyId={journeyId}
            journeyTitle={journeyTitle}
            quiz={quiz}
          />
        </Styled.CardSection>
      </Styled.Page>
    </ErrorBoundary>
  );
}

export default EvaluationPage;

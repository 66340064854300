import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// styles
import * as Styled from 'components/pageECoach/_styles/AboutECoach.style';
import * as Text from 'components/_styles/Text.style';

function PrivacyCard() {
  const { t } = useTranslation('pageEcoach');
  return (
    <>
      <Styled.Content>
        <Styled.AboutSection>
          <Text.P2Semibold>
            {t('aboutECoach.privacy.subheader')}
          </Text.P2Semibold>
          <Styled.AboutDescription>
            <Text.P2>{t('aboutECoach.privacy.disclaimer1')}</Text.P2>
            <Text.P2>{t('aboutECoach.privacy.disclaimer2')}</Text.P2>
            <Text.P2>{t('aboutECoach.privacy.disclaimer3')}</Text.P2>
            <Text.P2>{t('aboutECoach.privacy.disclaimer4')}</Text.P2>
          </Styled.AboutDescription>
        </Styled.AboutSection>
      </Styled.Content>
    </>
  );
}

PrivacyCard.propTypes = {
  toggleExplainer: PropTypes.func,
};

export default PrivacyCard;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// constants
import routesConfig from 'constants/routesConfig.json';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Cards from 'components/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';

function JourneyCardAdmin({ journey }) {
  const { t } = useTranslation();
  const lang = useUserLanguage();

  return (
    <Cards.BasicCard
      to={{
        pathname: `${routesConfig.JOURNEY_ID_PAGE}/${journey.id}`,
      }}
    >
      <div>
        <Text.CaptionCap>{t('pageJourneyList:journey')}</Text.CaptionCap>
        <Text.H2>{journey[`title_${lang}`]}</Text.H2>
      </div>
      <div>
        <Text.CaptionCap>{t('pageJourneyList:details')}</Text.CaptionCap>
        <Text.P2Semibold>{journey[`description_${lang}`]}</Text.P2Semibold>
      </div>
    </Cards.BasicCard>
  );
}

export default JourneyCardAdmin;

JourneyCardAdmin.propTypes = {
  journey: PropTypes.object,
};

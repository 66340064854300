import React from 'react';
import PropTypes from 'prop-types';

// components
import ActiveCardOwl from 'components/pageJourneyList/owlView/ActiveCardOwl';
import InactiveCard from 'components/pageJourneyList/InactiveCard';

// styles
import * as Styled from 'components/pageJourneyList/_styles/PageView.style';

export default function OwlJourneyList({ journeyList }) {
  const getCard = (journey) =>
    journey.microlearnings[0]?.schedules?.length > 0 ? (
      <ActiveCardOwl journey={journey} />
    ) : (
      <InactiveCard journey={journey} />
    );

  return (
    <Styled.Page>
      {journeyList.map((journey) => (
        <div key={journey.id}>{getCard(journey)}</div>
      ))}
    </Styled.Page>
  );
}

OwlJourneyList.propTypes = {
  journeyList: PropTypes.array,
};

import React from 'react';
import { Link } from 'react-router-dom';

// components
import ContentLayout from 'components/_pageLayout/ContentLayout';

// styles
import * as Styled from 'components/_pageLayout/_styles/ContentLayout.style';
import * as Text from 'components/_styles/Text.style';

export default function NotFound() {
  return (
    <ContentLayout>
      <Styled.Content>
        <Text.H1>404 Page not found</Text.H1>
        <Text.P1 style={{ maxWidth: '30em' }}>
          <Link to="/">Go back to the homepage</Link>
        </Text.P1>
      </Styled.Content>
    </ContentLayout>
  );
}

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';
import PropTypes from 'prop-types';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';
import DocumentList from 'components/pageECoach/documentDisplay/DocumentList';
import ClickableListCard from 'components/pageECoach/ClickableListCard';
import ECoachResponse from 'components/pageECoach/cardTemplates/ECoachResponse';
import Loader from 'components/pageECoach/Loader';

// constants
import colours from 'constants/colours';
import routesConfig from 'constants/routesConfig.json';

// context
import { useSessionContext } from 'contexts/ECoachSessionContext';

// hooks
import {
  useEcoachItemsConsumer,
  LoadingStatus,
} from 'hooks/useEcoachItemsConsumer';

// styles
import * as Styled from 'components/pageECoach/_styles/Report.style';
import * as Text from 'components/_styles/Text.style';
import { SessionProgressChoices } from 'components/pageECoach/utils';

// styles

function ECoachConversationListItem({ item: conversationStep }) {
  const { t } = useTranslation('pageEcoach');

  return (
    <Styled.Content>
      <Text.SubheadingSemibold>
        {conversationStep.title}
      </Text.SubheadingSemibold>
      <Text.P2>
        {conversationStep.short_description}
        <b style={{ color: colours.blue[500] }}>{t('See more.')}</b>
      </Text.P2>
    </Styled.Content>
  );
}

ECoachConversationListItem.propTypes = {
  item: PropTypes.object.isRequired,
};

function ECoachConversationDetails({ item }) {
  const { t } = useTranslation('pageEcoach');
  const posthog = usePostHog();

  useEffect(() => {
    posthog?.capture('convo_item_loaded');
  }, [posthog]);

  if (!item) {
    return <h1>No conversationStep set ?</h1>;
  }

  return (
    <>
      <Text.P2>{item?.description}</Text.P2>
      <Styled.Content>
        <Text.P2Semibold>{t('How to prepare')}</Text.P2Semibold>
        <Styled.List>
          {item?.prep?.map((pro) => (
            <li key={pro}>{pro}</li>
          ))}
        </Styled.List>
      </Styled.Content>
      <Styled.Content>
        <Text.P2Semibold>
          {t('Anticipating and handling reactions')}
        </Text.P2Semibold>
        <Styled.List>
          {item?.reactions?.map((con) => (
            <li key={con}>{con}</li>
          ))}
        </Styled.List>
      </Styled.Content>
      <Styled.Content>
        <Text.P2Semibold>{t('Example dialogue')}</Text.P2Semibold>
        <Styled.List>
          {item?.example_language?.map((con) => (
            <li key={con}>{con}</li>
          ))}
        </Styled.List>
      </Styled.Content>
    </>
  );
}

ECoachConversationDetails.propTypes = {
  item: PropTypes.object.isRequired,
};

export default function ConvoPrepCard({ chatGuideData }) {
  const { session, updateSession } = useSessionContext();
  const { t } = useTranslation('pageEcoach');

  const { context, items, selectedItem, selectItem } = useEcoachItemsConsumer(
    session.id,
    routesConfig.ECOACH.CONVERSATION_ITEMS_WS
  );

  useEffect(() => {
    // Update session progress upon items reception
    if (items.status === LoadingStatus.LOADED) {
      updateSession(session?.id, {
        progress: SessionProgressChoices.BACK_AND_FORTH,
      });
    }
  }, [items.status, session?.id, updateSession]);

  return (
    <>
      {session?.context?.convo_prep?.prompt &&
        !chatGuideData.displayConvoPrep && (
          <ChatBubble
            key={session?.context?.convo_prep?.prompt}
            pointer={true}
            sentByUser
          >
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
            >
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
              >
                <Text.P1>
                  {t(
                    'convoPrep.Who would you like to have a conversation with?'
                  )}
                </Text.P1>
                <Text.P2Semibold>
                  {session?.context?.convo_prep?.with_who}
                </Text.P2Semibold>
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
              >
                <Text.P1>
                  {t(
                    'convoPrep.What is your main goal going into this meeting?'
                  )}
                </Text.P1>
                <Text.P2Semibold>
                  {session?.context?.convo_prep?.goal}
                </Text.P2Semibold>
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
              >
                <Text.P1>
                  {t(
                    'convoPrep.Do you have any specific concerns or worries about your meeting?'
                  )}
                </Text.P1>
                <Text.P2Semibold>
                  {session?.context?.convo_prep?.worried_by}
                </Text.P2Semibold>
              </div>
            </div>
          </ChatBubble>
        )}
      <ECoachResponse status={items.status} loader={<Loader />}>
        <ClickableListCard
          isNumbered
          ItemDetailsComponent={ECoachConversationDetails}
          items={items.data}
          ListItemComponent={ECoachConversationListItem}
          listTitle={t('Conversation Plan')}
          selectedItem={selectedItem}
          selectItem={selectItem}
        />
        {context.data?.documents?.length > 0 && (
          <DocumentList
            documentList={context.data?.documents}
            session={session.id}
          />
        )}
      </ECoachResponse>
    </>
  );
}

ConvoPrepCard.propTypes = {
  chatGuideData: PropTypes.object,
};

export const onRegistrationSuccess = (
  response,
  { setSubmitting, setStatus }
) => {
  setSubmitting(false);
  setStatus({
    success: true,
  });
};

export const onRegistrationError = (
  response,
  { setSubmitting, setStatus, setFieldError }
) => {
  const { status } = response;

  if (status === 400) {
    const clonedResponse = response.clone();

    clonedResponse
      .json()
      .then((data) => {
        if (data.additional_infos) {
          Object.keys(data.additional_infos).forEach((key) => {
            setFieldError(
              `additional_infos.${key}`,
              data.additional_infos[key]
            );
          });

          // eslint-disable-next-line no-param-reassign
          delete data.additional_infos;
        }

        Object.keys(data).forEach((key) => {
          setFieldError(key, data[key]);
        });
      })
      .catch((error) => console.error('Error parsing JSON: ', error));
  } else {
    setStatus({ apiError: 'An error occured' });
  }

  setSubmitting(false);
};

import { useEffect, useRef } from 'react';

export default function useAutoScroll() {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) ref.current.scrollIntoView();
  }, [ref]);

  return ref;
}

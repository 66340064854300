import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// assets
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Card1 from 'components/pageOnboarding/assets/card1.png';
import Card2 from 'components/pageOnboarding/assets/card2.svg';

// styles
import * as Page from 'components/pageOnboarding/_styles/_index.style';
import * as Styled from 'components/pageOnboarding/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';

function GeneralIntroCards({ markComplete }) {
  const { t } = useTranslation('pageOnboarding');

  const [cardNumber, setCardNumber] = useState(1);

  const backPageClick = () => setCardNumber((prev) => prev - 1);
  const nextPageClick = () => {
    if (cardNumber === 2) markComplete(0);
    return setCardNumber((prev) => prev + 1);
  };

  return (
    <Styled.Card>
      <Styled.ImageArea>
        {cardNumber === 1 && <Styled.ImageOverflowed src={Card1} />}
        {cardNumber === 2 && <Styled.ImageSpread src={Card2} />}
      </Styled.ImageArea>
      <Styled.BottomSection>
        <Styled.InnerSection>
          <Text.H5Bold>
            {cardNumber === 1 && t('Welcome to Nurau!')}
            {cardNumber === 2 && t('Navigate with eCoach')}
          </Text.H5Bold>
          <Page.OnboardingP>
            {cardNumber === 1 &&
              t(
                'Embrace the power of eCoach and transform challenges into opportunities. Start your journey towards informed decision-making today.'
              )}
            {cardNumber === 2 &&
              t(
                "Efficiently navigate workplace challenges with eCoach. Tailored to fit your company's policies and best practices, eCoach provides guidance on any issue, ensuring your success is just a consultation away."
              )}
          </Page.OnboardingP>
        </Styled.InnerSection>
      </Styled.BottomSection>
      <Styled.Navigation>
        {cardNumber === 1 ? (
          <div></div>
        ) : (
          <Styled.BackButton aria-label={t('back')} onClick={backPageClick}>
            <NavigateBeforeIcon />
          </Styled.BackButton>
        )}
        <Styled.NavButton aria-label={t('next')} onClick={nextPageClick}>
          <NavigateNextIcon />
        </Styled.NavButton>
      </Styled.Navigation>
    </Styled.Card>
  );
}

GeneralIntroCards.propTypes = {
  markComplete: PropTypes.func,
};

export default GeneralIntroCards;

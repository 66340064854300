import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import { Users } from 'untitledui-js';
import { ReactComponent as RobotIcon } from 'assets/robot.svg';
import { ReactComponent as UnicornIcon } from 'assets/unicorn.svg';

// components
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import Topbar from 'components/navbar/Topbar';

// constants
import routesConfig from 'constants/routesConfig.json';
import userRoles from 'constants/userRoles.json';

// hooks
import useWindowSize from 'hooks/useWindowResize';

export default function Navbar({ activeTab, getUser }) {
  const { t } = useTranslation();
  const [width] = useWindowSize();

  const [isOpen, setIsOpen] = useState(width > 1024);
  const toggleSidebar = () => setIsOpen((prev) => !prev);
  if (!isOpen && width > 1024) setIsOpen(true);

  const routes = [
    {
      label: t('navbar:eCoach'),
      link: routesConfig.E_COACH_PAGE,
      logo: () => <RobotIcon />,
      tab: 'eCoach',
      users: [userRoles.MEMBER],
    },
    {
      label: t('navbar:members'),
      link: routesConfig.MEMBERS_PAGE,
      logo: () => <Users.Users01 />,
      tab: 'members',
      users: [userRoles.ADMIN],
    },
    {
      label: t('navbar:journeys'),
      link: routesConfig.JOURNEY_LIST_PAGE,
      logo: () => <UnicornIcon />,
      tab: 'journeys',
      users: [userRoles.ADMIN, userRoles.MEMBER, userRoles.OWL],
    },
  ];

  return (
    <ErrorBoundary>
      <Topbar
        activeTab={activeTab}
        getUser={getUser}
        routes={routes}
        sidebarOpen={isOpen}
        toggleSidebar={toggleSidebar}
      />
    </ErrorBoundary>
  );
}

Navbar.propTypes = {
  activeTab: PropTypes.string,
  getUser: PropTypes.func,
};

import styled from 'styled-components';
import * as Buttons from 'components/pageECoach/_styles/MessageInputs.style';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';

export const MultiCardSection = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
`;

export const Card = styled('div')`
  background-color: ${colours.shade[0]};
  border-radius: 20px;
  box-shadow: ${shadows.M};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: fit-content;
  max-width: 100%;
  padding: 30px;
  position: relative;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    padding: 20px;
  }
`;

export const Button = styled(Buttons.PromptButton)`
  width: 100%;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

// components
import Navbar from 'components/navbar/_index';

// constants
import routesConfig from 'constants/routesConfig.json';

function NavbarRoutes({ getUser }) {
  return (
    <Switch>
      <Route exact path={routesConfig.JOURNEY_LIST_PAGE}>
        <Navbar activeTab="journeys" getUser={getUser} />
      </Route>
      <Route path={routesConfig.JOURNEY_ID_PAGE}>
        <Navbar activeTab="journeys" getUser={getUser} />
      </Route>
      <Route path={routesConfig.SLIDESHOW_PAGE}>
        <Navbar activeTab="journeys" getUser={getUser} />
      </Route>
      <Route exact path={routesConfig.OWL_PROFILE_PAGE}>
        <Navbar activeTab="owlProfile" getUser={getUser} />
      </Route>
      <Route exact path={routesConfig.MEMBERS_PAGE}>
        <Navbar activeTab="members" getUser={getUser} />
      </Route>
      <Route exact path={routesConfig.SCHEDULE_PAGE}>
        <Navbar activeTab="schedule" getUser={getUser} />
      </Route>
      <Route exact path={routesConfig.E_COACH_PAGE}>
        <Navbar activeTab="eCoach" getUser={getUser} />
      </Route>
      <Route path={routesConfig.E_COACH_SESSION_PAGE}>
        <Navbar activeTab="eCoach" getUser={getUser} />
      </Route>
      <Route exact path={routesConfig.BOOKINGS_PAGE}>
        <Navbar activeTab="bookings" getUser={getUser} />
      </Route>
      <Route path="*">
        <Navbar getUser={getUser} />
      </Route>
    </Switch>
  );
}

NavbarRoutes.propTypes = {
  getUser: PropTypes.func,
};

export default NavbarRoutes;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

// styles
import * as Forms from 'components/_styles/Forms.style';

function MembersListSearch({ membersList, setMembersListFiltered }) {
  const { t } = useTranslation();

  const [searchFor, setSearchFor] = useState('');
  const handleSearch = (search) => setSearchFor(search);

  useEffect(() => {
    const membersListFiltered = membersList.filter((member) => {
      const fullName =
        `${member.user.first_name} ${member.user.last_name}`.toLowerCase();
      return fullName.includes(searchFor);
    });
    setMembersListFiltered(membersListFiltered);
  }, [searchFor, membersList, setMembersListFiltered]);

  return (
    <Forms.SearchField
      id="memberSearch"
      InputLabelProps={{
        sx: { backgroundColor: 'white', paddingRight: '10px' },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      label={`${t('pageOrganization:search')} ${membersList?.length} ${t(
        'pageOrganization:members'
      )}`}
      onChange={(e) => handleSearch(e.target.value)}
      placeholder={`${t('pageOrganization:search')} ${membersList?.length} ${t(
        'pageOrganization:members'
      )}`}
      sx={{ width: '40%', minWidth: '200px', borderRadius: '50px' }}
      value={searchFor}
      variant="outlined"
    />
  );
}

MembersListSearch.propTypes = {
  membersList: PropTypes.array,
  setMembersListFiltered: PropTypes.func,
};

export default MembersListSearch;

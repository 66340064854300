import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// constants
import routesConfig from 'constants/routesConfig.json';

// styles
import * as Buttons from 'components/_styles/Buttons.style';

function MicrolearningCardButtons({ cover, journey, microlearning }) {
  const { t } = useTranslation();
  const history = useHistory();

  const redirectToSlideshow = useCallback(() => {
    history.push({
      pathname: routesConfig.SLIDESHOW_PAGE,
      state: {
        cover,
        journeyId: journey.id,
        journeyTitleEN: journey.title_en,
        journeyTitleFR: journey.title_fr,
        microlearningId: microlearning.id,
        microlearningTitleEN: microlearning.title_en,
        microlearningTitleFR: microlearning.title_fr,
      },
    });
  }, [cover, history, journey, microlearning]);

  return (
    <Buttons.Secondary onClick={redirectToSlideshow}>
      {t('pageJourneyId:learnMore')}
    </Buttons.Secondary>
  );
}

MicrolearningCardButtons.propTypes = {
  cover: PropTypes.string,
  journey: PropTypes.object,
  microlearning: PropTypes.object,
};

export default MicrolearningCardButtons;

import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, MenuItem } from '@mui/material';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import useIndustries from 'hooks/demo/useIndustries';

export default function IndustryField({ currentLanguage, t }) {
  const industries = useIndustries(currentLanguage);

  const industriesOptions = React.useMemo(() => {
    if (!industries || Object.keys(industries).length === 0) {
      return [
        <MenuItem key="loading" value="loading">
          {t('Loading...')}
        </MenuItem>,
      ];
    }

    return Object.entries(industries).map(([orgUUID, industry]) => (
      <MenuItem key={orgUUID} value={orgUUID}>
        {industry}
      </MenuItem>
    ));
  }, [industries, t]);

  return (
    <FormControl fullWidth>
      <Field component={Select} name="industry" label={t('Industry')}>
        {industriesOptions}
      </Field>
    </FormControl>
  );
}

IndustryField.propTypes = {
  t: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import OwlLink from 'components/buttonOwl/_index';

// styles
import * as Styled from 'components/pageJourneyId/_styles/OrgAdminJourney.style';
import * as Text from 'components/_styles/Text.style';

function Tabs({ description, owls, setMicrolearningListFilter }) {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(0);

  const descriptionActive = `${activeTab === 0 ? 'true' : 'false'}`;
  const owlsActive = `${activeTab === 1 ? 'true' : 'false'}`;

  const toggleTab = (tab) => {
    setActiveTab(tab);
    if (!description) setMicrolearningListFilter(tab);
  };

  const getTab = (tab) => {
    if (tab === 'description')
      return (
        <Styled.Tab active={descriptionActive} onClick={() => toggleTab(0)}>
          <Styled.TabText>{t('pageJourneyId:description')}</Styled.TabText>
        </Styled.Tab>
      );
    return (
      <Styled.Tab active={owlsActive} onClick={() => toggleTab(1)}>
        <Styled.TabText>{t('pageJourneyId:owls')}</Styled.TabText>
      </Styled.Tab>
    );
  };

  const getTabContent = () => {
    if (activeTab === 0)
      return (
        <Styled.TabContent>
          <Text.P1>{description}</Text.P1>
        </Styled.TabContent>
      );
    return (
      <Styled.TabContent>
        <ul>
          {owls?.map((owl) => (
            <li key={owl.user.first_name.toString()}>
              <OwlLink owlInfo={owl}>
                {owl.user.first_name} {owl.user.last_name}
              </OwlLink>
            </li>
          ))}
        </ul>
      </Styled.TabContent>
    );
  };

  return (
    <Styled.InfoTabArea>
      <Styled.Tabs>
        {getTab('description')}
        {getTab('owls')}
      </Styled.Tabs>
      {getTabContent()}
    </Styled.InfoTabArea>
  );
}

export default Tabs;

Tabs.propTypes = {
  description: PropTypes.string,
  owls: PropTypes.array,
  setMicrolearningListFilter: PropTypes.func,
};

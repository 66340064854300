import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { General } from 'untitledui-js';
import GearsImg from 'assets/gears.png';
import SyncImg from 'assets/sync.png';
import SecurityImg from 'assets/security.png';

// components
import ApiLoading from 'components/_pageLayout/ApiLoading';
import SubscriptionConfirmButton from 'components/buttonCalendarSubscription/SubscriptionConfirmButton';

// context
import { useUser } from 'contexts/UserContext';

// hooks
import useApi from 'hooks/useApi';

// styles
import * as Styled from 'components/buttonCalendarSubscription/_styles/SubscriptionInfoCard.style';
import * as Text from 'components/_styles/Text.style';

function SubscriptionCard({ handleModalClose }) {
  const { userRole } = useUser();
  const { data, callApi: getIcsFile } = useApi();

  const { t } = useTranslation();

  useEffect(() => {
    getIcsFile('/calendar/generate-token/');
  }, [getIcsFile]);

  if (data.status === 'loading') return <ApiLoading />;
  const icsFile = `${process.env.REACT_APP_API_URL}/calendar/${userRole}/${data.response.token}/nurau-schedule.ics`;

  return (
    <Styled.Card>
      <Styled.CloseSection>
        <Styled.CloseButton onClick={handleModalClose}>
          <General.XClose />
        </Styled.CloseButton>
      </Styled.CloseSection>
      <Styled.CardContent>
        <Styled.Header>{t('buttonCalendarSubscription:header')}</Styled.Header>
        <Styled.ListSection>
          <Styled.ListRow>
            <Styled.ListIcon src={SyncImg} />
            <Styled.ListDescriptionSection>
              <Text.P3Semibold>
                {t('buttonCalendarSubscription:syncTitle')}
              </Text.P3Semibold>
              <Styled.DescriptionText>
                {t('buttonCalendarSubscription:sync')}
              </Styled.DescriptionText>
            </Styled.ListDescriptionSection>
          </Styled.ListRow>
          <Styled.ListRow>
            <Styled.ListIcon src={SecurityImg} />
            <Styled.ListDescriptionSection>
              <Text.P3Semibold>
                {t('buttonCalendarSubscription:privacyTitle')}
              </Text.P3Semibold>
              <Styled.DescriptionText>
                {t('buttonCalendarSubscription:privacy')}
              </Styled.DescriptionText>
            </Styled.ListDescriptionSection>
          </Styled.ListRow>
          <Styled.ListRow>
            <Styled.ListIcon src={GearsImg} />
            <Styled.ListDescriptionSection>
              <Text.P3Semibold>
                {t('buttonCalendarSubscription:staticTitle')}
              </Text.P3Semibold>
              <Styled.DescriptionText>
                <span>
                  <Styled.Download href={icsFile} download>
                    {t('buttonCalendarSubscription:clickHere')}
                  </Styled.Download>
                  {t('buttonCalendarSubscription:static')}
                </span>
              </Styled.DescriptionText>
            </Styled.ListDescriptionSection>
          </Styled.ListRow>
        </Styled.ListSection>
        <SubscriptionConfirmButton icsFile={icsFile} />
      </Styled.CardContent>
    </Styled.Card>
  );
}

SubscriptionCard.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
};

export default SubscriptionCard;

import { useEffect, useState } from 'react';
import {
  isChrome,
  isFirefox,
  isSafari,
  isEdge,
  isIE,
} from 'react-device-detect';

const useBrowserDetection = () => {
  const [browser, setBrowser] = useState('unknown');

  useEffect(() => {
    if (isChrome) setBrowser('chrome');
    else if (isFirefox) setBrowser('firefox');
    else if (isSafari) setBrowser('safari');
    else if (isEdge) setBrowser('edge');
    else if (isIE) setBrowser('ie');
    // Add more browser checks as needed
  }, []);

  return browser;
};

export default useBrowserDetection;

import styled from 'styled-components';
import device from 'constants/devices';
import * as Cards from 'components/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';

export const Container = styled(Cards.BasicCard)`
  flex-direction: column;
  max-height: unset;
  padding: 0 30px 30px 30px;
  width: 600px;

  @media (max-width: ${device.laptop}) {
    flex-direction: column;
    width: 425px;
  }

  @media (max-width: ${device.mobileL}) {
    align-items: center;
    padding: 15px;
    width: 250px;
  }
`;

export const OwlName = styled(Text.H1)`
  margin-bottom: 30px;
`;

export const Image = styled('img')`
  border-radius: 20px;
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const ImageContainer = styled('div')`
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  flex-shrink: 0;
  height: 200px;
  margin-right: 30px;
  width: 200px;

  @media (max-width: ${device.mobileL}) {
    margin-right: 0;
  }
`;

import { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import useApi from 'hooks/useApi';

const useRegister = (onSuccess, onError) => {
  const { data, callApi } = useApi();
  const [formContext, setFormContext] = useState({});
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (data.status === 'success' && data.response) {
      onSuccess(data.response, formContext);
    }
  }, [onSuccess, data, formContext]);

  useEffect(() => {
    if (data.status === 'error') {
      if (onError) onError(data.response, formContext);
    }
  }, [onError, data, formContext]);

  const register = (values, context) => {
    setFormContext(context);

    if (process.env.REACT_APP_DEBUG === 'true') {
      callApi('/demo/register', 'post', values);
    }

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    executeRecaptcha('signup').then((token) => {
      callApi('/demo/register', 'post', {
        ...values,
        recaptcha_v3_token: token,
      });
    });
  };

  return { register };
};

export default useRegister;

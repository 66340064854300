import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// components
import DetailsCard from 'components/pageCalendar/DetailsCard';

// helpers & hooks
import checkIfLive from 'helpers/checkIfLive';
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Text from 'components/_styles/Text.style';

function DetailsCardOwl({ details }) {
  const { t } = useTranslation();
  const lang = useUserLanguage();
  const [isLive, setIsLive] = useState(false);
  const startDate = details.starts_at.toString();

  const bookingCount = () => (
    <Text.CaptionCap style={{ fontWeight: '500', marginBottom: '5px' }}>
      {t('pageCalendar:membersBooked', { count: details.booking_count })}
    </Text.CaptionCap>
  );

  useEffect(() => {
    if (checkIfLive(startDate) && details) setIsLive(true);
  }, [details, startDate]);

  return (
    <DetailsCard
      bookingCount={bookingCount}
      bookingId={details.id}
      caption={t('pageCalendar:microlearning')}
      dateString={dayjs(startDate).locale(lang).format('LLL')}
      isLive={isLive}
      language={details.language}
      microlearningId={details.microlearning.id}
      microlearningTitle={details.microlearning[`title_${lang}`]}
      title={details.microlearning[`title_${lang}`]}
    />
  );
}

export default DetailsCardOwl;

DetailsCardOwl.propTypes = {
  details: PropTypes.object,
};

const SupportingOthers = {
  evaluationId: 'SupportingOthers',
  questions: [
    {
      questionNumber: 1,
      question_en:
        'Lucy keeps calling in sick and you suspect she may be either taking advantage of your trust or it could be related to her mental health. You also notice her work quality has been declining.',
      question_fr:
        "Lucie s'absente fréquemment en raison de maladie et vous suspectez un abus de confiance ou des enjeux de santé mentale. Vous constatez également une diminution de la qualité de son travail.",
      solution: 3,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Start asking Lucy to supply doctor’s notes each time she calls in sick.',
          option_fr:
            'Demandez à Lucie de fournir des justificatifs médicaux lors de ses absences.',
          feedback_en:
            'This could put Lucy on the defensive and think that you do not trust her.',
          feedback_fr:
            "Cette démarche pourrait rendre Lucie sur la défensive et lui donner l'impression que vous manquez de confiance en elle.",
        },
        {
          optionNumber: 2,
          option_en:
            'Email her and express your concern about her taking more sick days than normal.',
          option_fr:
            "Adressez-lui un courriel en exprimant votre préoccupation concernant l'augmentation, par rapport à la normale, de ses jours d'absence pour cause de maladie.",
          feedback_en:
            'Emails can be easily misinterpreted so is best to avoid when talking about personal issues.',
          feedback_fr:
            "Les courriels sont susceptibles d'être mal interprétés ; il est donc préconisé de les éviter lorsqu'il s'agit d'aborder des sujets personnels.",
        },
        {
          optionNumber: 3,
          option_en:
            'Arrange an in-person meeting with Lucy and enquire about how things are going at work and at home.',
          option_fr:
            'Planifiez une rencontre en personne avec Lucie et interrogez-la sur sa situation globale et comment les choses se passent au travail et à la maison.',
          feedback_en:
            'Yes, it’s best to talk face to face and giving time during a meeting shows concern for Lucy. If her sick leave is due to mental health, you can then direct her to appropriate supports.',
          feedback_fr:
            "Effectivement, privilégier un entretien en face à face est préférable et accorder du temps lors d'une réunion témoigne de votre préoccupation pour Lucie. Si ses absences sont liées à des enjeux de santé mentale, vous pourrez alors la guider vers les ressources d'aide adaptées et disponibles.",
        },
      ],
    },
    {
      questionNumber: 2,
      question_en:
        'At the meeting, Lucy tells you everything is going fine. However, you sense that she seems avoidant.',
      question_fr:
        "Au cours de l'entretien, Lucie affirme que tout va bien. Toutefois, vous avez l'impression qu'elle semble évasive.",
      solution: 3,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Talk about a time when you were struggling to cope with work and how reaching out was really helpful for you.',
          option_fr:
            "Évoquez une période durant laquelle vous avez eu des difficultés à gérer votre travail et où le fait de solliciter de l'aide vous a été d'un grand secours.",
          feedback_en:
            'Although sharing personal stories can sometimes be helpful, you should try to center the conversation around Lucy first to find out what is the real problem.',
          feedback_fr:
            "Même si le partage d'expériences personnelles peut parfois s'avérer utile, il est préférable de centrer d'abord la conversation sur Lucie afin de déterminer son enjeu principal.",
        },
        {
          optionNumber: 2,
          option_en:
            'Say that you are concerned that she has been taking more sick days than normal and you wonder if her health is okay.',
          option_fr:
            "Faites-lui part de votre inquiétude concernant l'augmentation de ses congés maladie par rapport à la normale et demandez-lui comment elle va réellement.",
          feedback_en:
            'This is a good way to broach your concerns, although as a manager, it might be more relevant to start from performance and work quality issues rather than health, which is more personal.',
          feedback_fr:
            "C'est une approche judicieuse pour exprimer vos préoccupations. Cependant, en tant que gestionnaire, il serait peut-être plus approprié d'aborder les questions de performance et de qualité du travail en premier, plutôt que la santé, qui est un sujet plus personnel.",
        },
        {
          optionNumber: 3,
          option_en:
            'Say that you have noticed that her work quality has been declining and inquire about the cause of her declining performance.',
          option_fr:
            'Dites-lui que vous avez observé une baisse de la qualité de son travail et demandez-lui son avis quant à la cause de cette diminution de performance.',
          feedback_en:
            'Yes, as a manager, you have the right to be concerned about her declining work quality and this is a good way to inquire about what is going on under the surface.',
          feedback_fr:
            "Effectivement, en tant que gestionnaire, vous êtes en droit de vous préoccuper de la diminution de la qualité de son travail, et cette approche constitue un bon moyen d'explorer ce qui se passe en profondeur.",
        },
      ],
    },
    {
      questionNumber: 3,
      question_en:
        'Lucy admits that there have been some recent personal changes in her life that have caused her to lose energy and motivation for work. She’s also been feeling more anxious about these recent changes.',
      question_fr:
        "Lucie reconnaît que des bouleversements personnels survenus récemment dans sa vie l'ont conduite à une baisse d'énergie et de motivation au travail. De plus, elle se sent davantage anxieuse en raison de ces changements récents.",
      solution: 2,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Ask her what those personal changes are and whether you can help.',
          option_fr:
            'Demandez-lui la nature de ces changements personnels et si vous pouvez lui apporter votre aide.',
          feedback_en:
            'This shows concern but could also overstep boundaries, especially if Lucy is not willing to share about those personal changes.',
          feedback_fr:
            "Cela témoigne d'une certaine préoccupation, mais pourrait également dépasser les limites, surtout si Lucie ne veut pas discuter de ces changements personnels.",
        },
        {
          optionNumber: 2,
          option_en:
            'Ask her if she has talked to anyone who can help her with the anxiety and what sort of support she would need from work to navigate this change.',
          option_fr:
            "Interrogez-la pour savoir si elle a échangé avec une personne susceptible de l'aider à surmonter son anxiété et quel genre de soutien elle aurait besoin au travail pour faire face à cette situation.",
          feedback_en:
            'Yes, redirecting her to personal and professional supports, such as EAPs offered at your work, is the best way to support Lucy, while maintaining good boundaries.',
          feedback_fr:
            "Effectivement, l'orienter vers des spécialistes capables de lui fournir un soutien personnel et professionnel, tels que les programmes d'aide aux employés (PAE) proposés sur votre lieu de travail, constitue la meilleure manière d'accompagner Lucie, tout en respectant les limites appropriées.",
        },
        {
          optionNumber: 3,
          option_en:
            'Introduce her to this new meditation app you’ve been using that’s really helped your anxiety.',
          option_fr:
            'Faites-lui découvrir cette nouvelle application de méditation que vous utilisez et qui a véritablement contribué à atténuer votre anxiété.',
          feedback_en:
            'An app might not necessarily be what everyone needs. Instead of suggesting what worked for you, try getting Lucy to take ownership of finding support.',
          feedback_fr:
            'Une application ne convient pas nécessairement à tout le monde. Plutôt que de suggérer ce qui a fonctionné pour vous, encouragez Lucie à rechercher une aide professionnelle adaptée à ses besoins.',
        },
      ],
    },
    {
      questionNumber: 4,
      question_en:
        'You know that Daniel struggles with OCD and anxiety, as he has told you this before. You have received bad reports from customers and even his own team members about how he does not listen and talks excessively over people.',
      question_fr:
        "Vous êtes au courant que Daniel souffre de TOC et d'anxiété, car il vous en a déjà fait part. Vous avez reçu des commentaires négatifs de la part de clients et même de membres de son équipe, indiquant qu'il n'écoute pas et se montre souvent agressif lors des interactions avec les gens.",
      solution: 1,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Meet with Daniel and revisit the specifics on his job expectations. Ask him how he is coping with his job role and if he needs any support.',
          option_fr:
            "Rencontrez Daniel et abordez les détails de ses attentes professionnelles. Demandez-lui comment il s'adapte à son travail et s'il a besoin d'aide.",
          feedback_en:
            'Yes, this is the best way to get Daniel’s opinion on how he feels and establish common ground regarding job expectations before giving feedback.',
          feedback_fr:
            "Effectivement, c'est la meilleure approche pour recueillir le point de vue de Daniel sur ses ressentis et établir un consensus concernant les attentes professionnelles avant de lui apporter un retour d'information.",
        },
        {
          optionNumber: 2,
          option_en:
            'Meet with Daniel to go over the bad reports you’ve been receiving from customers. Tell him specific points of improvement that will factor into his next performance review.',
          option_fr:
            "Rencontrez Daniel afin d'évoquer les commentaires négatifs que vous avez reçus de la part des clients. Exprimez-lui vos attentes précises qui seront prises en considération lors de sa prochaine évaluation.",
          feedback_en:
            'Although this is a very direct and honest approach, it could come across as harsh and critical if Daniel does not feel he has a chance to share his opinion.',
          feedback_fr:
            'Même si cette approche est très directe et honnête, elle pourrait être perçue comme rude et critique si Daniel ne se sent pas en mesure de partager son point de vue.',
        },
        {
          optionNumber: 3,
          option_en:
            'Pull Daniel out of any customer-facing roles and put him on a role you think would be more suitable for his OCD and anxiety.',
          option_fr:
            'Retirez Daniel de toute fonction impliquant un contact avec la clientèle et attribuez-lui un rôle que vous estimez plus adapté à ses TOC et son anxiété.',
          feedback_en:
            'This may temporarily solve the problem, however, Daniel may lose trust in you if he does not understand the reason for the transition. It is better to have a honest conversation.',
          feedback_fr:
            "Cette solution peut temporairement résoudre le problème, mais Daniel risque de perdre confiance en vous s'il ne saisit pas la raison de ce changement. Il est préférable d'entamer une discussion sincère.",
        },
      ],
    },
    {
      questionNumber: 5,
      question_en:
        'Daniel agrees with you on the job expectations and believes he has been meeting them in his role. He does not understand why you called this meeting and asks you the reason.',
      question_fr:
        'Daniel est en accord avec vous concernant les attentes professionnelles et estime les avoir satisfaites dans son rôle. Il ne saisit pas la raison pour laquelle cette rencontre a été organisée et vous interroge à ce sujet.',
      solution: 3,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Since Daniel and you are aligned on job expectations, you think his talking over people and lack of listening must be due to his OCD and anxiety. This is a personal mental health problem and you prefer not to intrude.',
          option_fr:
            "Comme Daniel et vous partagez les mêmes attentes professionnelles, vous considérez que le fait qu'il s'exprime parfois à haute voix et n'écoute pas est lié à ses TOC et à son anxiété. Il s'agit d'un enjeu personnel lié à la santé mentale et vous préférez ne pas intervenir.",
          feedback_en:
            'Ignoring the problem is not sustainable. If Daniel is not made aware of how his behaviour is affecting others, it will only be harder to have this conversation down the road.',
          feedback_fr:
            "Négliger le problème n'est pas une solution viable. Si Daniel n'a pas conscience de l'impact de son comportement sur les autres, il sera d'autant plus difficile d'aborder cette discussion avec lui ultérieurement.",
        },
        {
          optionNumber: 2,
          option_en:
            'Ask Daniel directly how he is dealing with his OCD and anxiety. Direct him to EAPs that can help him address these challenges.',
          option_fr:
            "Posez-lui des questions précises concernant la gestion de ses TOC et de son anxiété, et dirigez-le vers des programmes d'aide aux employés (PAE) susceptibles de l'aider à surmonter ces difficultés.",
          feedback_en:
            'Although Daniel’s mental health challenges are affecting his performance at work, the conversation should center around job expectations as that is your primary role as manager.',
          feedback_fr:
            "Bien que les enjeux de santé mentale de Daniel aient un impact sur ses performances professionnelles, la discussion doit se concentrer sur les attentes liées au travail, car c'est là votre première responsabilité en tant que gestionnaire.",
        },
        {
          optionNumber: 3,
          option_en:
            'Be honest with Daniel that even though you both agree on the expectations, he has not been meeting them as evidenced by the bad customer reports. Ask him to share his perspective on what he thinks happened in that situation.',
          option_fr:
            "Soyez sincère avec Daniel : bien que vous soyez tous deux en accord sur les attentes, il ne les a pas remplies, comme en témoignent les commentaires négatifs des clients. Demandez-lui de partager son point de vue concernant ce qu'il pense s'être produit dans cette situation.",
          feedback_en:
            'Yes, now that you have both agreed on the expectations, you have a basis to express concern for him not meeting them. Inviting his perspective on the reports also makes Daniel feel heard.',
          feedback_fr:
            "Oui, maintenant que vous êtes les deux en accord sur les attentes, vous pouvez exprimer vos préoccupations s'il ne les respecte pas. En l'invitant à partager son point de vue concernant les commentaires, vous donnez à Daniel le sentiment d'être écouté.",
        },
      ],
    },
    {
      questionNumber: 6,
      question_en:
        'Daniel feels ashamed upon hearing this news and tells you he’s worried his OCD and anxiety are getting out of hand, which is causing him to interact poorly with customers.',
      question_fr:
        "Daniel éprouve de la honte en apprenant cette nouvelle et vous confie qu'il craint que ses TOC et son anxiété deviennent ingérables, ce qui pourrait entraîner de mauvaises relations avec les clients.",
      solution: 2,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Tell him to take some time off work so he can get help for his mental health.',
          option_fr:
            "Suggérez-lui de prendre un congé afin qu'il puisse recevoir de l'aide pour sa santé mentale.",
          feedback_en: 'Time off might not be what Daniel needs right now.',
          feedback_fr:
            'Du temps libre n’est peut-être pas ce dont Daniel a besoin en ce moment.',
        },
        {
          optionNumber: 2,
          option_en:
            'Reassure him of his value to the company but tell him that in order to stay in this role, he will need to get help to address his OCD and anxiety. Ask him if he has seen someone who can help him.',
          option_fr:
            "Rassurez-le quant à son importance pour l'entreprise, mais informez-le que pour conserver ce poste, il devra chercher des moyens de gérer et/ou traiter ses TOC et son anxiété. Demandez-lui s'il a fait appel à un tiers pour obtenir du soutien en lien avec ces enjeux.",
          feedback_en:
            'Yes, this will build trust with Daniel after giving some negative feedback. You are also being honest with what needs to change while providing support.',
          feedback_fr:
            "En effet, cela contribuera à instaurer un climat de confiance avec Daniel après avoir partagé des informations négatives. Vous faites preuve d'honnêteté quant aux changements nécessaires tout en offrant votre soutien.",
        },
        {
          optionNumber: 3,
          option_en:
            'Give him the number of the welfare officer at work and tell Daniel to have a conversation with them.',
          option_fr:
            "Fournissez-lui le numéro de la personne responsable du bien-être social de l'entreprise et encouragez Daniel à entamer une conversation avec cette personne.",
          feedback_en:
            'This might be helpful for Daniel to have but it’s better to have more information on supports already available to Daniel before simply redirecting him to someone else.',
          feedback_fr:
            "Cela pourrait être bénéfique pour Daniel, mais il est préférable de disposer de plus d'informations sur les ressources déjà accessibles à Daniel avant de simplement l'orienter vers une autre personne.",
        },
      ],
    },
  ],
};

export default SupportingOthers;

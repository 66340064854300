import styled from 'styled-components';

export const Container = styled('div')`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
`;

export default Container;

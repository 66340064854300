import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import ApiLoading from 'components/_pageLayout/ApiLoading';
import ContentLayout from 'components/_pageLayout/ContentLayout';
import OwlProfile from 'components/pageProfile/OwlProfile';

// context
import { useUser } from 'contexts/UserContext';

// constants
import userRoles from 'constants/userRoles.json';

// hooks
import useApi from 'hooks/useApi';

// styles
import * as Text from 'components/_styles/Text.style';

function OwlProfilePage({ testData, testUser, testUserRole }) {
  const { t } = useTranslation();
  const { data: userData, userRole } = useUser();
  const { data: profileData, callApi: getOwlProfile } = useApi();

  const user = testUser || userData;
  const role = testUserRole || userRole;
  const owlProfile = testData || profileData;

  useEffect(() => {
    if (!testData && role === userRoles.OWL && owlProfile.status !== 'success')
      getOwlProfile('/owls/');
  }, [getOwlProfile, owlProfile.status, role, testData]);

  if (role !== userRoles.OWL) return null;
  return (
    <ContentLayout>
      <Text.H1>{t('pageProfile:header')}</Text.H1>
      <div style={{ marginTop: '30px' }}>
        {owlProfile.status === 'success' ? (
          <OwlProfile
            getOwlProfile={getOwlProfile}
            owlProfile={owlProfile}
            user={user}
          />
        ) : (
          <ApiLoading />
        )}
      </div>
    </ContentLayout>
  );
}

export default OwlProfilePage;

OwlProfilePage.propTypes = {
  testData: PropTypes.object,
  testUser: PropTypes.object,
  testUserRole: PropTypes.string,
};

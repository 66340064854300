import styled from 'styled-components';
import * as Cards from 'components/_styles/Cards.style';
import device from 'constants/devices';
import * as Buttons from 'components/_styles/Buttons.style';
import * as Text from 'components/_styles/Text.style';

export const Card = styled(Cards.BasicCard)`
  align-items: center;
  background: linear-gradient(278.76deg, #1a2634 1.35%, #36475a 102.58%);
  gap: 30px;
  justify-content: space-around;
  min-height: 750px;
  padding: 60px;

  @media (max-width: ${device.tablet}) {
    background-color: transparent;
    border-radius: 30px;
    gap: 30px;
    justify-content: center;
    padding: 30px;
  }
`;

export const Image = styled('img')`
  max-width: 350px;

  @media (max-width: ${device.tablet}) {
    width: 100%;
  }
`;

export const Title = styled(Text.H1Bold)`
  color: white;
  text-align: center;
`;

export const Message = styled(Text.P3Semibold)`
  color: white;
  text-align: center;
`;

export const EndButton = styled(Buttons.Primary)`
  width: 250px;

  @media (max-width: ${device.mobileM}) {
    width: 100%;
  }
`;

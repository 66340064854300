import React from 'react';
import PropTypes from 'prop-types';

// components
import MemberCard from 'components/pageOrganization/MemberCard';

function MembersList({ getMembersList, membersList, setAlert }) {
  const sortedMembers = membersList?.sort((a, b) =>
    a.user.first_name.localeCompare(b.user.first_name)
  );

  return (
    <>
      {sortedMembers?.map((member) => (
        <MemberCard
          getMembersList={getMembersList}
          key={member.user.email}
          member={member}
          setAlert={setAlert}
        />
      ))}
    </>
  );
}

MembersList.propTypes = {
  getMembersList: PropTypes.func,
  membersList: PropTypes.array,
  setAlert: PropTypes.func,
};

export default MembersList;

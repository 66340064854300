import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// styles
import * as Styled from 'components/progressIndicators/_styles/SimpleProgress.style';
import * as Text from 'components/_styles/Text.style';

export function Circle({ numMembers }) {
  const { t } = useTranslation();

  return (
    <Styled.Circle>
      <Text.H3>{numMembers}</Text.H3>
      <Text.CaptionCap>
        {t('progressIndicators:membersCompleted', { count: numMembers })}
      </Text.CaptionCap>
    </Styled.Circle>
  );
}

Circle.propTypes = {
  numMembers: PropTypes.number.isRequired,
};

export function Chip({ numMembers }) {
  const { t } = useTranslation();

  return (
    <div>
      <Styled.Chip>
        <Text.H3 style={{ marginRight: '10px' }}>{numMembers}</Text.H3>
        <Text.CaptionCap>
          {t('progressIndicators:membersCompleted', { count: numMembers })}
        </Text.CaptionCap>
      </Styled.Chip>
    </div>
  );
}

Chip.propTypes = {
  numMembers: PropTypes.number.isRequired,
};

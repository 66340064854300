import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';

export const Circle = styled('div')`
  align-items: center;
  border: 5px solid ${colours.primary[100]};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: center;
  margin-right: 50px;
  padding: 20px;
  text-align: center;
  width: 70px;

  @media (max-width: ${device.tablet}) {
    display: none;
  }
`;

export const Chip = styled('div')`
  display: none;

  @media (max-width: ${device.tablet}) {
    align-items: center;
    background-color: #ececec;
    border-radius: 50px;
    display: inline-flex;
    margin-top: 10px;
    padding: 5px 10px;
  }
`;

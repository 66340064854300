import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { ReactComponent as GreenCheckIcon } from 'assets/green-check.svg';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';

// styles
import * as Styled from 'components/pageECoach/_styles/AboutECoach.style';
import * as Text from 'components/_styles/Text.style';

function AboutCard() {
  const { t } = useTranslation('pageEcoach');

  const instructionRows = [
    t('convoPrep.about.Identify key talking points'),
    t('convoPrep.about.Set realistic expectations'),
    t('convoPrep.about.Plan for most likely responses'),
  ];

  const getRow = (row, key) => (
    <Styled.InstructionRow key={key}>
      <GreenCheckIcon />
      <Text.P1>{row}</Text.P1>
    </Styled.InstructionRow>
  );

  return (
    <ChatBubble
      divId="convoPrepForm"
      header={t('Conversation Plan')}
      pointer={true}
      subheader={t('convoPrep.about.Create a clear conversation plan.')}
    >
      <Styled.Content>
        <Styled.InstructionSection>
          {instructionRows.map((row, key) => getRow(row, key))}
        </Styled.InstructionSection>
      </Styled.Content>
    </ChatBubble>
  );
}

export default AboutCard;

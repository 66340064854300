import React from 'react';
import PropTypes from 'prop-types';

// styles
import * as Styled from 'components/_styles/Alert.style';

const Alert = ({ handleClose, message }) => (
  <Styled.Container onClose={handleClose}>
    <Styled.Message>{message}</Styled.Message>
    <Styled.ButtonsArea />
  </Styled.Container>
);

Alert.propTypes = {
  handleClose: PropTypes.func,
  message: PropTypes.string,
};

export default Alert;

import React from 'react';
import PropTypes from 'prop-types';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageEvaluation/_styles/_index.style';

function QuizOptions(props) {
  const { feedbackView, options, selection, selectOption, solution } = props;
  const lang = useUserLanguage();

  if (!feedbackView || selection !== solution)
    return (
      <>
        {options.map((option) => (
          <div key={option.optionNumber}>
            {selection !== option.optionNumber ? (
              <Styled.SelectionBlock
                onClick={() => selectOption(option.optionNumber)}
              >
                <Styled.OptionText>
                  {option[`option_${lang}`]}
                </Styled.OptionText>
              </Styled.SelectionBlock>
            ) : (
              <Styled.SelectedBlock>
                <Styled.OptionText>
                  {option[`option_${lang}`]}
                </Styled.OptionText>
              </Styled.SelectedBlock>
            )}
          </div>
        ))}
      </>
    );
  return (
    <>
      {options.map((option) => (
        <div key={option.optionNumber}>
          {selection === option.optionNumber ? (
            <Styled.SelectedBlock>
              <Styled.OptionText>{option[`option_${lang}`]}</Styled.OptionText>
            </Styled.SelectedBlock>
          ) : (
            <Styled.OptionBlock>
              <Styled.OptionText>{option[`option_${lang}`]}</Styled.OptionText>
            </Styled.OptionBlock>
          )}
        </div>
      ))}
    </>
  );
}

QuizOptions.propTypes = {
  feedbackView: PropTypes.bool,
  options: PropTypes.array,
  selection: PropTypes.number,
  selectOption: PropTypes.func,
  solution: PropTypes.number,
};

export default QuizOptions;

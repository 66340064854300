import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import * as Text from 'components/_styles/Text.style';

const Loader = ({ inline, type }) => {
  const { t } = useTranslation('pageEcoach');

  const [showApology, setShowApology] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowApology(true);
    }, 30000); // 30 seconds

    return () => clearTimeout(timer);
  }, []);

  const apologyText = t(
    'It looks like there may have been an error. Please try refreshing the page or check back in a moment.'
  );

  const actionPlanText = showApology
    ? apologyText
    : t('Finding the best answer for your situation…');
  const convoPrepText = showApology
    ? apologyText
    : t(
        'Analyzing billions of sources to generate your personalized conversation plan.'
      );

  if (inline) return <CircularProgress size={25} />;

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
      <div>
        <CircularProgress size={25} />
      </div>
      <Text.P2>
        {type === 'action plan' ? actionPlanText : convoPrepText}
      </Text.P2>
    </div>
  );
};

export default Loader;

Loader.propTypes = {
  inline: PropTypes.bool,
  type: PropTypes.string,
};

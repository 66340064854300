import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import Chatbar from 'components/pageECoach/chatbar/Chatbar';
import ChatBubble from 'components/pageECoach/ChatBubble';

export default function QuestionGoal({ chatGuideData, setChatGuideData }) {
  const { t } = useTranslation('pageEcoach');

  const handleAnswer = (answer) =>
    setChatGuideData((prev) => ({
      ...prev,
      convoPrep: { ...prev.convoPrep, goal: answer },
    }));

  const eCoachMessage = () => (
    <ChatBubble
      simpleString={t(
        'convoPrep.What is your main goal going into this meeting?'
      )}
      pointer={true}
    />
  );

  if (chatGuideData.convoPrep.goal)
    return (
      <>
        {eCoachMessage()}
        <ChatBubble
          simpleString={chatGuideData.convoPrep.goal}
          pointer={true}
          sentByUser
        />
      </>
    );

  return (
    <>
      {eCoachMessage()}
      <Chatbar autoFocus initialInput="" onSubmit={handleAnswer} />
    </>
  );
}

QuestionGoal.propTypes = {
  chatGuideData: PropTypes.object,
  setChatGuideData: PropTypes.func,
};

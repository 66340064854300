import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// assets
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// components
import MemberCardJourneyRows from 'components/pageOrganization/MemberCardJourneyRows';
import MemberFormUpdate from 'components/pageOrganization/MemberFormUpdate';

// hooks
import useApi from 'hooks/useApi';
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Cards from 'components/_styles/Cards.style';
import * as Styled from 'components/pageOrganization/_styles/MemberCard.style';
import * as Text from 'components/_styles/Text.style';

function MemberCard({ getMembersList, member, setAlert }) {
  const { data: updateSubmission, callApi: updateCallApi } = useApi();
  const { data: deleteSubmission, callApi: deleteCallApi } = useApi();
  const { t } = useTranslation();
  const lang = useUserLanguage();

  const [showEditMember, setShowEditMember] = useState('');
  const showEditInput = (mem) => setShowEditMember(mem.user.email);
  const hideEditInput = () => setShowEditMember('');

  useEffect(() => {
    if (updateSubmission.status === 'success') {
      setAlert(t('pageOrganization:editSuccess'));
      getMembersList('/members/');
    } else if (updateSubmission.status === 'error') {
      setAlert(t('pageOrganization:editError'));
    }
  }, [updateSubmission, getMembersList, setAlert, t]);

  useEffect(() => {
    if (deleteSubmission.status === 'success') {
      setAlert(t('pageOrganization:deleteSuccess'));
      getMembersList('/members/');
    } else if (deleteSubmission.status === 'error') {
      setAlert(t('pageOrganization:deleteError'));
    }
  }, [deleteSubmission, getMembersList, setAlert, t]);

  return (
    <Cards.AccordianCard key={member.user.email}>
      <Cards.AccordionOverview
        aria-controls="panel1a-content"
        expandIcon={<ExpandMoreIcon />}
        id="panel1a-header"
      >
        <Text.H2>
          {member.job_title
            ? member.user.first_name.concat(' ', member.user.last_name)
            : member.user.first_name.concat(' ', member.user.last_name)}
        </Text.H2>
      </Cards.AccordionOverview>
      <Cards.AccordionContent>
        <Styled.AccountMeta>
          <Styled.AccountMetaInfo>
            <Styled.AccountMetaInfoColumn>
              <Text.CaptionCap>{t('pageOrganization:email')}</Text.CaptionCap>
              <Text.P1 style={{ margin: '0 0 5px 0' }}>
                {member.user.email}
              </Text.P1>
            </Styled.AccountMetaInfoColumn>
            {member.job_title && (
              <Styled.AccountMetaInfoColumn>
                <Text.CaptionCap>
                  {t('pageOrganization:jobTitle')}
                </Text.CaptionCap>
                <Text.P1 style={{ margin: '0 0 5px 0' }}>
                  {member.job_title}
                </Text.P1>
              </Styled.AccountMetaInfoColumn>
            )}
            <Styled.AccountMetaInfoColumn>
              <Text.CaptionCap>
                {t('pageOrganization:dateAdded')}
              </Text.CaptionCap>
              <Styled.DateText>
                {`Added ${dayjs(member.user.date_joined)
                  .locale(lang)
                  .format('LLL')}`}
              </Styled.DateText>
            </Styled.AccountMetaInfoColumn>
          </Styled.AccountMetaInfo>
          {member.user.email === showEditMember ? (
            <Text.P1 onClick={hideEditInput} style={{ cursor: 'pointer' }}>
              {t('pageOrganization:closeEdit')}
            </Text.P1>
          ) : (
            <Text.P1
              onClick={() => showEditInput(member)}
              style={{ cursor: 'pointer' }}
            >
              {t('pageOrganization:edit')}
            </Text.P1>
          )}
        </Styled.AccountMeta>
        {member.user.email === showEditMember && (
          <MemberFormUpdate
            deleteCallApi={deleteCallApi}
            hideEditInput={hideEditInput}
            member={member}
            updateCallApi={updateCallApi}
          />
        )}
        <MemberCardJourneyRows participations={member.participations} />
      </Cards.AccordionContent>
    </Cards.AccordianCard>
  );
}

MemberCard.propTypes = {
  getMembersList: PropTypes.func,
  member: PropTypes.object.isRequired,
  setAlert: PropTypes.func,
};

export default MemberCard;

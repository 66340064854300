import styled from 'styled-components';
import * as Buttons from 'components/_styles/Buttons.style';
import * as Cards from 'components/_styles/Cards.style';
import colours from 'constants/colours';
import device from 'constants/devices';

export const Card = styled(Cards.BasicCard)`
  gap: 20px;
  justify-content: unset;
  max-width: 540px;
  padding: 30px;
  position: relative;
  width: 100%;

  @media (max-width: ${device.mobileL}) {
    border-radius: 30px;
    gap: 15px;
    justify-content: space-between;
    padding: 15px;
  }
`;

export const Wrapper = styled(Buttons.Primary)`
  .atcb_initialized {
    text-align: center;
  }

  .atcb_button_wrapper {
    padding: 0;
  }

  .atcb_button {
    background-color: white;
    border: 1px solid black;
    border-radius: 50px;
    box-shadow: none;
    color: black;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: 48px;
    margin: 0;
    padding: 0 30px;
    text-transform: unset;

    &:hover {
      border: 1px solid ${colours.primary[500]};
    }

    @media (max-width: ${device.tablet}) {
      font-size: 14px;
    }

    @media (max-width: ${device.mobileL}) {
      height: 36px;
      padding: 0 15px;
    }
  }

  .atcb_icon {
    display: none;
  }

  .atcb_list_item {
    min-width: 170px;
  }

  @media (max-width: 600px) {
    .atcb_button {
      min-width: 0;
    }
    .atcb_icon {
      margin: 0;
    }
  }
`;

export default Wrapper;

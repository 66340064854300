import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// assets
import FinalCardImg from 'assets/finalCard.svg';

// component
import Textbox from 'components/pageSlideshow/Textbox';

// constants
import routesConfig from 'constants/routesConfig.json';

// styles
import * as StyledTextbox from 'components/pageSlideshow/_styles/Textbox.style';

export default function SlideLast({ journeyId }) {
  const history = useHistory();

  const handleRedirect = () => {
    history.push({
      pathname: `${routesConfig.JOURNEY_ID_PAGE}/${journeyId}`,
    });
  };

  return (
    <>
      <StyledTextbox.SuccessImgContainer>
        <StyledTextbox.SuccessImg src={FinalCardImg} />
      </StyledTextbox.SuccessImgContainer>
      <Textbox ctaView={true} nextClick={handleRedirect} />
    </>
  );
}

SlideLast.propTypes = {
  journeyId: PropTypes.string,
};

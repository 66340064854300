import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import JourneyCardAdmin from 'components/pageJourneyList/orgAdminView/JourneyCardAdmin';

// styles
import * as Styled from 'components/pageJourneyList/_styles/PageView.style';
import * as Text from 'components/_styles/Text.style';

export default function OrgAdminJourneyList({ journeyList }) {
  const { t } = useTranslation();

  const listContent = () => {
    if (journeyList.length === 0) return <p></p>;
    return (
      <>
        {journeyList.map((journey) => (
          <div key={journey.id}>
            <JourneyCardAdmin journey={journey} />
          </div>
        ))}
      </>
    );
  };

  return (
    <Styled.Page>
      <Text.H1>{t('pageJourneyList:journeys')}</Text.H1>
      {listContent()}
    </Styled.Page>
  );
}

OrgAdminJourneyList.propTypes = {
  journeyList: PropTypes.array,
};

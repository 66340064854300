import styled from 'styled-components';
import * as Buttons from 'components/_styles/Buttons.style';
import * as Text from 'components/_styles/Text.style';
import colours from 'constants/colours';
import device from 'constants/devices';

export const AccountMeta = styled('div')`
  align-items: center;
  background-color: #ececec;
  display: flex;
  justify-content: space-between;
  padding: 10px 50px;

  @media (max-width: ${device.tablet}) {
    align-items: start;
    padding: 10px 20px;
  }
`;

export const AccountMetaInfo = styled('div')`
  display: flex;

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
  }
`;

export const AccountMetaInfoColumn = styled('div')`
  margin-right: 60px;

  @media (max-width: ${device.tablet}) {
    margin-bottom: 10px;
  }
`;

export const DateText = styled(Text.P1)`
  margin: 0 0 5px 0;
  text-transform: capitalize;
`;

export const EditForm = styled('div')`
  padding: 10px 50px;
`;

export const JourneyInfo = styled('div')`
  align-items: center;
  display: flex;

  @media (max-width: ${device.tablet}) {
    width: 100%;
  }
`;

export const JourneyRow = styled('div')`
  align-items: center;
  border-top: 1.5px solid ${colours.neutralCool[300]};
  color: black;
  display: flex;
  justify-content: space-between;
  max-height: 250px;
  padding: 30px 50px;
  text-decoration: none;

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
    padding: 10px 20px;
  }
`;

export const JourneyRows = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const LearnMoreButton = styled(Buttons.Secondary)`
  @media (max-width: ${device.tablet}) {
    margin: 20px;
    width: 100%;
  }

  @media (max-width: ${device.mobileL}) {
    margin: 10px;
  }
`;

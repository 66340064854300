/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// styles
import * as Styled from 'components/_pageLayout/_styles/ErrorBoundary.style';
import * as Text from 'components/_styles/Text.style';

function DefaultError() {
  const { t } = useTranslation();
  return (
    <Styled.Container>
      <Text.H5Extrabold>{t('pageLayout:error')}</Text.H5Extrabold>
    </Styled.Container>
  );
}

export default class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: '',
      errorInfo: '',
      hasError: false,
    };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });
    this.setState({ errorInfo });
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallback) return this.props.fallback;
      return <DefaultError />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  fallback: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useWebsocketSetup from 'hooks/useWebsocketSetup';
import routesConfig from 'constants/routesConfig.json';

const ECoachSessionContext = createContext(null);

// eslint-disable-next-line react/prop-types
export const ECoachSessionProvider = ({ children }) => {
  const history = useHistory();
  const { id: paramSessionId } = useParams();

  const [session, setSession] = useState(null);
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebsocketSetup(
    routesConfig.ECOACH.SESSION_WS
  );

  useEffect(() => {
    if (lastJsonMessage === null) {
      return;
    }

    const { action, data } = lastJsonMessage;

    if (action === 'patch_or_create' || action === 'retrieve') {
      setSession(data);

      if (data?.id !== paramSessionId) {
        if (data?.id) {
          history.push(`/eCoach/${data.id}`);
        } else {
          history.push('/eCoach/');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, lastJsonMessage]);

  const retrieveSession = useCallback(
    (pk) => {
      if (readyState !== WebSocket.OPEN) {
        return;
      }
      sendJsonMessage({
        action: 'retrieve',
        request_id: Date.now(),
        pk,
      });
    },
    [readyState, sendJsonMessage]
  );

  const updateSession = useCallback(
    (pk, data) => {
      if (data.pk) {
        // eslint-disable-next-line no-param-reassign
        delete data.pk;
      }

      if (readyState !== WebSocket.OPEN) {
        return;
      }

      sendJsonMessage({
        action: 'patch_or_create',
        request_id: Date.now(),
        pk,
        data,
      });
    },
    [readyState, sendJsonMessage]
  );

  const updateContext = useCallback(
    (sessionId, newContext) => {
      updateSession(sessionId, { context: newContext });
    },
    [updateSession]
  );

  const updateProgress = useCallback(
    (sessionId, newProgress) => {
      updateSession(sessionId, { progress: newProgress });
    },
    [updateSession]
  );

  useEffect(() => {
    retrieveSession(paramSessionId);
  }, [paramSessionId, retrieveSession]);

  return (
    <ECoachSessionContext.Provider
      value={{
        session,
        retrieveSession,
        updateSession,
        updateContext,
        updateProgress,
      }}
    >
      {children}
    </ECoachSessionContext.Provider>
  );
};

// Step 3: Create a custom hook to use the session context
export const useSessionContext = () => useContext(ECoachSessionContext);

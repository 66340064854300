import styled from 'styled-components';
import device from 'constants/devices';
import colours from 'constants/colours';
import * as Text from 'components/_styles/Text.style';

export const JourneyMetaInfo = styled('div')`
  max-width: 600px;
`;

export const JourneyMeta = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const MicrolearningHeader = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${device.mobileL}) {
    align-items: start;
    flex-direction: column;
  }
`;

export const TabArea = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const InfoTabArea = styled(TabArea)`
  margin-bottom: 50px;
`;

export const Tab = styled('div')`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  padding: 15px 30px;
  transition: 0.1s;

  ${({ active }) =>
    active === 'true' &&
    `
    background-color: ${colours.primary[500]};
    border-radius: 50px;
    color: white;
    font-weight: 900;
    pointer-events: none;
  `}

  @media (max-width: ${device.mobileS}) {
    padding: 5px 20px;
  }
`;

export const TabContent = styled('div')`
  max-width: 40em;
  padding-left: 5px;
`;

export const Tabs = styled('div')`
  align-items: center;
  background-color: ${colours.primary[50]};
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  width: min-content;
`;

export const TabText = styled(Text.P1)`
  font-weight: 700;
  margin: 0;
`;

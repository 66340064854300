import { styled } from '@mui/material/styles';
import colours from 'constants/colours';
import device from 'constants/devices';

export const ClockIcon = styled('img')`
  height: 20px;
`;

export const DateSection = styled('div')`
  align-items: center;
  display: flex;
  gap: 13px;
  padding: 5px 10px 5px 5px;
`;

export const LiveIndicator = styled('div')`
  align-items: center;
  background-color: ${colours.primary[500]};
  border-radius: 30px;
  display: flex;
  justify-content: center;
  gap: 13px;
  padding: 5px 10px 5px 5px;
  width: fit-content;

  svg path {
    stroke: ${colours.shade[0]};
  }

  p {
    color: white;
  }
`;

export const OwlImage = styled('img')`
  border-radius: 100px;
  height: 30px;
  object-fit: cover;
  width: 30px;
`;

export const Participants = styled('div')`
  align-items: center;
  display: flex;
  gap: 13px;
  padding: 5px 10px 5px 5px;
`;

export const RowCol = styled('div')`
  align-items: center;
  display: flex;
  gap: 8px;

  p {
    color: ${colours.neutralCool[600]};
  }

  svg path {
    stroke: ${colours.neutralCool[600]};
  }
`;

export const RowInfo = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: ${device.mobileM}) {
    flex-direction: column;
  }
`;

export const SeeMore = styled('div')`
  display: flex;
  gap: 10px;
  justify-content: center;

  p {
    color: ${colours.neutralCool[400]};
  }
`;

export const UpcomingSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const UpcomingData = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const UpcomingRow = styled('div')`
  border-bottom: 1px solid ${colours.neutralCool[200]};
  border-top: 1px solid ${colours.neutralCool[200]};
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 0;
`;

export const UsersIcon = styled('img')`
  height: 20px;
`;

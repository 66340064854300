import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { Maps, Time, Users } from 'untitledui-js';

// components
import ActiveCard from 'components/pageJourneyList/ActiveCard';
import JoinLobbyButton from 'components/buttonJoinLobby/_index';
import ProgressBar from 'components/pageJourneyList/ProgressBar';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageJourneyList/_styles/ActiveCardOwl.style';
import * as Text from 'components/_styles/Text.style';

function ActiveCardOwlLive({ journey, schedule }) {
  const { t } = useTranslation();
  const lang = useUserLanguage();

  const totalMicrolearnings = journey.microlearnings?.length;
  const completedMicrolearnings = journey.microlearnings?.filter(
    (microlearning) => microlearning.reviewed === true
  ).length;

  const cta = () => (
    <JoinLobbyButton
      bookingId={schedule.id}
      journeyId={journey.id}
      microlearningTitle={schedule.microlearning[`title_${lang}`]}
    />
  );

  const featuredInfo = () => (
    <>
      {schedule.participants && (
        <Styled.Participants>
          <Users.User01 />
          <Text.P2Semibold>
            {t('pageJourneyList:participants', {
              count: schedule.participants,
            })}
          </Text.P2Semibold>
        </Styled.Participants>
      )}
      <Styled.DateSection>
        <Maps.Globe02 />
        <Text.P2Semibold>
          {schedule.language === 'en' ? 'English' : 'Français'}
        </Text.P2Semibold>
      </Styled.DateSection>
      <Styled.LiveIndicator>
        <Time.Clock />
        <Text.P2Semibold>
          {`${t('pageCalendar:live')} | ${dayjs(schedule.starts_at)
            .locale(lang)
            .format('LLL')}`}
        </Text.P2Semibold>
      </Styled.LiveIndicator>
    </>
  );

  const progressBar = () => (
    <ProgressBar
      progress={completedMicrolearnings}
      total={totalMicrolearnings}
    />
  );

  return (
    <ActiveCard
      cta={cta}
      featuredInfo={featuredInfo}
      journey={journey}
      progressBar={progressBar}
    />
  );
}

ActiveCardOwlLive.propTypes = {
  journey: PropTypes.object,
  schedule: PropTypes.object,
};

export default ActiveCardOwlLive;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';

// components
import * as Styled from 'components/buttonOwl/_styles/OwlProfileModal.style';

// styles
import * as Forms from 'components/_styles/Forms.style';
import * as Text from 'components/_styles/Text.style';

function OwlProfileModal({ handleModalClose, owlInfo }) {
  const { t } = useTranslation();
  const owlName = `${owlInfo.user.first_name} ${owlInfo.user.last_name}`;

  return (
    <Styled.Container>
      <Forms.CloseButtomArea>
        <IconButton
          aria-label={t('buttonOwl:close')}
          onClick={handleModalClose}
        >
          <Close />
        </IconButton>
      </Forms.CloseButtomArea>
      <Styled.OwlName>{owlName}</Styled.OwlName>
      <Styled.ImageContainer>
        <Styled.Image alt="Owl's picture" src={owlInfo.picture} />
      </Styled.ImageContainer>
      <Text.P1 style={{ maxWidth: '30em' }}>{owlInfo.expertise}</Text.P1>
      <Text.P1 style={{ maxWidth: '30em' }}>{owlInfo.reason}</Text.P1>
    </Styled.Container>
  );
}

OwlProfileModal.propTypes = {
  handleModalClose: PropTypes.func,
  owlInfo: PropTypes.object,
};

export default OwlProfileModal;

import styled from 'styled-components';
import device from 'constants/devices';
import * as Text from 'components/_styles/Text.style';

export const Page = styled('div')`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  padding-top: 30px;

  @media (max-width: ${device.tablet}) {
    align-items: start;
    overflow: auto;
    padding-top: 70px;
  }
`;

export const OnboardingP = styled(Text.P1)`
  color: #8f9499;
  font-size: 17px;
  line-height: 120%;

  @media (max-width: ${device.tablet}) {
    font-size: 17px;
  }
`;

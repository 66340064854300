import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// components
import ApiLoading from 'components/_pageLayout/ApiLoading';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import MemberJourneyList from 'components/pageJourneyList/memberView/_index';
import OrgAdminJourneyList from 'components/pageJourneyList/orgAdminView/_index';
import OwlJourneyList from 'components/pageJourneyList/owlView/_index';

// context
import { useUser } from 'contexts/UserContext';

// constants
import userRoles from 'constants/userRoles.json';

// hooks
import useApi from 'hooks/useApi';

export default function JourneyListPage({ testData, testUserRole }) {
  const { data, callApi: getJourneyList } = useApi();
  const { data: stats, callApi: getOrganizationStatistics } = useApi();
  const { userRole } = useUser();

  const journeyList = testData || data;
  const organizationStatistics = testData
    ? testData.organizationStatistics
    : stats;
  const role = testUserRole || userRole;

  useEffect(() => {
    if (!testData && role === userRoles.ADMIN)
      getOrganizationStatistics('/statistics/organizations/');
  }, [getOrganizationStatistics, testData, role]);

  useEffect(() => {
    if (!testData && role === userRoles.MEMBER)
      getJourneyList('/members/journeys/');
    if (!testData && role === userRoles.OWL) getJourneyList('/owls/journeys/');
    if (!testData && role === userRoles.ADMIN) getJourneyList('/hr/journeys/');
  }, [testData, getJourneyList, role]);

  if (journeyList.status === 'loading') return <ApiLoading />;

  if (role === userRoles.MEMBER)
    return (
      <ErrorBoundary>
        <MemberJourneyList journeyList={journeyList.response} />
      </ErrorBoundary>
    );

  if (role === userRoles.ADMIN)
    return (
      <ErrorBoundary>
        <OrgAdminJourneyList
          journeyList={journeyList.response}
          organizationStatistics={organizationStatistics}
        />
      </ErrorBoundary>
    );

  if (role === userRoles.OWL)
    return (
      <ErrorBoundary>
        <OwlJourneyList journeyList={journeyList.response} />
      </ErrorBoundary>
    );

  return null;
}

JourneyListPage.propTypes = {
  testData: PropTypes.object,
  testUserRole: PropTypes.string,
};

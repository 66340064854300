import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import { FormControl, MenuItem } from '@mui/material';

const languageOptions = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'Français' },
];

export default function LanguageField({
  t,
  currentLanguage,
  handleLanguageChange,
}) {
  return (
    <FormControl fullWidth>
      <Field
        component={Select}
        name="language"
        label={t('Language')}
        value={currentLanguage}
        onChange={(event) => handleLanguageChange(event.target.value)}
      >
        {languageOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
}

LanguageField.propTypes = {
  t: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  handleLanguageChange: PropTypes.func.isRequired,
};

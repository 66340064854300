import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EmailIcon from '@mui/icons-material/Email';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PhoneIcon from '@mui/icons-material/Phone';
import VideocamIcon from '@mui/icons-material/Videocam';

// hooks
import useApi from 'hooks/useApi';

// styles
import * as Page from 'components/pageOnboarding/_styles/_index.style';
import * as Styled from 'components/pageOnboarding/_styles/CommunicationForm.style';
import * as StyledCard from 'components/pageOnboarding/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';

function CommunicationForm(props) {
  const {
    communicationCardNumber,
    handleSectionReturn,
    markComplete,
    setSubmittedCommunicationForm,
    submittedCommunicationForm,
  } = props;

  const { data, callApi } = useApi();
  const { t } = useTranslation('pageOnboarding');

  const [cardNumber, setCardNumber] = useState(communicationCardNumber);
  const [communicationPreference, setCommunicationPreference] = useState(
    submittedCommunicationForm.communication_preference
  );
  const [timingPreference, setTimingPreference] = useState(
    submittedCommunicationForm.feedback_timing
  );
  const [modePreference, setModePreference] = useState(
    submittedCommunicationForm.feedback_mode
  );

  const communicationPreferences = {
    phone_call: { label: t('pageOnboarding:phone'), icon: <PhoneIcon /> },
    instant_chat: { label: t('pageOnboarding:chat'), icon: <ChatBubbleIcon /> },
    email: { label: t('pageOnboarding:email'), icon: <EmailIcon /> },
    face_to_face: { label: t('pageOnboarding:face'), icon: <PeopleAltIcon /> },
    video_call: { label: t('pageOnboarding:video'), icon: <VideocamIcon /> },
  };

  const timingPreferences = {
    immediate: t('pageOnboarding:immediate'),
    delayed: t('pageOnboarding:delayed'),
  };

  const modePreferences = {
    virtual: t('pageOnboarding:virtual'),
    in_person: t('pageOnboarding:in_person'),
  };

  const nextPageClick = () => {
    if (cardNumber === 2) {
      const params = {
        communication_preference: communicationPreference,
        feedback_mode: modePreference,
        feedback_timing: timingPreference,
      };
      callApi('/members/communication-preferences/ ', 'put', params);
      setSubmittedCommunicationForm(params);
    }
    return setCardNumber((prev) => prev + 1);
  };

  useEffect(() => {
    if (data.status === 'success') markComplete(5);
  }, [data.status, markComplete]);

  const backPageClick = () => {
    if (cardNumber === 1) handleSectionReturn(5);
    return setCardNumber((prev) => prev - 1);
  };
  const handleComSelect = (com) => setCommunicationPreference(com);

  const handleTimingToggle = () => {
    if (timingPreference === 'immediate') setTimingPreference('delayed');
    else setTimingPreference('immediate');
  };

  const handleModeToggle = () => {
    if (modePreference === 'virtual') setModePreference('in_person');
    else setModePreference('virtual');
  };

  const card1 = {
    body: t(
      "Although this info won't be used directly, it helps us understand our community and improve our services."
    ),
    header: t('pageOnboarding:method'),
  };

  const card2 = {
    body: t(
      "Although this info won't be used directly, it helps us understand our community and improve our services."
    ),
    header: t('pageOnboarding:feedback'),
  };

  const getCard1 = () => (
    <Styled.TabArea>
      {Object.keys(communicationPreferences).map((key) => (
        <Styled.ButtonSelect
          aria-label={communicationPreferences[key].label}
          key={key}
          onClick={() => handleComSelect(key)}
          selected={communicationPreference === key}
          startIcon={communicationPreferences[key].icon}
        >
          {communicationPreferences[key].label}
        </Styled.ButtonSelect>
      ))}
    </Styled.TabArea>
  );

  const getCard2 = () => (
    <Styled.TabArea>
      <Styled.Tabs>
        <Styled.Tab
          onClick={handleTimingToggle}
          selected={timingPreference === 'immediate'}
        >
          <Styled.TabText selected={timingPreference === 'immediate'}>
            {timingPreferences.immediate}
          </Styled.TabText>
        </Styled.Tab>
        <Styled.Tab
          onClick={handleTimingToggle}
          selected={timingPreference === 'delayed'}
        >
          <Styled.TabText selected={timingPreference === 'delayed'}>
            {timingPreferences.delayed}
          </Styled.TabText>
        </Styled.Tab>
      </Styled.Tabs>
      <Styled.Tabs>
        <Styled.Tab
          onClick={handleModeToggle}
          selected={modePreference === 'virtual'}
        >
          <Styled.TabText selected={modePreference === 'virtual'}>
            {modePreferences.virtual}
          </Styled.TabText>
        </Styled.Tab>
        <Styled.Tab
          onClick={handleModeToggle}
          selected={modePreference === 'in_person'}
        >
          <Styled.TabText selected={modePreference === 'in_person'}>
            {modePreferences.in_person}
          </Styled.TabText>
        </Styled.Tab>
      </Styled.Tabs>
    </Styled.TabArea>
  );

  return (
    <StyledCard.Card>
      <StyledCard.TopSection>
        <StyledCard.InnerSection>
          <Text.H5Bold>
            {cardNumber === 1 && card1.header}
            {cardNumber === 2 && card2.header}
          </Text.H5Bold>
          <Page.OnboardingP style={{ marginBottom: '40px' }}>
            {cardNumber === 1 && card1.body}
            {cardNumber === 2 && card2.body}
          </Page.OnboardingP>
          {cardNumber === 1 && getCard1()}
          {cardNumber === 2 && getCard2()}
        </StyledCard.InnerSection>
      </StyledCard.TopSection>
      <StyledCard.Navigation>
        <StyledCard.BackButton
          aria-label={t('pageOnboarding:back')}
          onClick={backPageClick}
        >
          <NavigateBeforeIcon />
        </StyledCard.BackButton>
        <StyledCard.NavButton
          aria-label={t('pageOnboarding:next')}
          onClick={nextPageClick}
        >
          <NavigateNextIcon />
        </StyledCard.NavButton>
      </StyledCard.Navigation>
    </StyledCard.Card>
  );
}

CommunicationForm.propTypes = {
  communicationCardNumber: PropTypes.number,
  handleSectionReturn: PropTypes.func,
  markComplete: PropTypes.func,
  setSubmittedCommunicationForm: PropTypes.func,
  submittedCommunicationForm: PropTypes.object,
};

export default CommunicationForm;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// constants
import routesConfig from 'constants/routesConfig.json';

// styles
import * as Buttons from 'components/_styles/Buttons.style';
import * as Styled from 'components/pageCalendar/_styles/PageView.style';

export default function NoDatesCard({ description, withButton }) {
  const { t } = useTranslation();
  const history = useHistory();

  const handleHomeRedirect = useCallback(() => {
    history.push({ pathname: routesConfig.JOURNEY_LIST_PAGE });
  }, [history]);

  return (
    <Styled.EmptyCard>
      {description && (
        <Styled.EmptyCardText>{description}</Styled.EmptyCardText>
      )}
      {withButton && (
        <Buttons.Primary onClick={handleHomeRedirect}>
          {t('pageCalendar:emptyUpcomingButton')}
        </Buttons.Primary>
      )}
    </Styled.EmptyCard>
  );
}

NoDatesCard.propTypes = {
  description: PropTypes.string,
  withButton: PropTypes.bool,
};

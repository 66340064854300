import styled from 'styled-components';
import * as Cards from 'components/_styles/Cards.style';
import colours from 'constants/colours';
import device from 'constants/devices';
import * as Button from 'components/_styles/Buttons.style';
import * as Text from 'components/_styles/Text.style';

export const CloseSection = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0 0;
  width: 100%;
`;

export const CloseButton = styled(Button.SecondaryIcon)`
  height: 40px;
  max-width: 40px;
  min-width: unset;
  padding: 0;
  width: 40px;

  svg {
    height: 20px;
    width: 20px;
  }
`;

export const Card = styled(Cards.BasicCard)`
  align-items: center;
  box-shadow: none;
  display: flex;
  height: unset;
  justify-content: start;
  overflow: unset;
  padding: 0;
  position: relative;
  width: 480px;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (max-width: ${device.tablet}) {
    background-color: white;
    border-radius: 30px;
    gap: 30px;
    width: 100%;
  }
`;

export const CardContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 30px 30px 30px;
`;

export const Download = styled('a')`
  color: ${colours.blue[500]};
  text-decoration: none;
`;

export const Header = styled(Text.H3Bold)`
  text-align: center;
`;

export const ListSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const ListRow = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 30px;

  @media (max-width: ${device.mobileL}) {
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
`;

export const ListIcon = styled('img')`
  height: 50px;
  width: 50px;
`;

export const ListDescriptionSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: ${device.mobileL}) {
    text-align: center;
  }
`;

export const DescriptionText = styled(Text.P2Semibold)`
  color: ${colours.neutralCool[500]};
  display: flex;
  flex-direction: column;
`;

export const BottomSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

export const ButtonSection = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import Chatbar from 'components/pageECoach/chatbar/Chatbar';
import ChatBubble from 'components/pageECoach/ChatBubble';

export default function QuestionWho({ chatGuideData, setChatGuideData }) {
  const { t } = useTranslation('pageEcoach');

  const handleAnswer = (answer) =>
    setChatGuideData((prev) => ({
      ...prev,
      convoPrep: { ...prev.convoPrep, with_who: answer },
    }));

  const eCoachMessage = () => (
    <ChatBubble
      simpleString={t(
        'convoPrep.Who would you like to have a conversation with?'
      )}
      pointer={true}
    />
  );

  if (chatGuideData.convoPrep.with_who)
    return (
      <>
        {eCoachMessage()}
        <ChatBubble
          simpleString={chatGuideData.convoPrep.with_who}
          pointer={true}
          sentByUser
        />
      </>
    );

  return (
    <>
      {eCoachMessage()}
      <Chatbar autoFocus initialInput="" onSubmit={handleAnswer} />
    </>
  );
}

QuestionWho.propTypes = {
  chatGuideData: PropTypes.object,
  setChatGuideData: PropTypes.func,
};

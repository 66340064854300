import styled from 'styled-components';
import * as Text from 'components/_styles/Text.style';
import colours from 'constants/colours';
import device from 'constants/devices';

export const AboutDescription = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ListSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: ${device.tablet}) {
    gap: 10px;
  }
`;

export const ListRow = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: ${device.mobileL}) {
    align-items: center;
    flex-direction: column;
  }
`;

export const ListIcon = styled('img')`
  object-fit: contain;
  object-position: 0 0;
  width: 30px;
`;

export const ListDescriptionSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (max-width: ${device.mobileL}) {
    text-align: center;
  }
`;

export const DescriptionText = styled(Text.P1)`
  color: ${colours.neutralCool[500]};
  display: flex;
  flex-direction: column;
`;

export const BottomSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

export const ButtonSection = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import DetailsCardSupportAdmin from 'components/pageCalendar/supportAdminView/DetailsCardSupportAdmin';
import NoDatesCard from 'components/pageCalendar/NoDatesCard';

// styles
import * as Styled from 'components/pageCalendar/_styles/PageView.style';

export default function SupportAdminPageView({ scheduledDates }) {
  const { t } = useTranslation();

  const getDetailsCard = (booking) => (
    <DetailsCardSupportAdmin details={booking} key={booking.id} />
  );

  return (
    <Styled.Page>
      <Styled.DateCardPage>
        {scheduledDates?.length === 0 ? (
          <NoDatesCard description={t('pageCalendar:emptyUpcoming')} />
        ) : (
          scheduledDates?.map((booking) => getDetailsCard(booking))
        )}
      </Styled.DateCardPage>
    </Styled.Page>
  );
}

SupportAdminPageView.propTypes = {
  scheduledDates: PropTypes.array.isRequired,
};

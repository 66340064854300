import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';

export const Card = styled('div')`
  background: linear-gradient(278.76deg, #1a2634 1.35%, #36475a 102.58%);
  border-radius: 40px;
  display: flex;
  gap: 60px;
  padding: 50px;

  @media (max-width: ${device.laptop}) {
    align-items: center;
    flex-direction: column;
    gap: 15px;
    padding: 30px;
  }
`;

export const IconSection = styled('div')`
  display: flex;
  padding: 20px;
  img {
    object-fit: contain;
    width: 100%;
  }
  justify-content: center;
`;

export const DescriptionSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 500px;
  padding: 50px 60px 50px 0;

  @media (max-width: ${device.laptop}) {
    flex-direction: column;
    gap: 15px;
    padding: 0;
    text-align: center;
  }
`;

export const Description = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 15px;
  h1,
  p {
    color: ${colours.shade[0]};
  }
`;

import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';

// styles
import * as Styled from 'components/pageCalendar/_styles/Calendar.style';
import * as Text from 'components/_styles/Text.style';

function DayCell(props) {
  const { day, month, scheduledDates, selectedDate, setFilter, year } = props;

  const isActiveDate = () => {
    const dateString = dayjs()
      .set('year', year)
      .set('month', month)
      .set('date', day);
    return scheduledDates.includes(dateString.format('ddd MMM DD YYYY'));
  };

  const getClass = () => {
    if (dayjs(`${year}-${month + 1}-${day}`).isSame(selectedDate))
      return 'filtering';
    if (isActiveDate(day, month, year)) return 'clickable';
    return '';
  };

  return (
    <Styled.Day onClick={() => setFilter(day, month, year)}>
      <Styled.DayInner className={getClass()}>
        <Styled.DayText>
          <Styled.DotFiller />
          <Text.P2Semibold>{day}</Text.P2Semibold>
          {isActiveDate(day, month, year) ? (
            <Styled.Dot />
          ) : (
            <Styled.DotFiller />
          )}
        </Styled.DayText>
      </Styled.DayInner>
    </Styled.Day>
  );
}

DayCell.propTypes = {
  day: PropTypes.number,
  month: PropTypes.number,
  scheduledDates: PropTypes.array,
  selectedDate: PropTypes.object,
  setFilter: PropTypes.func,
  year: PropTypes.number,
};
export default DayCell;

// useIndustries.js
import { useEffect, useState } from 'react';
import useApi from 'hooks/useApi';

const useIndustries = (currentLanguage) => {
  const { data, callApi } = useApi();
  const [industries, setIndustries] = useState({});

  useEffect(() => {
    callApi('/demo/industries', 'get', null, {
      'Accept-Language': currentLanguage,
    });
  }, [callApi, currentLanguage]);

  useEffect(() => {
    if (data.status === 'success' && data.response) {
      setIndustries(data.response);
    }
  }, [data]);

  return industries;
};

export default useIndustries;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// assets
import Award from 'assets/award.png';

// components
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';

// constants
import routesConfig from 'constants/routesConfig.json';

// styles
import * as Styled from 'components/pageEvaluation/_styles/EndCard.style';

function QuizEndCard({ journeyTitle }) {
  const { t } = useTranslation();
  const history = useHistory();

  const redirect = useCallback(() => {
    history.push(routesConfig.JOURNEY_LIST_PAGE);
  }, [history]);

  return (
    <ErrorBoundary>
      <Styled.Card>
        <Styled.Image src={Award} />
        <Styled.Title>{t('pageEvaluation:endCardTitle')}</Styled.Title>
        {journeyTitle && (
          <Styled.Message>
            {t('pageEvaluation:endCardText', { journeyTitle })}
          </Styled.Message>
        )}
        <Styled.EndButton onClick={redirect}>
          {t('pageEvaluation:endCardButton')}
        </Styled.EndButton>
      </Styled.Card>
    </ErrorBoundary>
  );
}

QuizEndCard.propTypes = {
  journeyTitle: PropTypes.string,
};

export default QuizEndCard;

import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { Arrow } from 'untitledui-js';

// components
import DetailsCardMember from 'components/pageCalendar/memberView/DetailsCardMember';
import DetailsCardOwl from 'components/pageCalendar/owlView/DetailsCardOwl';
import NoDatesCard from 'components/pageCalendar/NoDatesCard';

// context
import { useUser } from 'contexts/UserContext';

// constants
import userRoles from 'constants/userRoles.json';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageCalendar/_styles/PageView.style';
import * as Text from 'components/_styles/Text.style';

export default function DateCardColumn(props) {
  const { filteredDates, pastDates, resetFilter, selectedDate, upcomingDates } =
    props;
  const { t } = useTranslation();
  const lang = useUserLanguage();
  const { userRole } = useUser();

  const getDetailsCard = (details) => {
    if (userRole === userRoles.MEMBER)
      return <DetailsCardMember details={details} key={details.id} />;
    return <DetailsCardOwl details={details} key={details.id} />;
  };

  const getCardHeader = () => {
    if (selectedDate === null) return t('pageCalendar:reservations');
    return dayjs(selectedDate).locale(lang).format('dddd LL');
  };

  const defaultView = () => (
    <>
      <Text.H1Bold>{t('pageCalendar:upcoming')}</Text.H1Bold>
      {upcomingDates.length === 0 ? (
        <NoDatesCard description={t('pageCalendar:emptyUpcoming')} withButton />
      ) : (
        upcomingDates?.map((upcomingDate) => getDetailsCard(upcomingDate))
      )}
      <Text.H1Bold>{t('pageCalendar:completed')}</Text.H1Bold>
      {pastDates.length === 0 ? (
        <NoDatesCard description={t('pageCalendar:emptyCompleted')} />
      ) : (
        pastDates?.map((pastDate) => getDetailsCard(pastDate))
      )}
    </>
  );

  const filterView = () => (
    <>
      <Styled.CardColumnHeaderArea>
        <Styled.ResetFilter onClick={resetFilter}>
          <Arrow.ChevronLeft />
          <Text.P2Semibold>{t('pageCalendar:viewAll')}</Text.P2Semibold>
        </Styled.ResetFilter>
        <Styled.CardColumnHeader>{getCardHeader()}</Styled.CardColumnHeader>
      </Styled.CardColumnHeaderArea>
      {filteredDates.map((details) => getDetailsCard(details))}
    </>
  );

  return (
    <Styled.DateCardColumn>
      {selectedDate === null ? defaultView() : filterView()}
    </Styled.DateCardColumn>
  );
}

DateCardColumn.propTypes = {
  filteredDates: PropTypes.array,
  pastDates: PropTypes.array,
  resetFilter: PropTypes.func,
  selectedDate: PropTypes.object,
  upcomingDates: PropTypes.array,
};

import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';
import * as Text from 'components/_styles/Text.style';

export const Calendar = styled('div')`
  align-items: center;
  background-color: ${colours.shade[0]};
  border-radius: 60px;
  box-shadow: ${shadows.M};
  display: flex;
  flex-direction: column;
  height: 80vh;
  overflow: hidden;

  @media (max-width: ${device.laptop}) {
    border-radius: 30px;
    height: 600px;
  }

  @media (max-width: ${device.mobileL}) {
    border-radius: 30px;
    height: 335px;
  }
`;

export const GridItem = styled('div')`
  aspect-ratio: 1 / 1;
  border-radius: 5px;
`;

export const Dot = styled('div')`
  background-color: ${colours.primary[500]};
  border-radius: 50px;
  min-height: 12px;
  width: 12px;

  @media (max-width: ${device.laptopL}) {
    min-height: 6px;
    width: 6px;
  }
`;

export const DotFiller = styled('div')`
  height: 12px;

  @media (max-width: ${device.laptopL}) {
    height: 6px;
  }
`;

export const Day = styled(GridItem)`
  background-color: transparent;

  p {
    margin: 3px;
  }

  .active {
    background-color: ${colours.primary[500]};
    cursor: unset;

    .DayText p {
      color: ${colours.shade[0]};
    }
  }

  .filtering {
    background-color: ${colours.neutralCool[50]};
    border-radius: 12px;
  }

  .clickable {
    cursor: pointer;
  }
`;

export const DayInner = styled('div')`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

export const DayText = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  justify-content: center;
  width: 100%;

  @media (max-width: ${device.laptopL}) {
    gap: 0;
  }
`;

export const DayBuffer = styled(Day)`
  cursor: default;
  &:hover {
    background-color: transparent;
  }
`;

export const GridContainer = styled('div')`
  box-sizing: border-box;
  display: grid;
  text-align: center;
  grid-template-columns: repeat(7, 1fr);
  padding: 0 44px;
  width: 100%;

  @media (max-width: ${device.laptop}) {
    gap: 0 10px;
    padding: 0 10px;
  }
`;

export const GridContainerWeeks = styled(GridContainer)`
  border-bottom: 1px solid ${colours.neutralCool[200]};
  grid-auto-rows: auto;
  padding: 30px 44px 0 44px;

  @media (max-width: ${device.laptop}) {
    gap: 0;
    padding: 0 10px;
  }
`;

export const GridContainerScroll = styled(GridContainer)`
  padding: 40px 90px 0 90px;
  overflow: auto;

  @media (max-width: ${device.laptop}) {
    padding: 20px 10px 0 10px;
  }
`;

export const MonthRow = styled('div')`
  align-items: center;
  display: flex;
  padding: 30px 44px 10px 55px;
  text-transform: capitalize;

  @media (max-width: ${device.laptop}) {
    padding: 30px 10px 10px 20px;
  }
`;

export const MonthLabel = styled(Text.P2Semibold)`
  color: ${colours.neutralCool[800]};
`;

export const WeekLabel = styled(Text.CaptionSemiboldCap)`
  color: ${colours.neutralCool[500]};
  text-transform: uppercase;

  @media (max-width: ${device.laptop}) {
    font-size: 12px;
  }

  @media (max-width: ${device.mobileL}) {
    font-size: 10px;
  }
`;

const Diversity = {
  evaluationId: 'Diversity',
  questions: [
    {
      questionNumber: 1,
      question_en:
        'You hear whispers going around the team that it is hard to get along with Jamie on a personal level. She is getting left out of social events (e.g. group lunches) because of her autistic tendencies. When she shares during team meetings, people are quiet and do not respond. Even though her performance is good, you are worried that Jamie’s lack of inclusion is affecting her mental health.',
      question_fr:
        "Vous entendez des rumeurs au sein de l'équipe selon lesquelles il est difficile de s'entendre avec Joëlle sur le plan personnel. Elle est exclue des événements sociaux (par exemple, des déjeuners de groupe) en raison de ses tendances autistiques. Lorsqu'elle s'exprime au cours des réunions d'équipe, ses collègues demeurent silencieux et ne réagissent pas. Même si ses performances sont bonnes, vous craignez que le manque d'intégration de Joëlle affecte sa santé mentale.",
      solution: 3,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Keep an eye out for Jamie but don’t interfere with the team social dynamics since it is not work-related and what the team does on their personal time is not your responsibility.',
          option_fr:
            "Soyez attentif(ive) à Joëlle, mais n'intervenez pas dans la dynamique sociale de l'équipe, car elle n'est pas liée au travail et ce que les membres de l'équipe font pendant leur temps libre n'est pas de votre ressort.",
          feedback_en:
            'Even though this seems like a personal issue, the non-inclusivity of your team can affect morale, an overall sense of belonging and productivity. It is your role as manager to be monitoring team dynamics to ensure an inclusive team for all.',
          feedback_fr:
            "Cependant, il est important de noter que la non-inclusivité au sein de votre équipe peut affecter le moral, le sentiment général d'appartenance et la productivité de la personne concernée. En tant que gestionnaire, votre rôle est de surveiller la dynamique de l'équipe afin de veiller à ce qu'elle soit inclusive pour tous.",
        },
        {
          optionNumber: 2,
          option_en:
            'Call the team without Jamie into a personal meeting with you. Openly discuss the struggles Jamie might be facing and encourage the team to be more inclusive of Jamie.',
          option_fr:
            "Convoquez l'équipe sans Joëlle à une réunion individuelle avec vous. Discutez ouvertement des difficultés auxquelles Joëlle peut être confrontée et encouragez l'équipe à l'intégrer davantage.",
          feedback_en:
            'This approach can be too direct or confrontational to address an underlying social dynamic. It may also cause the team to treat Jamie in a special way because you had asked them to, instead of understanding why.',
          feedback_fr:
            "Cette approche peut être trop directe ou conflictuelle pour aborder une dynamique sociale sous-jacente. Elle peut également amener l'équipe à traiter Joëlle d'une manière particulière parce que vous le leur avez demandé, au lieu de comprendre pourquoi.",
        },
        {
          optionNumber: 3,
          option_en:
            'Organize a training on inclusivity of neurodiversity for the team to attend.',
          option_fr:
            "Organisez une formation sur l'inclusion de la neurodiversité pour l'équipe.",
          feedback_en:
            'Yes, a lot of non-inclusivity stems from ignorance. Your team might not know what struggles Jamie might be facing. Increasing the level of awareness around neurodiversity is one way of decreasing stigma and building inclusivity.',
          feedback_fr:
            "Oui, une grande partie de la non-inclusivité découle de l'ignorance. Votre équipe ne connaît peut-être pas les difficultés auxquelles Joëlle peut être confrontée. Accroître le niveau de sensibilisation à la neurodiversité est un moyen de réduire la stigmatisation et de favoriser l'inclusion.",
        },
      ],
    },
    {
      questionNumber: 2,
      question_en:
        'The training on neurodiversity goes through challenges neurodiverse people may face, including social challenges. However, they also have strengths.',
      question_fr:
        'La formation sur la neurodiversité aborde les défis auxquels les personnes neuro diverses peuvent être confrontées, y compris les défis sociaux. Cependant, elles ont aussi des points forts.',
      solution: 3,
      options: [
        {
          optionNumber: 1,
          option_en:
            'At the end of the training, address the team as a whole. Highlight your desire to have a neurodiverse team and that your hope is that we can all be more inclusive.',
          option_fr:
            "À la fin de la formation, adressez-vous à l'ensemble de l'équipe. Soulignez votre désir d'avoir une équipe neurodiverse et partagez votre espoir de voir tout le monde devenir plus inclusif.",
          feedback_en:
            'This is usually the extent to which managers go to implement inclusivity. However, your team is unlikely to implement any changes recommended in the training unless you take the lead as manager.',
          feedback_fr:
            "En général, c'est dans cette mesure que les gestionnaires mettent en œuvre l'inclusivité. Cependant, il est peu probable que votre équipe applique les changements recommandés dans la formation si vous ne prenez pas l'initiative en tant que gestionnaire.",
        },
        {
          optionNumber: 2,
          option_en:
            'After the training, go up to individual team members and ask them for feedback. See if you can get them to relate the teaching material to Jamie’s specific situation.',
          option_fr:
            "Après la formation, rencontrez les différents membres de l'équipe et demandez-leur leur avis. Voyez si vous pouvez les amener à faire le lien entre le matériel pédagogique et la situation spécifique de Joëlle.",
          feedback_en:
            'This approach could be too confrontational and targeted to Jamie. Your goal was to increase the entire team’s awareness around neurodiversity, not just to get them to change their behaviour towards Jamie.',
          feedback_fr:
            "Cette approche pourrait être trop conflictuelle et cibler Joëlle. Votre objectif était de sensibiliser l'ensemble de l'équipe à la neurodiversité, et pas seulement de les amener à modifier leur comportement à l'égard de Joëlle.",
        },
        {
          optionNumber: 3,
          option_en:
            'Start a practice recommended at the training to build team inclusivity, such as getting everyone to share one of their strengths and challenges before team meetings, or organizing a working lunch where everyone is invited.',
          option_fr:
            "Mettez en place une pratique recommandée lors de la formation pour favoriser l'intégration de l'équipe, par exemple en demandant à chacun de faire part de l'un de ses points forts et de l'un de ses défis avant les réunions d'équipe, ou en organisant un déjeuner de travail auquel tout le monde est convié.",
          feedback_en:
            'Yes, this is a great way to put into practice a culture of inclusivity. While culture takes a while to change, with training and implementing healthy practices, it can get there.',
          feedback_fr:
            "Oui, c'est un excellent moyen de mettre en pratique une culture de l'inclusion. Bien qu'il faille un certain temps pour changer cette culture, la formation et la mise en œuvre de pratiques saines permettent d'y parvenir.",
        },
      ],
    },
    {
      questionNumber: 3,
      question_en:
        'This practice has been going on for a month now and you are curious if any of your actions has had an impact on your team and Jamie.',
      question_fr:
        "Cette pratique dure depuis un mois et vous vous demandez si l'une de vos actions a eu un impact sur votre équipe et sur Joëlle.",
      solution: 3,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Send out a survey to the team that tests their knowledge about content from the training and how effective they felt the training was.',
          option_fr:
            "Envoyez une enquête à l'équipe pour tester ses connaissances sur le contenu de la formation et pour évaluer l'efficacité de celle-ci.",
          feedback_en:
            'A survey is one way to collect information on impact, however it only measures knowledge and does not account for whether the information was put into practice.',
          feedback_fr:
            "Une enquête est un moyen de collecter des informations sur l'impact, mais elle ne mesure que les connaissances et ne permet pas de savoir si les informations ont été mises en pratique.",
        },
        {
          optionNumber: 2,
          option_en:
            'Continue to observe your team and wait for someone to come to you with a complaint before taking any further action.',
          option_fr:
            "Continuez d'observer votre équipe et attendez que quelqu'un vienne vous voir pour se plaindre avant de prendre d'autres mesures.",
          feedback_en:
            'This is a more passive approach which might not detect the underlying currents of social situations or give you a good pulse on whether your team is becoming more inclusive.',
          feedback_fr:
            "Il s'agit d'une approche plus passive qui risque de ne pas détecter les courants sous-jacents des situations sociales ou de ne pas vous permettre de savoir si votre équipe devient plus inclusive.",
        },
        {
          optionNumber: 3,
          option_en:
            'Have a one-on-one meeting with Jamie. Ask her how she feels in the team and if there is anything you could be doing to better support her.',
          option_fr:
            "Organisez un entretien en tête à tête avec Joëlle. Demandez-lui comment elle se sent au sein de l'équipe et s'il y a quelque chose que vous pourriez faire pour mieux la soutenir.",
          feedback_en:
            'Yes, this is a great way to see how Jamie is feeling and she is receiving the support she needs. Based on Jamie’s response, you can make other necessary adjustments to make sure she feels included, respected and supported by her colleagues.',
          feedback_fr:
            "Oui, c'est un excellent moyen de voir comment Joëlle se sent et si elle reçoit le soutien dont elle a besoin. En fonction de la réponse de Joëlle, vous pouvez procéder à d'autres ajustements nécessaires pour vous assurer qu'elle se sente incluse, respectée et soutenue par ses collègues.",
        },
      ],
    },
    {
      questionNumber: 4,
      question_en:
        'In a team meeting discussing delegation of responsibilities, Mark says, “I don’t mean to be offensive, but in my personal experience, I’ve found my Iranian interns are not as responsible as the Chinese.”',
      question_fr:
        "Lors d'une réunion d'équipe consacrée à la délégation des responsabilités, Marc déclare : « Je ne voudrais pas être offensant, mais d'après mon expérience personnelle, j'ai constaté que mes stagiaires iraniens n'étaient pas aussi responsables que les Chinois ».",
      solution: 2,
      options: [
        {
          optionNumber: 1,
          option_en:
            '“I’m not sure I agree with that statement Mark, what do the rest of you think?”',
          option_fr:
            "« Je ne suis pas sûr(e) d'être d'accord avec cette déclaration, Marc. Qu'en pense le reste d'entre vous? »",
          feedback_en:
            'Opening up the floor to the rest of the team might cause a heated and unproductive discussion. As the manager, you should take the lead in first offering your opinion on his statement.',
          feedback_fr:
            "Donner la parole au reste de l'équipe pourrait provoquer une discussion animée et improductive. En tant que gestionnaire, vous devez prendre l'initiative de donner votre avis sur cette déclaration.",
        },
        {
          optionNumber: 2,
          option_en:
            '“Mark, although that is your personal experience, we can’t make sweeping generalizations about employees based on them. We have all had personal experiences in the past with certain people that influence the way we perceive groups of people, but this is also subject to bias, and we need to evaluate each person on the basis of their individual merit.”',
          option_fr:
            "« Marc, bien qu'il s'agisse de votre expérience personnelle, nous ne pouvons pas en tirer des conclusions générales sur les employé(e)s. Nous avons tous eu par le passé des expériences personnelles avec certains individus qui influencent la façon dont nous percevons des groupes de personnes, mais cela est également sujet à des préjugés, et nous devons évaluer chaque personne sur la base de ses mérites individuels ».",
          feedback_en:
            'Yes, this is a kind but helpful way of addressing Mark’s comment in a way that does not discount his personal experience. Setting an example of how to respond to such comments in meetings is important as a manager.',
          feedback_fr:
            "En effet, c'est une façon polie et utile de répondre au commentaire de Marc sans minimiser son expérience personnelle. En tant que gestionnaire, il est important de montrer l'exemple sur la manière de répondre à de tels commentaires lors des réunions.",
        },
        {
          optionNumber: 3,
          option_en:
            'Share a contrasting story of one of your Iranian interns who was very responsible to offer a counterpoint to Mark’s statement.',
          option_fr:
            "Racontez une histoire qui va à l'encontre de cette déclaration : celle de l'un de vos stagiaires iraniens qui s'est montré très responsable pour offrir un contrepoint au point de vue de Marc.",
          feedback_en:
            'Instead of countering personal story with another story, you should express your disagreement directly and explain to the whole team why this generalization is not valid.',
          feedback_fr:
            "Au lieu de répondre à une histoire personnelle par une autre histoire, vous devriez exprimer directement votre désaccord et expliquer à toute l'équipe pourquoi cette généralisation n'est pas valable.",
        },
      ],
    },
    {
      questionNumber: 5,
      question_en:
        'Mark looks disgruntled but remains quiet after you respond to him.',
      question_fr:
        'Marc semble mécontent mais reste silencieux suite à votre réponse.',
      solution: 2,
      options: [
        {
          optionNumber: 1,
          option_en:
            'You feel like you have addressed the situation sufficiently now that Mark has not responded defiantly.',
          option_fr:
            "Vous avez l'impression d'avoir suffisamment abordé la situation maintenant que Marc n'a pas réagi de manière provocante.",
          feedback_en:
            'While Mark has not responded, he might not fully understand your response and a private chat is warranted to make sure everything is clear.',
          feedback_fr:
            "Si Marc n'a pas répondu, il se peut qu'il n'ait pas bien compris votre réponse et qu'une discussion privée soit nécessaire pour s'assurer que tout est clair.",
        },
        {
          optionNumber: 2,
          option_en:
            'Have a chat with Mark in private to explain further what you said in the meeting and why it could be offensive to other co-workers as well as the interns.',
          option_fr:
            'Discutez en privé avec Marc pour lui expliquer plus en détail ce que vous avez dit lors de la réunion et pourquoi cela pourrait être offensant pour certains collègues ainsi que pour les stagiaires.',
          feedback_en:
            'Yes, it is best to debrief with Mark after the meeting to see if he understands why his comment was inappropriate.',
          feedback_fr:
            "En effet, il est préférable de faire le point avec Marc après la réunion pour voir s'il comprend pourquoi son commentaire était inapproprié.",
        },
        {
          optionNumber: 3,
          option_en:
            'Continue to address the importance of inclusivity and why racist comments are not welcomed at the workplace during the meeting.',
          option_fr:
            "Continuez d'aborder l'importance de l'inclusion et les raisons pour lesquelles les commentaires racistes ne sont pas les bienvenus sur le lieu de travail.",
          feedback_en:
            'While this is an important point to cover, this should be done in private with Mark instead of in front of everyone.',
          feedback_fr:
            "Bien qu'il s'agisse d'un point important à aborder, il convient de le faire en privé avec Marc plutôt que devant tout le monde.",
        },
      ],
    },
  ],
};

export default Diversity;

import styled from 'styled-components';
import device from 'constants/devices';
import * as PageLayout from 'components/_pageLayout/_styles/PageLayout.style';

export const Page = styled(PageLayout.Page)`
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 1;
  gap: 30px;

  @media (max-width: ${device.mobileL}) {
    gap: 15px;
    padding: 15px;
  }
`;

export default Page;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// components
import ClickableListCard from 'components/pageECoach/ClickableListCard';
import ECoachResponse from 'components/pageECoach/cardTemplates/ECoachResponse';
import Loader from 'components/pageECoach/Loader';
import StyledMarkdown from 'components/_pageLayout/StyledMarkdown';
import SuggestionListItem from 'components/pageECoach/suggestions/SuggestionListItem';
import SuggestionItemDetails from 'components/pageECoach/suggestions/SuggestionItemDetails';
import DocumentList from 'components/pageECoach/documentDisplay/DocumentList';

// context
import { useSessionContext } from 'contexts/ECoachSessionContext';
import { useUser } from 'contexts/UserContext';

// hooks & helpers
import routesConfig from 'constants/routesConfig.json';
import {
  useEcoachItemsConsumer,
  LoadingStatus,
} from 'hooks/useEcoachItemsConsumer';
import { SessionProgressChoices } from 'components/pageECoach/utils';

// styles
import * as Text from 'components/_styles/Text.style';

export default function SuggestionsCard() {
  const { t } = useTranslation('pageEcoach');

  const { session, updateSession } = useSessionContext();
  const { data: user } = useUser();
  const { context, items, selectedItem, selectItem, ragSummary } =
    useEcoachItemsConsumer(session.id, routesConfig.ECOACH.SUGGESTION_ITEMS_WS);

  useEffect(() => {
    // Update session progress upon items reception
    if (items.status === LoadingStatus.LOADED) {
      updateSession(session?.id, {
        progress: SessionProgressChoices.BACK_AND_FORTH,
      });
    }
  }, [items.status, session?.id, updateSession]);

  return (
    <ECoachResponse
      status={items.status}
      loader={<Loader type={'action plan'} />}
    >
      <>
        {items.data && items.data.length > 0 ? (
          <ClickableListCard
            ItemDetailsComponent={SuggestionItemDetails}
            items={items.data}
            ListItemComponent={SuggestionListItem}
            listTitle={t('Suggestions')}
            selectedItem={selectedItem}
            selectItem={selectItem}
          />
        ) : (
          <>
            <Text.CaptionSemiboldCap>
              {t('convoPrep.Best practices at', {
                company: user.organization.name,
              })}
            </Text.CaptionSemiboldCap>
            <StyledMarkdown content={ragSummary.data} />
          </>
        )}
        {context.data?.documents?.length > 0 && (
          <DocumentList
            documentList={context.data?.documents}
            session={session.id}
          />
        )}
      </>
    </ECoachResponse>
  );
}

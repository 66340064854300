import styled from 'styled-components';

export const Container = styled('div')`
  align-items: center;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
`;

export default Container;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MuiMarkdown from 'mui-markdown';

// assets
import { Arrow } from 'untitledui-js';

// hooks
import useUserNickname from 'hooks/useUserNickname';

// style
import * as Buttons from 'components/_styles/Buttons.style';
import * as StyledTextbox from 'components/pageSlideshow/_styles/Textbox.style';

export default function Textbox(props) {
  const { backClick, contentText, ctaView, nextClick, progress } = props;

  const { t } = useTranslation();
  const nickname = useUserNickname();

  const getContentText = () => {
    if (ctaView) {
      if (progress < 0) return t('pageSlideshow:welcome', { nickname });
      return t('pageSlideshow:lastSlide2', { nickname });
    }
    return contentText;
  };

  const ctaButtonText = () => {
    if (progress < 0) return t('pageSlideshow:start');
    return t('pageSlideshow:done');
  };

  if (ctaView)
    return (
      <StyledTextbox.Container cta={ctaView.toString()}>
        <StyledTextbox.ContentCentered>
          <StyledTextbox.ContentTextCentered>
            {getContentText()}
          </StyledTextbox.ContentTextCentered>
        </StyledTextbox.ContentCentered>
        <StyledTextbox.CTA>
          <Buttons.Primary onClick={nextClick}>
            {ctaButtonText()}
          </Buttons.Primary>
        </StyledTextbox.CTA>
      </StyledTextbox.Container>
    );

  return (
    <StyledTextbox.Container>
      <StyledTextbox.Content key={progress}>
        <StyledTextbox.ContentText>
          <MuiMarkdown
            overrides={{
              strong: {
                component: 'span',
                props: {
                  style: { color: '#FF4D52', fontWeight: '900' },
                },
              },
              h1: {
                component: 'p',
                props: {
                  style: {
                    fontSize: '2rem',
                    fontWeight: '900',
                    color: '#FF4D52',
                  },
                },
              },
            }}
          >
            {getContentText()}
          </MuiMarkdown>
        </StyledTextbox.ContentText>
        <StyledTextbox.ScrollGradient />
      </StyledTextbox.Content>
      <StyledTextbox.Navigation>
        <StyledTextbox.NavButtons onClick={backClick}>
          <Arrow.ChevronLeft />
        </StyledTextbox.NavButtons>
        <StyledTextbox.GuideProgressContainer>
          <StyledTextbox.GuideProgress progress={progress} />
        </StyledTextbox.GuideProgressContainer>
        <StyledTextbox.NavButtons onClick={nextClick}>
          <Arrow.ChevronRight />
        </StyledTextbox.NavButtons>
      </StyledTextbox.Navigation>
    </StyledTextbox.Container>
  );
}

Textbox.propTypes = {
  backClick: PropTypes.func,
  contentText: PropTypes.string,
  ctaView: PropTypes.bool,
  nextClick: PropTypes.func,
  progress: PropTypes.number,
};

import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import * as Text from 'components/_styles/Text.style';

export const Tabs = styled('div')`
  border-bottom: 3px solid ${colours.neutralWarm[200]};
  box-sizing: border-box;
  display: flex;
  flex: 1;
  gap: 20px;

  @media (max-width: ${device.tablet}) {
    gap: 10px;
    padding: 0 15px;
  }

  @media (max-width: ${device.mobileM}) {
    flex-direction: column;
  }
`;

export const Tab = styled('div')`
  border-bottom: ${(props) =>
    props.active && `3px solid ${colours.primary[500]}`};
  cursor: pointer;
  display: flex;
  gap: 10px;
  margin-bottom: -3px;
  padding: 10px 8px 20px 8px;
`;

export const TabText = styled(Text.P3Semibold)`
  color: ${(props) => props.active && `${colours.primary[500]}`};

  @media (max-width: ${device.mobileL}) {
    font-size: 16px;
  }
`;

import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';

export const Slide = styled('div')`
  aspect-ratio: 16 / 9;
  box-sizing: border-box;
  gap: 30px;
  max-height: 500px;
  position: relative;
  width: unset;

  @media (max-width: ${device.laptop}) {
    border-radius: 0;
    max-height: unset;
    width: 100%;
  }
`;

export const SlideVideoEmbed = styled('video')`
  border-radius: 10px;
  height: 100%;
  object-fit: contain;
  position: absolute;
  width: 100%;
`;

export const SlideImage = styled('img')`
  border-radius: 10px;
  position: relative;
  object-fit: contain;
  width: 100%;
`;

export const SlideOverlay = styled('div')`
  background-image: linear-gradient(
    rgba(0, 0, 0, 70%),
    rgba(0, 0, 0, 0%),
    rgba(0, 0, 0, 0%)
  );
  border-radius: 10px;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

export const Titles = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 30px;
  position: absolute;
  top: 30px;
  z-index: 2;

  h2,
  p {
    color: ${colours.shade[0]};
  }

  @media (max-width: ${device.mobileM}) {
    display: none;
  }
`;

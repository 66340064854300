import React from 'react';
import PropTypes from 'prop-types';

// components
import InactiveCard from 'components/pageJourneyList/InactiveCard';

// styles
import * as Styled from 'components/pageJourneyList/_styles/PageView.style';

export default function MemberJourneyList({ journeyList }) {
  return (
    <Styled.Page>
      {journeyList.map((journey) => (
        <div key={journey.id}>
          <InactiveCard journey={journey} />
        </div>
      ))}
    </Styled.Page>
  );
}

MemberJourneyList.propTypes = {
  journeyList: PropTypes.array,
};

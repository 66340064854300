import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { Link } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// constants
import routesConfig from 'constants/routesConfig.json';

// hooks
import useApi from 'hooks/useApi';

// styles
import colours from 'constants/colours';
import * as Styled from 'components/pageLogin/_styles/ErrorMessage.style';
import * as Text from 'components/_styles/Text.style';

export default function ErrorMessage({ action, email, error }) {
  const { t } = useTranslation('pageLogin');
  const posthog = usePostHog();
  const history = useHistory();
  const { callApi } = useApi();

  const [timeLeft, setTimeLeft] = useState(60);

  useEffect(() => {
    let intervalId;

    if ((error === 'too many attempts' || !error) && timeLeft > 0) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(intervalId);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return function cleanup() {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [error, timeLeft]);

  const handleEmailResend = useCallback(() => {
    callApi('/login/', 'post', { email });
    posthog?.capture('user_rerequested_login_link');
    setTimeLeft(60);
  }, [callApi, email, posthog]);

  const returnToEmailCard = useCallback(() => {
    history.push({ pathname: `${routesConfig.LOGIN_PAGE}` });
  }, [history]);

  if (error === 'invalid email')
    return (
      <Styled.Error>
        <Styled.ErrorMessage>
          {t('errorMessages.invalidEmailLabel')}
        </Styled.ErrorMessage>
        <Styled.ErrorMessageList>
          <Styled.ErrorMessageSmall>
            {t('errorMessages.invalidEmailAction1')}
          </Styled.ErrorMessageSmall>
          <Styled.ErrorMessageSmall>
            <Trans
              i18nKey="errorMessages.invalidEmailAction2"
              components={[
                <Link
                  key="0"
                  href="mailto:info@nurau.com"
                  underline="none"
                  target="_blank"
                />,
              ]}
              ns="pageLogin"
            />
          </Styled.ErrorMessageSmall>
          <Styled.ErrorMessageSmall>
            <Trans
              i18nKey="errorMessages.invalidEmailAction3"
              components={[
                <Link
                  key="0"
                  href="https://us10.list-manage.com/survey?u=e450832626b2b44f0f73d73af&id=adb1f13f45&attribution=false"
                  underline="none"
                  target="_blank"
                />,
              ]}
              ns="pageLogin"
            />
          </Styled.ErrorMessageSmall>
        </Styled.ErrorMessageList>
      </Styled.Error>
    );

  if (error === 'invalid code')
    return (
      <Styled.Error>
        <Styled.ErrorMessage>
          {t('errorMessages.expiredCodeLabel')}
        </Styled.ErrorMessage>
        <Styled.ErrorMessage
          onClick={returnToEmailCard}
          style={{ color: colours.primary[400], cursor: 'pointer' }}
        >
          {t('errorMessages.expiredCodeAction')}
        </Styled.ErrorMessage>
        <Styled.ErrorMessage>{t('errorMessages.or')}</Styled.ErrorMessage>
        <Styled.ErrorMessage
          onClick={action}
          style={{ color: colours.primary[400], cursor: 'pointer' }}
        >
          {t('errorMessages.reEnterCode')}
        </Styled.ErrorMessage>
      </Styled.Error>
    );

  if (error === 'too many attempts')
    return (
      <Styled.Error>
        <Styled.ErrorMessage>
          {t('errorMessages.tooManyAttemptsLabel')}
        </Styled.ErrorMessage>
        {timeLeft === 0 ? (
          <Styled.ErrorMessage
            onClick={returnToEmailCard}
            style={{ color: colours.primary[400], cursor: 'pointer' }}
          >
            {t('errorMessages.expiredCodeAction')}
          </Styled.ErrorMessage>
        ) : (
          <Styled.ErrorMessage>
            {t('errorMessages.tooManyAttemptsAction', { timeLeft })}
          </Styled.ErrorMessage>
        )}
      </Styled.Error>
    );

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <Text.P2Semibold>{t('errorMessages.missingEmailLabel')}</Text.P2Semibold>
      {timeLeft === 0 ? (
        <Styled.Resend onClick={handleEmailResend}>
          {t('errorMessages.missingEmailAction')}
        </Styled.Resend>
      ) : (
        <Styled.ResendTimed>
          {t('errorMessages.missingEmailActionTimed', { timeLeft })}
        </Styled.ResendTimed>
      )}
    </div>
  );
}

ErrorMessage.propTypes = {
  action: PropTypes.func,
  email: PropTypes.string,
  error: PropTypes.string,
};

import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';
import userRoles from 'constants/userRoles.json';
import { ReactComponent as ArrowIcon } from 'assets/chevron-right.svg';

export const Arrow = styled(ArrowIcon)`
  display: block;
  margin-top: 20px;
  min-width: 24px;

  path {
    stroke: ${colours.shade[0]};
  }

  @media (max-width: ${device.laptop}) {
    margin-top: 13px;
  }
`;

export const Containter = styled('div')`
  border-radius: 50px;
  box-shadow: ${shadows.L};
  cursor: ${(props) =>
    props.userrole === userRoles.MEMBER ? 'pointer' : 'default'};
  display: flex;
  min-height: ${(props) =>
    props.userrole === userRoles.MEMBER ? '740px' : '560px'};
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  width: 100%;

  @media (max-width: ${device.laptop}) {
    flex-direction: column;
    min-height: 664px;
    overflow: hidden;
  }
`;

export const Content = styled('div')`
  align-items: flex-end;
  display: flex;
  flex: 1;
  gap: 10px;
  justify-content: space-between;
  padding: 60px;

  @media (max-width: ${device.laptop}) {
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-end;
    padding: 30px;
  }
`;

export const Cover = styled('div')`
  flex: 1;
  height: 100%;
  inset: 0;
  position: absolute;
  z-index: 1;

  @media (max-width: ${device.laptop}) {
    max-height: 100%;
  }
`;

export const CoverImage = styled('img')`
  border-radius: 50px;
  height: 100%;
  object-fit: cover;
  width: 100%;

  @media (max-width: ${device.laptop}) {
    width: calc(100vw - 20px);
  }
`;

export const CtaSection = styled('div')`
  align-items: center;
  display: flex;
  gap: 30px;
  width: 187px;
  z-index: 2;

  @media (max-width: ${device.laptop}) {
    width: 100%;
  }
`;

export const InfoSection = styled('div')`
  align-items: flex-start;
  display: flex;
  gap: 20px;
  z-index: 1;

  h1 {
    color: ${colours.shade[0]};
  }

  p {
    color: ${colours.shade[0]};
  }

  @media (max-width: ${device.laptop}) {
    gap: 0;
  }
`;

export const JourneyTitle = styled('div')`
  align-items: flex-start;
  display: flex;
  gap: 20px;
`;

export const StatusIcon = styled('div')`
  align-items: center;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  padding: 10px;

  @media (max-width: ${device.tablet}) {
    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

export const StatusComplete = styled(StatusIcon)`
  background-color: ${colours.primary[500]};

  svg path {
    stroke: ${colours.shade[0]};
  }
`;

export const Titles = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import ContentLayout from 'components/_pageLayout/ContentLayout';
import MicrolearningCardAdmin from 'components/pageJourneyId/orgAdminView/MicrolearningCardAdmin';
import Tabs from 'components/pageJourneyId/orgAdminView/Tabs';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageJourneyId/_styles/OrgAdminJourney.style';
import * as Text from 'components/_styles/Text.style';

export default function OrgAdminJourneyId({ journey }) {
  const { t } = useTranslation();
  const lang = useUserLanguage();

  const adminListContent = () => (
    <>
      {journey.microlearnings.map((microlearning) => (
        <MicrolearningCardAdmin
          key={microlearning.id}
          microlearning={microlearning}
        />
      ))}
    </>
  );

  const totalMicrolearnings = journey.microlearnings?.length || 5;

  return (
    <ContentLayout>
      <Styled.JourneyMeta>
        <Styled.JourneyMetaInfo>
          <Text.H1>{journey[`title_${lang}`]}</Text.H1>
          <Tabs
            description={journey[`description_${lang}`]}
            infoTabs={true}
            owls={journey.owls}
          />
        </Styled.JourneyMetaInfo>
      </Styled.JourneyMeta>
      <Styled.MicrolearningHeader>
        <Text.H2>{t('pageJourneyId:microlearnings')}</Text.H2>
        <Text.CaptionCap>
          {`${totalMicrolearnings} ${t('pageJourneyId:microlearnings')}`}
        </Text.CaptionCap>
      </Styled.MicrolearningHeader>
      {adminListContent()}
    </ContentLayout>
  );
}

OrgAdminJourneyId.propTypes = {
  journey: PropTypes.object,
};

import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// context
import { useUser } from 'contexts/UserContext';

// components
import ApiLoading from 'components/_pageLayout/ApiLoading';
import CalendarCard from 'components/pageCalendar/CalendarCard';
import CalendarSubscriptionButton from 'components/buttonCalendarSubscription/_index';
import DateCardColumn from 'components/pageCalendar/DateCardColumn';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import SupportAdminPageView from 'components/pageCalendar/supportAdminView/_index';

// constants
import userRoles from 'constants/userRoles.json';

// helpers & hooks
import isFinished from 'helpers/isFinished';
import useApi from 'hooks/useApi';

// styles
import * as Styled from 'components/pageCalendar/_styles/PageView.style';
import * as Text from 'components/_styles/Text.style';

export default function CalendarPage({ testUserRole }) {
  const { t } = useTranslation();
  const { data, callApi } = useApi();
  const { userRole } = useUser();

  const [selectedDate, setSelectedDate] = useState(null);
  const resetFilter = () => setSelectedDate(null);
  const role = testUserRole || userRole;

  useEffect(() => {
    if (role === userRoles.SUPPORT_ADMIN) callApi('/support_admins/schedules/');
    if (role === userRoles.MEMBER) callApi('/bookings/');
    if (role === userRoles.OWL) callApi('/schedules/');
  }, [role, callApi]);

  if (data.status === 'loading') return <ApiLoading />;

  if (role === userRoles.SUPPORT_ADMIN)
    return (
      <ErrorBoundary>
        <SupportAdminPageView scheduledDates={data.response} />
      </ErrorBoundary>
    );

  const schedules = data.response;

  const getUpcomingDates = () => {
    if (role === userRoles.MEMBER)
      return (
        schedules?.filter(
          (schedule) => !isFinished(schedule.schedule.starts_at)
        ) || []
      );
    return (
      schedules?.filter((schedule) => !isFinished(schedule.starts_at)) || []
    );
  };

  const getPastDates = () => {
    if (role === userRoles.MEMBER)
      return (
        schedules?.filter((schedule) =>
          isFinished(schedule.schedule.starts_at)
        ) || []
      );
    return (
      schedules?.filter((schedule) => isFinished(schedule.starts_at)) || []
    );
  };

  const setFilter = (day, month, year) =>
    setSelectedDate(
      dayjs().set('year', year).set('month', month).set('date', day)
    );

  const filteredDates = () => {
    if (selectedDate === null) return schedules;
    if (role === userRoles.MEMBER)
      return schedules.filter((schedule) =>
        dayjs(schedule.schedule.starts_at).isSame(selectedDate, 'day')
      );
    return schedules.filter((schedule) =>
      dayjs(schedule.starts_at).isSame(selectedDate, 'day')
    );
  };

  const schedulesFormatted = () => {
    if (role === userRoles.MEMBER)
      return schedules.map((date) =>
        dayjs(date.schedule.starts_at).format('ddd MMM DD YYYY')
      );
    return schedules.map((date) =>
      dayjs(date.starts_at).format('ddd MMM DD YYYY')
    );
  };

  return (
    <ErrorBoundary>
      <Styled.Page>
        <Styled.CalendarColumn>
          <Styled.CalendarHeader>
            <Text.H1Bold>{t('pageCalendar:calendar')}</Text.H1Bold>
            <CalendarSubscriptionButton />
          </Styled.CalendarHeader>
          <CalendarCard
            scheduledDates={schedulesFormatted()}
            selectedDate={selectedDate}
            setFilter={setFilter}
          />
        </Styled.CalendarColumn>
        <DateCardColumn
          filteredDates={filteredDates()}
          pastDates={getPastDates()}
          resetFilter={resetFilter}
          selectedDate={selectedDate}
          setFilter={setFilter}
          upcomingDates={getUpcomingDates()}
        />
      </Styled.Page>
    </ErrorBoundary>
  );
}

CalendarPage.propTypes = {
  testUserRole: PropTypes.string,
};

import styled from 'styled-components';
import device from 'constants/devices';
import * as Buttons from 'components/_styles/Buttons.style';

const Button = styled(Buttons.Primary)`
  @media (max-width: ${device.mobileL}) {
    height: 56px;
    min-width: 100%;
  }
`;

export default Button;

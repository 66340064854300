import React from 'react';
import PropTypes from 'prop-types';
import ChatBubble from 'components/pageECoach/ChatBubble';
import { LoadingStatus } from 'hooks/useEcoachItemsConsumer';
import * as Text from 'components/_styles/Text.style';

export default function ECoachResponse({
  status,
  loadingTitle,
  loader,
  children,
}) {
  return (
    <ChatBubble pointer={true}>
      {status === LoadingStatus.LOADED ? (
        children
      ) : (
        <>
          {loadingTitle && (
            <Text.SubheadingSemibold>{loadingTitle}</Text.SubheadingSemibold>
          )}
          {loader}
        </>
      )}
    </ChatBubble>
  );
}

ECoachResponse.propTypes = {
  status: PropTypes.string.isRequired,
  loadingTitle: PropTypes.string,
  loader: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// assets
import Award from 'assets/award.png';

// constants
import routesConfig from 'constants/routesConfig.json';

// styles
import * as Styled from 'components/pageJourneyId/_styles/EvaluationCard.style';
import * as Buttons from 'components/_styles/Buttons.style';
import * as Text from 'components/_styles/Text.style';

function EvaluationCard({ evaluationId, journeyId, journeyTitle }) {
  const { t } = useTranslation();
  const history = useHistory();

  const goToEvaluationPage = useCallback(() => {
    history.push({
      pathname: `${routesConfig.EVALUATION_PAGE}`,
      state: { evaluationId, journeyId, journeyTitle },
    });
  }, [history, evaluationId, journeyId, journeyTitle]);

  return (
    <Styled.Card>
      <Styled.IconSection>
        <img src={Award} />
      </Styled.IconSection>
      <Styled.DescriptionSection>
        <Styled.Description>
          <Text.H1Bold>{t('pageJourneyId:evaluationCardHeader')}</Text.H1Bold>
          <Text.Subheading>
            {t('pageJourneyId:evaluationCardDescription')}
          </Text.Subheading>
        </Styled.Description>
        <Buttons.Primary onClick={goToEvaluationPage}>
          {t('pageJourneyId:evaluationCardButton')}
        </Buttons.Primary>
      </Styled.DescriptionSection>
    </Styled.Card>
  );
}

EvaluationCard.propTypes = {
  evaluationId: PropTypes.string,
  journeyId: PropTypes.string,
  journeyTitle: PropTypes.string,
};

export default EvaluationCard;

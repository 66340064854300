import styled from 'styled-components';
import * as Buttons from 'components/_styles/Buttons.style';
import * as Cards from 'components/_styles/Cards.style';
import device from 'constants/devices';

export const Card = styled(Cards.BasicCard)`
  border-radius: 20px;
  gap: 30px;
  min-height: 750px;
  padding: 30px;
  position: relative;
  width: 580px;

  @media (max-width: ${device.tablet}) {
    background-color: white;
    box-shadow: none;
    justify-content: start;
    min-height: unset;
    width: 100%;
  }
`;

export const ImageArea = styled('div')`
  align-items: center;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (max-width: ${device.tablet}) {
    flex-grow: unset;
  }
`;

export const ImageOverflowed = styled('img')`
  max-width: 425px;
  padding: 20px 0;

  @media (max-width: ${device.tablet}) {
    max-height: 500px;
    object-fit: cover;
    object-position: 0 0;
  }
`;

export const ImageSpread = styled('img')`
  width: 100%;
  z-index: 1;

  @media (max-width: ${device.tablet}) {
    max-height: 500px;
    object-fit: cover;
  }
`;

export const ImageWithPadding = styled(ImageSpread)`
  max-width: 425px;
  padding: 50px 0 50px;

  @media (max-width: ${device.tablet}) {
    width: 80%;
  }
`;

export const Section = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

export const InnerSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 425px;
`;

export const TopSection = styled(Section)`
  margin-top: 70px;

  @media (max-width: ${device.tablet}) {
    margin-top: 10px;
  }
`;

export const BottomSection = styled(Section)`
  margin: 0;
`;

export const Navigation = styled('div')`
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  width: 100%;
  z-index: 5;

  @media (max-width: ${device.tablet}) {
    width: 100%;
  }
`;

export const NavButton = styled(Buttons.PrimaryIcon)`
  pointer-events: auto;
  z-index: 3;
`;

export const BackButton = styled(Buttons.SecondaryIcon)`
  pointer-events: auto;
  z-index: 3;
`;

export const EndButton = styled(Buttons.Primary)`
  margin: 50px;
  max-width: 300px;
  width: 100%;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// components
import StatusIcon from 'components/progressIndicators/StatusIcon';

// constants
import routesConfig from 'constants/routesConfig.json';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Text from 'components/_styles/Text.style';
import * as Styled from 'components/pageOrganization/_styles/MemberCard.style';

function MemberCardJourneyRows({ participations }) {
  const getRows = () =>
    participations.map((participation) => (
      <MemberCardJourneyRow
        key={participation.journey.id}
        participation={participation}
      />
    ));
  return <>{getRows()}</>;
}

MemberCardJourneyRows.propTypes = {
  participations: PropTypes.array,
};

export default MemberCardJourneyRows;

function MemberCardJourneyRow({ participation }) {
  const history = useHistory();
  const { t } = useTranslation();
  const lang = useUserLanguage();

  const { journey } = participation;
  const status = participation.completed_at ? 'green' : 'yellow';
  const label = participation.completed_at ? 'completed' : 'in progress';

  const toJourneyIdPage = () =>
    history.push({ pathname: `${routesConfig.JOURNEY_ID_PAGE}/${journey.id}` });

  return (
    <Styled.JourneyRows>
      <Styled.JourneyRow>
        <Styled.JourneyInfo>
          <StatusIcon label={label} status={status} />
          <div style={{ padding: '15px' }}>
            <Text.CaptionCap>{t('pageOrganization:journey')}</Text.CaptionCap>
            <Text.H3>{journey[`title_${lang}`]}</Text.H3>
          </div>
        </Styled.JourneyInfo>
        <Styled.LearnMoreButton onClick={toJourneyIdPage} variant="outlined">
          {t('pageOrganization:learnMore')}
        </Styled.LearnMoreButton>
      </Styled.JourneyRow>
    </Styled.JourneyRows>
  );
}

MemberCardJourneyRow.propTypes = {
  participation: PropTypes.object,
};

import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';

export const Suggestions = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const SuggestionCard = styled('div')`
  background-color: ${colours.shade[0]};
  border-radius: 20px;
  box-shadow: ${shadows.M};
  display: flex;
  flex: 1;
  gap: 10px;
  justify-content: space-between;

  @media (max-width: ${device.mobileM}) {
    flex-direction: column;
  }
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px;
`;

export const ActionArea = styled(Content)`
  background-color: ${colours.neutralCool[50]};
  border-left: 0.5px solid ${colours.neutralCool[200]};
  border-radius: 0 20px 20px 0;
  gap: 5px;
  justify-content: space-around;
  padding: 10px 20px;

  @media (max-width: ${device.mobileM}) {
    flex-direction: row;
    justify-content: space-around;
    padding: 15px 10px;
  }
`;

export const Action = styled('div')`
  align-items: center;
  border-radius: 50px;
  box-shadow: ${shadows.M};
  display: flex;
  justify-content: center;
  padding: 7px;

  svg {
    height: 13px;
    width: 13px;
  }
`;

export const RobotAction = styled(Action)`
  background-color: ${colours.shade[0]};

  svg path {
    stroke: ${colours.neutralCool[800]};
  }
`;

export const StarAction = styled(Action)`
  background-color: ${colours.teal[50]};

  svg path {
    stroke: ${colours.teal[800]};
  }
`;

export const XAction = styled(Action)`
  background-color: ${colours.primary[50]};

  svg path {
    stroke: ${colours.primary[800]};
  }
`;

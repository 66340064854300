import styled from 'styled-components';
import device from 'constants/devices';
import * as Text from 'components/_styles/Text.style';

export const InputWrapper = styled('div')`
  padding-top: 15px;
  width: 75%;

  @media (max-width: ${device.mobileL}) {
    width: 100%;
  }
`;

export const Layout = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
`;

export const MemberActions = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${device.mobileL}) {
    flex-direction: column;
  }
`;

export const Stat = styled('div')`
  align-items: center;
  background-color: #ffe9e8;
  border: 1px solid #ffddda;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 0 30px 70px 0;
  padding: 50px;
  width: 200px;

  @media (max-width: ${device.mobileL}) {
    padding: 15px;
    margin: 0 0 10px 0;
    width: unset;
  }
`;

export const StatsData = styled(Text.H1)`
  font-size: 100px;
  margin-bottom: 30px;

  @media (max-width: ${device.tablet}) {
    font-size: 24px;
  }
`;

export const StatsLabel = styled(Text.CaptionCap)`
  margin: 0px;
  text-align: center;
  text-transform: uppercase;
`;

export const StatsWrapper = styled('div')`
  display: flex;
  margin-top: 30px;

  @media (max-width: ${device.mobileL}) {
    flex-direction: column;
    margin: 30px 0;
  }
`;

/* eslint-disable */

const PsychologicalSafety = {
  evaluationId: 'PsychologicalSafety',
  questions: [
    {
      questionNumber: 1,
      question_en:
        'Matt is constantly chatting, interrupting and derailing meetings, which has made team meetings more unproductive. Because he is speaking too much, you notice that other employees don’t feel comfortable to speak up and his opinion monopolizes the meeting.',
      question_fr:
        "Mathieu est constamment en train de bavarder, d'interrompre et de perturber  les réunions, ce qui les rend peu productives. Parce qu'il parle trop, vous remarquez que les autres employés ne se sentent pas à l'aise pour prendre la parole et que son opinion monopolise la réunion.",
      solution: 1,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Call Matt into a one-on-one meeting to discuss his behaviour at team meetings. You tell him that he is a valuable asset but that during meetings, he needs to make space for other members of the team to speak.',
          option_fr:
            "Vous convoquez Mathieu à un entretien en tête à tête pour discuter de son comportement lors des réunions d'équipe. Vous lui dites qu'il est un atout précieux pour l’entreprise, mais que pendant les réunions, il doit laisser la place aux autres membres de l'équipe pour qu'ils puissent s'exprimer.",
          feedback_en:
            'Yes, having a private chat with Matt will help you better understand if there is a deeper reason for his behaviour during meetings (e.g. not feeling appreciated, attention seeking) and address any problem she might bring up.',
          feedback_fr:
            "Oui, une conversation privée avec Mathieu vous aidera à mieux comprendre s'il y a une raison plus profonde à son comportement pendant les réunions (par exemple, il ne se sent pas apprécié, il cherche à attirer l'attention) et à résoudre les problèmes qu'il pourrait soulever.",
        },
        {
          optionNumber: 2,
          option_en:
            'At the beginning of the next team meeting, set norms around turn taking and staying on task.',
          option_fr:
            "Au début de la prochaine réunion d'équipe, fixez des normes concernant le respect des tours de parole et des sujets de discussion à l'ordre du jour.",
          feedback_en:
            'This is very helpful but before the next meeting, you should probably talk to Mark directly, so he understands how his behaviour is affecting the team.',
          feedback_fr:
            "Cette démarche peut s’avérer très utile, mais avant la prochaine réunion, vous devriez vous adresser à Mathieu pour qu'il comprenne comment son comportement affecte l'équipe.",
        },
        {
          optionNumber: 3,
          option_en:
            'The next time Matt derails a meeting, make sure to redirect the conversation so the meeting stays on track.',
          option_fr:
            'La prochaine fois que Mathieu perturbera une réunion, assurez-vous de recentrer la conversation pour que la réunion reste sur la bonne voie.',
          feedback_en:
            'Without making Mark aware of his behaviour, you might end up feeling like you are constantly fighting to keep the meeting on track.',
          feedback_fr:
            "Si vous ne faites pas prendre conscience à Mathieu de l’aspect perturbateur de son comportement, vous risquez d'avoir l'impression de devoir constamment lutter pour que la réunion se déroule comme prévu.",
        },
      ],
    },
    {
      questionNumber: 2,
      question_en:
        'During your meeting with Matt, he says he was merely trying to help the meeting move along. He feels like it takes too long to reach decisions and letting too many people’s opinions be heard further slows down the decision-making process.',
      question_fr:
        "Lors de votre entretien avec Mathieu, il vous a dit qu'il essayait simplement de faire avancer la réunion. Il estime qu'il faut trop de temps pour prendre des décisions et que le fait de laisser s'exprimer trop de personnes ralentit le processus de prise de décision.",
      solution: 1,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Tell Matt you appreciate his honesty and that you will take more leadership over running efficient meetings, but that you also want to make sure people have a chance to be heard. Ask him for suggestions about how to run a better meeting.',
          option_fr:
            "Dites à Mathieu que vous appréciez son honnêteté et que vous prendrez les moyens nécessaires pour que les réunions soient plus efficaces, mais que vous voulez aussi vous assurer que les autres membres de l’équipe aient une chance d'être entendu(e)s. Demandez-lui de vous faire des suggestions sur la manière d'organiser de meilleures réunions.",
          feedback_en:
            'Yes, Matt has good intentions and could have helpful opinions in how to run a better meeting. You should address these issues first before assuming Matt is being insubordinate.  At the next meeting, go over norms for the meeting including having an agenda, taking turns and keeping to topics on the agenda. Monitor Matt’s behaviour and redirect the conversation if you sense it derailing.',
          feedback_fr:
            "Oui, Mathieu a de bonnes intentions et pourrait avoir des opinions utiles sur la façon de mieux organiser une réunion. Vous devriez d'abord aborder ces questions avant de supposer que Mathieu est indiscipliné. Au début de la prochaine réunion, passez en revue les normes, notamment en ce qui concerne l'ordre du jour, les tours de parole et le respect des sujets inscrits à l'ordre du jour. Surveillez le comportement de Mathieu et réorientez la conversation si vous sentez qu'elle dérape.",
        },
        {
          optionNumber: 2,
          option_en:
            'It’s clear that Matt has trouble working on your team. Talk to your supervisor about transferring him to another team that needs strong leadership and where Matt’s skills can be better utilized.',
          option_fr:
            "Il est clair que Mathieu a du mal à travailler au sein de votre équipe. Discutez avec votre supérieur de la possibilité de le transférer dans une autre équipe qui a besoin d'un leadership fort et où les compétences de Mathieu peuvent être mieux utilisées.",
          feedback_en:
            'It may not merely be a problem with Matt. You should first see if changing your team dynamic and how you run your meetings can help solve the issue.',
          feedback_fr:
            "Il ne s'agit peut-être pas seulement d'un problème avec Mathieu. Vous devriez d'abord voir si le fait de modifier la dynamique de votre équipe et la façon dont vous organisez vos réunions peuvent contribuer à résoudre le problème.",
        },
        {
          optionNumber: 3,
          option_en:
            'Matt works best as a solo performer. Remove him from the team and give him solo work so he does not interfere with your team dynamic.',
          option_fr:
            "Mathieu travaille mieux seul. Retirez-le de l'équipe et donnez-lui du travail en solo afin qu'il n'interfère pas avec la dynamique de votre équipe.",
          feedback_en:
            'While it may be true that Matt prefers to work on his own, he can develop the skills required to work constructively in teams.',
          feedback_fr:
            "S'il est vrai que Mathieu préfère travailler seul, il peut acquérir les compétences nécessaires pour travailler de manière constructive au sein d'une équipe.",
        },
      ],
    },
    {
      questionNumber: 3,
      question_en:
        'Your team is about to go to embark on a project with high risk. Everyone has a different opinion on how to execute the project given the high stakes involved. Tensions are starting to rise as everyone in the room can sense the gravity of making the right decision.',
      question_fr:
        "Votre équipe est sur le point d’entamer un projet à haut risque. Chacun a une opinion différente sur la manière d'exécuter le projet compte tenu des enjeux importants qu'il comporte. La tension commence à monter, car tous les membres de votre équipe savent qu'il est important de prendre la bonne décision.",
      solution: 1,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Acknowledge the risk and uncertainty in the project, as well as the potential to fail. However, state that this is the only way you will learn.',
          option_fr:
            "Reconnaissez le risque et l'incertitude du projet, ainsi que la possibilité d'échouer. Toutefois, indiquez que c'est la seule façon d'apprendre.",
          feedback_en:
            'Yes, taking the time to set the stage and reframe failure is very important in establishing psychological safety, especially in a tense situation like this.',
          feedback_fr:
            "En effet, prendre le temps de préparer le terrain et de recadrer l'échec est très important pour établir la sécurité psychologique, surtout dans une situation tendue comme celle-ci.",
        },
        {
          optionNumber: 2,
          option_en:
            'Call on the most qualified individual in the team to lead your team through this. Tell the team to follow their instructions because you trust them.',
          option_fr:
            "Faites appel à la personne la plus qualifiée de l'équipe pour mener votre équipe dans cette démarche. Dites aux membres de votre équipe de suivre ses instructions parce que vous faites confiance à cette personne.",
          feedback_en:
            'Although you trust them, this puts the decision-making power all in the hands of one person and may make the other members feel disqualified or unheard.',
          feedback_fr:
            "Bien que vous lui fassiez confiance, le pouvoir de décision est ainsi concentré entre les mains d'une seule personne, ce qui peut donner aux autres membres l'impression d'être incompétents ou de ne pas être entendus.",
        },
        {
          optionNumber: 3,
          option_en:
            'Go around the table and get everyone’s opinion on what they think should happen. Seek to arrive at a consensus on how you should move forward.',
          option_fr:
            "Faites un tour de table et demandez l'avis de chacun sur ce qui devrait se passer. Cherchez à parvenir à un consensus sur la manière de procéder.",
          feedback_en:
            'While seeking everyone’s opinion is one way of inviting participation, without setting the stage, this invitation could result in even more tension when disagreements arrive, making a consensus impossible.',
          feedback_fr:
            "Si demander l'opinion de chacun est une manière d'encourager la participation, sans préparation du terrain en amont, cette invitation pourrait générer davantage de tensions lors de désaccords, rendant ainsi impossible l'obtention d'un consensus.",
        },
      ],
    },
    {
      questionNumber: 4,
      question_en:
        'After you state this, the room falls quiet. Everyone is waiting for you to take the lead on where to go next.',
      question_fr:
        'Après cette déclaration, votre équipe demeure silencieuse. Tout le monde attend que vous preniez la direction des opérations.',
      solution: 1,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Invite others’ participation by asking, “This is what I propose, what am I missing?”',
          option_fr:
            "Invitez les membres de votre équipe à participer en leur demandant : « Voilà ce que je propose, qu'est-ce que j'oublie? ».",
          feedback_en:
            'Yes, this gives clear vision while also inviting your team to give you feedback.',
          feedback_fr:
            "En effet, cela permet de proposer une vision claire tout en invitant votre équipe à vous donner un retour d'information.",
        },
        {
          optionNumber: 2,
          option_en:
            'Call on someone in your team to share their idea and have the team to discuss it.',
          option_fr:
            "Demandez à un membre de votre équipe de partager son idée et demandez à l'équipe d'en discuter.",
          feedback_en:
            'While calling on others can be a way of inviting participation, by first being vulnerable and sharing your idea, you are taking the lead and allowing others to feel safe in sharing theirs.',
          feedback_fr:
            "Si faire appel à d'autres personnes peut être une façon d'inviter à la participation, en étant d'abord vulnérable et en partageant vos idées, vous prenez les devants et permettez aux autres de se sentir en sécurité en partageant les leurs.",
        },
        {
          optionNumber: 3,
          option_en:
            'Start drawing up a table listing out the various solutions with their pros and cons. Work with your team to start filling out this table together.',
          option_fr:
            'Commencez à dresser un tableau énumérant les différentes solutions, avec leurs avantages et leurs inconvénients. Travaillez avec votre équipe pour remplir ce tableau.',
          feedback_en:
            'This could be a helpful way to organize your ideas, but asking insightful questions are usually better at inviting everyone’s participation.',
          feedback_fr:
            "Cela peut être un moyen utile d'organiser vos idées, mais poser des questions perspicaces est généralement plus efficace pour inviter tout le monde à participer.",
        },
      ],
    },
    {
      questionNumber: 5,
      question_en:
        'After some discussion, you decide on a course of action. After a few weeks, one of your employees comes to you to say that the results are not looking good.',
      question_fr:
        "Après quelques discussions, vous décidez d'une ligne de conduite à suivre. Après quelques semaines, l'un(e) de vos collaborateurs(trices) vient vous voir pour vous dire que les résultats sont mauvais.",
      solution: 2,
      options: [
        {
          optionNumber: 1,
          option_en:
            'Sit down with your employee to go through what steps were taken and where things went wrong.',
          option_fr:
            "Asseyez-vous avec votre employé(e) pour passer en revue les mesures prises et les points qui n'ont pas fonctionné.",
          feedback_en:
            'This can be helpful but given the high stakes, can also turn into an opportunity to find blame with who is responsible.',
          feedback_fr:
            'Cela peut être utile, mais, compte tenu des enjeux importants, cela peut aussi se transformer en une occasion de trouver des coupables.',
        },
        {
          optionNumber: 2,
          option_en:
            'Thank your employee for sharing the bad news with you and ask them, “What did you learn from this?”',
          option_fr:
            "Remerciez votre employé(e) de vous avoir fait part de la mauvaise nouvelle et posez-lui la question suivante : « Qu'avez-vous appris de cette situation? ».",
          feedback_en:
            'Yes, recognizing the courage it took to share is responding productively. You are also turning this into a learning opportunity instead of a chance to blame.',
          feedback_fr:
            "Oui, reconnaître le courage qu'il a fallu pour partager est une réponse productive. Vous transformez également cette situation en une opportunité d'apprentissage plutôt qu'en une occasion de blâmer.",
        },
        {
          optionNumber: 3,
          option_en:
            'Point out where your employee did not exactly follow through on your instructions and how that is the cause of this bad result.',
          option_fr:
            "Indiquez où votre employé(e) n'a pas suivi exactement vos instructions et en quoi cela est la cause de ce mauvais résultat.",
          feedback_en:
            'Even though this may be true, criticism and blame are not helpful in this situation. Refrain from correction when failure is immediately presented to you and try to turn this into a learning opportunity.',
          feedback_fr:
            "Même si c'est vrai, la critique et le blâme ne sont pas utiles dans cette situation. Abstenez-vous de corriger un échec qui se présente immédiatement à vous et essayez d'en faire une occasion d'apprentissage.",
        },
      ],
    },
  ],
};

export default PsychologicalSafety;

import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';
import * as Text from 'components/_styles/Text.style';

export const ButtonSelect = styled(Button)`
  background-color: ${(props) => (props.selected ? '#ff4d52' : '#f4f4f2')};
  border-radius: 50px;
  color: ${(props) => (props.selected ? 'white' : 'black')};
  height: 60px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 900;
  opacity: ${(props) => (props.selected ? 1 : 0.6)};
  text-transform: uppercase;
  width: 100%;

  :hover {
    background-color: ${(props) => (props.selected ? '#ff4d52' : '#f4f4f2')};
    color: ${(props) => (props.selected ? 'white' : 'black')};
    opacity: ${(props) => (props.selected ? 0.8 : 1)};
  }
`;

export const TabArea = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
`;

export const Tabs = styled('div')`
  align-items: center;
  background-color: #f4f4f2;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 10px;
  width: 100%;
`;

export const Tab = styled('div')`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  justify-content: center;
  opacity: 0.6;
  padding: 15px 30px;
  transition: 0.1s;

  :hover {
    opacity: 1;
  }

  ${(props) =>
    props.selected &&
    css`
      background-color: #ff4d52;
      border-radius: 50px;
      color: white;
      font-weight: 900;
      opacity: 1;
      pointer-events: none;

      :hover {
        opacity: 0.8;
      }
    `}
`;

export const TabText = styled(Text.P1)`
  color: ${(props) => (props.selected ? 'white' : 'black')};
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
`;

import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

// components
import ApiLoading from 'components/_pageLayout/ApiLoading';
import ContentLayout from 'components/_pageLayout/ContentLayout';
import DocumentRow from 'components/pageECoach/documentDisplay/DocumentRow';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import ShareButton from 'components/pageECoach/documentDisplay/DocumentShareButton';
import ReturnToSessionButton from 'components/pageECoach/suggestions/ReturnToSessionButton';

// hooks
import useApi from 'hooks/useApi';
import useBrowserDetection from 'hooks/useBrowserDetection';
import { isMobile } from 'react-device-detect';

// styles
import * as Text from 'components/_styles/Text.style';
import * as Styled from 'components/pageECoach/_styles/Toolbar.style';

export default function DocumentPage() {
  const { data, callApi: getDocument } = useApi();
  const params = useParams();
  const browser = useBrowserDetection();
  const { id } = params;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const language = queryParams.get('language');
  const source = queryParams.get('source');
  const docPageURL = `/documents/${id}/pdf?language=${language}`;

  useEffect(() => {
    getDocument(docPageURL, 'pdf');
  }, [getDocument, docPageURL]);

  if (data.status === 'loading') return <ApiLoading />;

  if (['edge', 'ie'].includes(browser) || (browser === 'safari' && isMobile))
    return (
      <DocumentRow
        language={language}
        onPage
        docParentId={id}
        docTitle={data.response.metadata.title}
      />
    );

  return (
    <ContentLayout>
      <ErrorBoundary>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
          <Styled.Toolbar
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', gap: '10px' }}>
              {source && <ReturnToSessionButton session={source} />}
              <ShareButton docURL={docPageURL} onPage />
            </div>
            <Text.P2 style={{ fontStyle: 'italic' }}>
              Last updated: {data.response.metadata?.publication_date}
            </Text.P2>
          </Styled.Toolbar>
          <iframe
            src={data.response.pdfUrl}
            style={{ width: '100%', height: '100vh' }}
          />
        </div>
      </ErrorBoundary>
    </ContentLayout>
  );
}

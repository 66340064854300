import React from 'react';
import PropTypes from 'prop-types';

// styles
import * as Styled from 'components/pageJourneyList/_styles/ProgressBar.style';

function ProgressBar({ progress, total }) {
  const getCellType = (cellNumber) => {
    if (cellNumber === progress + 1)
      return (
        <Styled.CellCurrent key={cellNumber}>
          <Styled.CellCurrentText>
            {`${progress + 1} / ${total}`}
          </Styled.CellCurrentText>
        </Styled.CellCurrent>
      );
    if (cellNumber <= progress)
      return <Styled.CellComplete key={cellNumber}></Styled.CellComplete>;
    return <Styled.CellIncomplete key={cellNumber}></Styled.CellIncomplete>;
  };
  return (
    <Styled.Container>
      {[...Array(total)].map((e, i) => getCellType(i + 1))}
    </Styled.Container>
  );
}

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default ProgressBar;

import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';

export const AccordianCard = styled(Accordion)`
  background-color: ${colours.shade[0]};
  border-radius: 40px;
  box-shadow: ${shadows.M};
  color: black;
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  text-decoration: none;
  width: 100%;

  &::before {
    display: none;
  }

  &:last-of-type {
    border-radius: 25px;
  }

  &:first-of-type {
    border-radius: 25px;
  }

  .MuiCollapse-vertical {
    border-radius: 0 0 25px 25px;
    overflow: hidden;
  }
`;

export const AccordionOverview = styled(AccordionSummary)`
  padding: 30px;

  .MuiAccordionSummary-content {
    margin: 0;
  }

  @media (max-width: ${device.mobileM}) {
    padding: 20px;
  }
`;

export const AccordionContent = styled(AccordionDetails)`
  border-top: 2px solid #c4c4c4;
  padding: 0;
`;

export const BasicCard = styled('div')`
  background-color: ${colours.shade[0]};
  border-radius: 60px;
  box-shadow: ${shadows.L};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 580px;
  padding: 90px;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
    max-height: initial;
    max-width: initial;
  }
`;

export const BaseCard = styled('div')`
  background-color: ${colours.shade[0]};
  border-radius: 40px;
  box-shadow: ${shadows.L};
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    padding: 20px;
    border-radius: 30px;
  }
`;

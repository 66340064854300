import styled from 'styled-components';
import * as Cards from 'components/_styles/Cards.style';
import colours from 'constants/colours';
import device from 'constants/devices';
import * as Buttons from 'components/_styles/Buttons.style';
import * as Text from 'components/_styles/Text.style';

export const Page = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;

  @media (max-width: ${device.tablet}) {
    background-color: white;
  }
`;

export const CardSection = styled('div')`
  align-items: start;
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;
  overflow: auto;
  padding: 30px;

  @media (max-width: ${device.tablet}) {
    align-items: start;
    justify-content: start;
  }
`;

export const Card = styled(Cards.BasicCard)`
  gap: 10px;
  min-height: 750px;
  padding: 60px;

  @media (max-width: ${device.tablet}) {
    background-color: transparent;
    border-radius: 0;
    box-shadow: unset;
    justify-content: start;
    padding: 0;
  }
`;

export const EndCard = styled(Card)`
  align-items: center;
  justify-content: space-around;

  @media (max-width: ${device.tablet}) {
    gap: 30px;
    justify-content: center;
  }
`;

export const FeedbackBlock = styled('div')`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
`;

export const IncorrectBlock = styled(FeedbackBlock)`
  background-color: ${colours.error[100]};
`;

export const IncorrectText = styled(Text.P2Semibold)`
  color: ${colours.error[700]};
`;

export const CorrectBlock = styled(FeedbackBlock)`
  background-color: ${colours.success[100]};
`;

export const CorrectText = styled(Text.P2Semibold)`
  color: ${colours.success[700]};
`;

export const OptionBlock = styled('div')`
  background-color: ${colours.shade[0]};
  border: 2px solid ${colours.neutralCool[100]};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
`;

export const SelectionBlock = styled(OptionBlock)`
  cursor: pointer;

  &:hover {
    background-color: ${colours.neutralCool[50]};
  }
`;

export const SelectedBlock = styled(OptionBlock)`
  background-color: ${colours.neutralCool[100]};
`;

export const OptionText = styled(Text.P2Semibold)`
  color: ${colours.neutralCool[600]};
`;

export const NextButton = styled(Buttons.Primary)`
  width: 180px;
`;

export const EndButton = styled(Buttons.Primary)`
  width: 250px;
`;

export const NextButtonDisabled = styled(Buttons.PrimaryDisabled)`
  width: 180px;
`;

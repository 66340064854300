import React from 'react';
import PropTypes from 'prop-types';

// components
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';

// styles
import * as Styled from 'components/_pageLayout/_styles/ContentLayout.style';

export default function ContentLayout({ children }) {
  return (
    <ErrorBoundary>
      <Styled.ContentContainer>
        <Styled.Content>{children}</Styled.Content>
      </Styled.ContentContainer>
    </ErrorBoundary>
  );
}

ContentLayout.propTypes = {
  children: PropTypes.node,
};

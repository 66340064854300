import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import device from 'constants/devices';

export const Actions = styled(DialogActions)`
  display: flex;

  @media (max-width: ${device.mobileL}) {
    flex-direction: column;

    button {
      margin-top: 15px;
      width: 100%;
    }
  }
`;

export const Background = styled(Dialog)`
  background-color: rgba(0, 0, 0, 0.7);
  cursor: default;
`;

export const Card = styled(Paper)`
  background-color: white;
  border-radius: 25px;
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 25%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 30px;
`;

export const Content = styled(DialogContent)`
  padding: 0;
`;

export const ContentText = styled(DialogContentText)`
  padding: 0;
`;

export const Container = styled(Dialog)`
  :focus-visible {
    outline: none;
  }
`;

export const Title = styled(DialogTitle)`
  padding: 0;
`;

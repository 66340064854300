import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import colours from 'constants/colours';
import device from 'constants/devices';
import * as Cards from 'components/_styles/Cards.style';

export const ActionArea = styled('div')`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 30px;

  @media (max-width: ${device.mobileL}) {
    padding: 15px;
  }
`;

export const CloseButtomArea = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  padding: 30px 30px 0 0;

  @media (max-width: ${device.mobileL}) {
    padding: 10px 10px 0 0;
    width: 100%;
  }
`;

export const FormCard = styled(Cards.BasicCard)`
  max-height: unset;
  width: 600px;

  @media (max-width: ${device.laptop}) {
    width: 425px;
  }

  @media (max-width: ${device.mobileL}) {
    align-items: center;
    padding: 15px 0;
    width: 250px;
  }
`;

export const IconResponseArea = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0 30px;

  @media (max-width: ${device.mobileL}) {
    padding: 0 15px;
  }
`;

export const InfoArea = styled('div')`
  padding: 30px;
`;

export const InputField = styled('input')`
  background-color: white;
  border: 1px solid;
  border-radius: 15px;
  flex: 1;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  margin: 15px 0;
  padding: 15px;
`;

export const InlineLayout = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 0;

  @media (max-width: ${device.laptop}) {
    flex-direction: column;
  }
`;

export const InlineInputWrapper = styled('div')`
  align-items: center;
  display: flex;
  flex: 2;
  justify-content: flex-start;

  @media (max-width: ${device.laptop}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const InlineInputField = styled(InputField)`
  margin: 15px 5px;
  width: 100%;
`;

export const InlineActionArea = styled('div')`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4px;
  margin-top: 15px;

  button {
    margin-left: 10px;
  }

  @media (max-width: ${device.laptop}) {
    justify-content: center;
  }

  @media (max-width: ${device.mobileL}) {
    flex-direction: column;
    width: 100%;

    button {
      margin: 10px;
      width: 100%;
    }
  }
`;

export const ResponseArea = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px;

  @media (max-width: ${device.mobileL}) {
    padding: 30px 0 0 0;
    width: 90%;
  }
`;

export const SearchField = styled(TextField)`
  background-color: white;
  margin-bottom: 4px;
  margin-top: 8px;

  .MuiInputBase-adornedEnd {
    padding-right: 25px;
  }

  label {
    left: 15px;
  }

  fieldset {
    border: 1px solid;
    border-radius: 50px;
  }

  @media (max-width: ${device.mobileL}) {
    margin-bottom: 30px;
    width: 100%;
  }
`;

export const TextareaField = styled('textarea')`
  border: 1px solid ${colours.neutralCool[300]};
  border-radius: 25px;
  flex: 1;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  padding: 15px;
`;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import TextField from '@mui/material/TextField';

// components
import ApiLoading from 'components/_pageLayout/ApiLoading';

// hooks
import useApi from 'hooks/useApi';

// styles
import { AlertArea } from 'components/_styles/Alert.style';
import * as Buttons from 'components/_styles/Buttons.style';
import * as Styled from 'components/pageProfile/OwlProfile.style';
import * as Text from 'components/_styles/Text.style';

function OwlProfile({ getOwlProfile, owlProfile, user }) {
  const { t } = useTranslation();
  const { data: submission, callApi } = useApi();

  const [showEditProfile, setShowEditProfile] = useState(false);
  const [expertise, setExpertise] = useState(owlProfile.response.expertise);
  const [reason, setReason] = useState(owlProfile.response.reason);

  const showEditOwlProfile = () => setShowEditProfile(true);
  const hideEditOwlProfile = () => setShowEditProfile(false);
  const handleExpertiseChange = (event) => setExpertise(event.target.value);
  const handleReasonChange = (event) => setReason(event.target.value);

  const uploadPicture = (event) => {
    const formData = new FormData();
    const picture = event.target.files[0];
    formData.append('picture', picture);
    callApi('/owls/picture/', 'picture', formData);
    getOwlProfile('/owls/');
  };

  const updateOwlProfile = (event) => {
    const params = { reason, expertise };
    callApi('/owls/', 'put', params);
    event.preventDefault();
  };

  useEffect(() => {
    if (submission.status === 'success') getOwlProfile('/owls/');
  }, [submission, getOwlProfile]);

  useEffect(() => {
    if (owlProfile) {
      const emptyProfile =
        !owlProfile.response.expertise && !owlProfile.response.reason;
      setShowEditProfile(emptyProfile);
    }
  }, [owlProfile]);

  if (owlProfile.status === 'loading') return <ApiLoading />;
  return (
    <Styled.Card>
      <AlertArea>
        {submission.status === 'success' && (
          <Alert severity="success">{t('pageProfile:updateSuccess')}</Alert>
        )}
        {submission.status === 'error' && (
          <Alert severity="error">{t('pageProfile:updateError')}</Alert>
        )}
      </AlertArea>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Styled.Row>
          <Styled.LabelCol>
            <Styled.Label>{t('pageProfile:name')}</Styled.Label>
          </Styled.LabelCol>
          <Styled.DataCol>
            <Text.P1>{`${user.first_name} ${user.last_name}`}</Text.P1>
          </Styled.DataCol>
        </Styled.Row>
        <Styled.Row>
          <Styled.LabelCol>
            <Styled.Label>{t('pageProfile:profilePicture')}</Styled.Label>
          </Styled.LabelCol>
          <Styled.PhotoCol>
            <Styled.ImageContainer>
              <Styled.Image
                alt="Owl's picture"
                src={owlProfile.response.picture}
              />
            </Styled.ImageContainer>
            {showEditProfile && (
              <div>
                <label htmlFor="contained-button-file">
                  <Styled.Input
                    accept="image/*"
                    aria-label="contained-button-file"
                    id="contained-button-file"
                    onChange={uploadPicture}
                    type="file"
                  />
                  <IconButton aria-label="upload picture" component="span">
                    <PhotoCamera />
                  </IconButton>
                </label>
              </div>
            )}
          </Styled.PhotoCol>
        </Styled.Row>
        <Styled.Row>
          <Styled.LabelCol>
            <Styled.Label>{t('pageProfile:bio')}</Styled.Label>
            <Styled.LabelDescription>
              {t('pageProfile:expertise')}
            </Styled.LabelDescription>
          </Styled.LabelCol>
          <Styled.DataCol>
            {!showEditProfile ? (
              <Text.P1>{owlProfile.response.expertise}</Text.P1>
            ) : (
              <TextField
                id="Expertise"
                label={t('pageProfile:expertiseLabel')}
                margin="dense"
                onChange={handleExpertiseChange}
                sx={{ display: 'flex' }}
                value={expertise}
                variant="outlined"
              />
            )}
          </Styled.DataCol>
        </Styled.Row>
        <Styled.Row>
          <Styled.LabelCol>
            <Styled.Label>{t('pageProfile:motivation')}</Styled.Label>
            <Styled.LabelDescription>
              {t('pageProfile:motivationDescription')}
            </Styled.LabelDescription>
          </Styled.LabelCol>
          <Styled.DataCol>
            {!showEditProfile ? (
              <Text.P1>{owlProfile.response.reason}</Text.P1>
            ) : (
              <TextField
                defaultValue={reason}
                id="outlined-multiline-static"
                label={t('pageProfile:motivation')}
                margin="dense"
                multiline
                onChange={handleReasonChange}
                rows={4}
                sx={{ display: 'flex' }}
              />
            )}
          </Styled.DataCol>
        </Styled.Row>
      </div>
      {!showEditProfile ? (
        <Styled.ActionArea>
          <Buttons.Primary onClick={showEditOwlProfile}>
            {t('pageProfile:editProfile')}
          </Buttons.Primary>
        </Styled.ActionArea>
      ) : (
        <Styled.EditActionArea>
          <Buttons.Secondary onClick={hideEditOwlProfile}>
            {t('pageProfile:discardChanges')}
          </Buttons.Secondary>
          <Buttons.Primary onClick={updateOwlProfile}>
            {t('pageProfile:saveChanges')}
          </Buttons.Primary>
        </Styled.EditActionArea>
      )}
    </Styled.Card>
  );
}

OwlProfile.propTypes = {
  getOwlProfile: PropTypes.func,
  owlProfile: PropTypes.object,
  user: PropTypes.object,
};

export default OwlProfile;

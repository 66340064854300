import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { usePostHog } from 'posthog-js/react';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import { MediaDevices } from 'untitledui-js';

// context
import { useUser } from 'contexts/UserContext';

// styles
import * as Styled from 'components/pageECoach/_styles/Chatbar.style';

export default function Microphone({
  deactivated,
  handleCursorPosition,
  insertTranscript,
  setMicrophoneDeactivated,
}) {
  const posthog = usePostHog();
  const micRef = useRef(null);
  const [isListening, setIsListening] = useState(false);
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  const { data: user } = useUser();
  const language = user.language === 'fr' ? 'fr-FR' : 'en-CA';

  const deactivate = useCallback(() => {
    SpeechRecognition.stopListening();
    setIsListening(false);
    setMicrophoneDeactivated(true);
    resetTranscript();
  }, [resetTranscript, setMicrophoneDeactivated]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) deactivate();
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [deactivate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (micRef.current && !micRef.current.contains(event.target))
        deactivate();
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [micRef, deactivate, isListening]);

  useEffect(() => {
    if (deactivated) deactivate();
  }, [deactivated, deactivate]);

  useEffect(() => {
    const addPunctuation = (text) => {
      let processed =
        user.language === 'en'
          ? text
              .replace(/\s*\bperiod\b/gi, '.')
              .replace(/\s*\bcomma\b/gi, ',')
              .replace(/\s*\bcolon\b/gi, ':')
              .replace(/\s*\bsemicolon\b/gi, ';')
              .replace(/\bopen quote\b/gi, '"')
              .replace(/\s*\bclose quote\b/gi, '"')
              .replace(/\s*\bquestion mark\b/gi, '?')
              .replace(/\s*\bexclamation mark\b/gi, '!')
              .replace(/\s*\bexclamation point\b/gi, '!')
              .replace(/\bopen bracket\b/gi, '(')
              .replace(/\s*\bclose bracket\b/gi, ')')
          : text
              .replace(/\s*\bpoint d'interrogation\b/gi, '?')
              .replace(/\s*\bpoint d'exclamation\b/gi, '!')
              .replace(/\s*\bpoint-virgule\b/gi, ';')
              .replace(/\s*\bdeux-points\b/gi, ':')
              .replace(/\s*\bpoint\b/gi, '.')
              .replace(/\s*\bvirgule\b/gi, ',')
              .replace(/\bguillemet\b/gi, '"')
              .replace(/\parenthèse ouvrante\b/gi, '(')
              .replace(/\s*\bparenthèse fermante\b/gi, ')');

      // Capitalize the first letter of each sentence
      processed = processed.replace(/(?<=\.\s|^\s*)([a-z])/g, (match) =>
        match.toUpperCase()
      );

      return processed;
    };

    const processedText = addPunctuation(transcript);
    if (transcript && !deactivated) insertTranscript(processedText);
  }, [deactivated, insertTranscript, transcript, user.language]);

  if (!browserSupportsSpeechRecognition) return <></>;

  const handleListening = () => {
    if (isListening) {
      deactivate();
      handleCursorPosition();
      posthog?.capture('deactivated_microphone');
    } else {
      SpeechRecognition.startListening({ continuous: true, language });
      setIsListening(true);
      setMicrophoneDeactivated(false);
      posthog?.capture('activated_microphone');
    }
  };

  return (
    <Styled.Microphone
      active={isListening}
      onClick={handleListening}
      ref={micRef}
    >
      <MediaDevices.Microphone01 />
    </Styled.Microphone>
  );
}

Microphone.propTypes = {
  deactivated: PropTypes.bool,
  handleCursorPosition: PropTypes.func,
  insertTranscript: PropTypes.func,
  setMicrophoneDeactivated: PropTypes.func,
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import { Arrow } from 'untitledui-js';

// context
import { useUser } from 'contexts/UserContext';

// hooks
import useApi from 'hooks/useApi';

// styles
import colours from 'constants/colours';
import * as Styled from 'components/navbar/_styles/LanguageToggle.style';
import * as Text from 'components/_styles/Text.style';

export default function LanguageToggle({ getUser }) {
  const { data: submission, callApi: updateUser } = useApi();
  const { data: user } = useUser();
  const { t } = useTranslation('navbar');

  const switchLanguage = () => {
    const newLanguage = user.language === 'en' ? 'fr' : 'en';
    const params = { language: newLanguage };
    updateUser('/users/language/', 'put', params);
  };

  const languageLabel = user.language === 'en' ? 'Français' : 'English';
  const languageLabelMobile = user.language === 'en' ? 'FR' : 'EN';

  useEffect(() => {
    if (submission.status === 'success') getUser('/users/me');
  }, [submission, getUser]);

  return (
    <div style={{ cursor: 'pointer' }} onClick={switchLanguage}>
      <Styled.LanguageTextMobile>
        {languageLabelMobile}
      </Styled.LanguageTextMobile>
      <Styled.LanguageToggleDesktop>
        <Arrow.SwitchHorizontal01
          size={20}
          stroke={colours.neutralCool[500]}
          strokeWidth={1.5}
        />
        <Styled.LanguageText>
          <Text.P1Semibold style={{ color: colours.neutralCool[500] }}>
            {t('Switch to')}
          </Text.P1Semibold>
          <Text.P1 style={{ color: colours.neutralCool[500] }}>
            {languageLabel}
          </Text.P1>
        </Styled.LanguageText>
      </Styled.LanguageToggleDesktop>
    </div>
  );
}

LanguageToggle.propTypes = {
  getUser: PropTypes.func,
};

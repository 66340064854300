import styled from 'styled-components';
import * as Cards from 'components/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';
import device from 'constants/devices';
import colours from 'constants/colours';
import shadows from 'constants/dropShadows';

export const Section = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
  }
`;

export const Card = styled(Cards.BasicCard)`
  border-radius: 30px;
  box-shadow: ${shadows.M};
  box-sizing: unset;
  display: flex;
  flex: 0 1 40%;
  flex-direction: row;
  gap: 10px;
  justify-content: unset;
  max-width: unset;
  padding: 30px;

  @media (max-width: ${device.tablet}) {
    border-radius: 30px;
    flex-direction: column;
  }
`;

export const InfoSection = styled('div')`
  align-items: start;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 50%;
  flex-direction: column;
  gap: 12px;

  @media (max-width: ${device.tablet}) {
    padding: 30px;
  }
`;

export const Title = styled(Text.H5Bold)`
  text-transform: capitalize;
`;

export const Participants = styled(Text.P2Semibold)`
  text-transform: lowercase;
`;

export const Info = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const InfoRow = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 5px;
`;

export const ScheduleSection = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Row = styled('div')`
  align-items: center;
  border-bottom: ${(props) =>
    props.order === 100 ? 'none' : `1px solid ${colours.neutralCool[200]}`};
  border-top: ${(props) =>
    props.order === 0 ? 'none' : `1px solid ${colours.neutralCool[200]}`};
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  padding: 15px 0;
`;

export const Dates = styled('div')`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 30px;
  width: 65%;
`;

export const DateHistory = styled(Text.P2Semibold)`
  color: ${colours.neutralCool[400]};
`;

export const DateSection = styled('div')`
  align-items: center;
  display: flex;
  gap: 13px;
  padding: 5px 10px 5px 0;
`;

export const StatusContainer = styled('div')`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 54px;

  @media (max-width: ${device.tablet}) {
    width: 40px;
  }
`;

export const StatusComplete = styled('div')`
  align-items: center;
  background-color: ${colours.primary[500]};
  border-radius: 40px;
  display: flex;
  justify-content: center;
  padding: 4px;

  svg {
    height: 14px;
    width: 14px;
  }

  svg path {
    stroke: ${colours.shade[0]};
    stroke-width: 3;
  }
`;

import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { General, Maps, Users } from 'untitledui-js';

// components
import JoinLobbyButton from 'components/buttonJoinLobby/_index';

// helpers & hooks
import isFinished from 'helpers/isFinished';
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageJourneyId/_styles/GroupsCard.style';
import * as Text from 'components/_styles/Text.style';

const ScheduleRow = ({ date, journey, numRows, order }) => {
  const { t } = useTranslation();
  const lang = useUserLanguage();

  const [isLive, setIsLive] = useState(false);

  const getDateString = (d) => {
    if (isLive)
      return (
        <Text.P2Semibold>{t('pageJourneyId:completed_on')}</Text.P2Semibold>
      );
    if (dayjs(date.starts_at).isBetween(dayjs(), dayjs().add(7, 'day')))
      return (
        <Text.P2Semibold>
          {dayjs(d).locale(lang).format('dddd')}
        </Text.P2Semibold>
      );
    if (isFinished(date.starts_at))
      return (
        <Styled.DateHistory>{`${order + 1}. ${dayjs(d)
          .locale(lang)
          .format('LLL')}`}</Styled.DateHistory>
      );
    return (
      <Text.P2Semibold>{`${order + 1}. ${dayjs(d)
        .locale(lang)
        .format('LLL')}`}</Text.P2Semibold>
    );
  };

  useEffect(() => {
    if (date.starts_at.isToday()) setIsLive(true);
  }, [date, setIsLive]);

  const getTime = (d) => {
    if (isLive)
      return (
        <JoinLobbyButton
          bookingId={date.id}
          journeyId={journey.id}
          microlearningId={date.microlearning.id}
          microlearningTitle={date.microlearning[`title_${lang}`]}
        />
      );
    if (isFinished(date.starts_at) && !isLive)
      return (
        <Styled.StatusComplete>
          <General.Check />
        </Styled.StatusComplete>
      );
    return (
      <Text.P2Semibold>{`${dayjs(d).format(
        'LT'
      )} (${dayjs.tz.guess()})`}</Text.P2Semibold>
    );
  };

  return (
    <Styled.Row order={numRows - 1 === order ? 100 : order}>
      <Styled.Dates>{getDateString(date.starts_at)}</Styled.Dates>
      {getTime(date.starts_at)}
    </Styled.Row>
  );
};

ScheduleRow.propTypes = {
  date: PropTypes.object,
  journey: PropTypes.object,
  numRows: PropTypes.number,
  order: PropTypes.number,
};

function GroupCard({ journey }) {
  const { t } = useTranslation();
  const getHeader = (date) => dayjs(date).format('dddd LT');
  const getLanguage = (l) => (l === 'en' ? 'English' : 'Français');

  return (
    <Styled.Section>
      {journey.timetables.map((timetable) => (
        <Styled.Card key={timetable.id}>
          <Styled.InfoSection>
            <Styled.Title>
              {getHeader(timetable.schedules[0].starts_at)}
            </Styled.Title>
            <Styled.Info>
              <Styled.InfoRow>
                <Users.Users01 />
                <Styled.Participants>
                  {timetable.participants.length} {t('pageJourneyId:people')}
                </Styled.Participants>
              </Styled.InfoRow>
              <Styled.InfoRow>
                <Maps.Globe02 />
                <Text.P2Semibold>
                  {getLanguage(timetable.language)}
                </Text.P2Semibold>
              </Styled.InfoRow>
            </Styled.Info>
          </Styled.InfoSection>
          <Styled.ScheduleSection>
            {timetable.schedules.map((schedule, order) => (
              <ScheduleRow
                key={schedule.id}
                date={schedule}
                journey={journey}
                numRows={timetable.schedules.length}
                order={order}
              />
            ))}
          </Styled.ScheduleSection>
        </Styled.Card>
      ))}
    </Styled.Section>
  );
}

GroupCard.propTypes = {
  journey: PropTypes.object,
};

export default GroupCard;

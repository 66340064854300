const device = {
  mobileS: '320px',
  mobileSMin: '321px',
  mobileM: '375px',
  mobileMMin: '376px',
  mobileL: '425px',
  mobileLMin: '426px',
  mobileXL: '768px',
  mobileXLMin: '769px',
  tablet: '768px',
  tabletMin: '769px',
  laptop: '1024px',
  laptopMin: '1025px',
  laptopL: '1440px',
  laptopLMin: '1441px',
  desktopS: '1925px',
  desktopSMin: '1926px',
  desktop: '2560px',
  desktopMin: '2561px',
  desktopL: '3840px',
  desktopLMin: '3841px',
};

export default device;

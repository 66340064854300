import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

// assets
import { General } from 'untitledui-js';

// components
import ConfirmDialog from 'components/_pageLayout/ConfirmDialog';

// style
import * as Header from 'components/pageECoach/_styles/Toolbar.style';
import * as Styled from 'components/pageECoach/_styles/DocumentRow.style';

export default function DocumentShareButton({ docURL, onPage }) {
  const { t } = useTranslation('pageEcoach');
  const posthog = usePostHog();

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const handleConfirmDialogOpen = () => setIsOpenConfirmDialog(true);
  const handleConfirmDialogClose = () => setIsOpenConfirmDialog(false);

  const getBaseUrl = () => {
    const parsedUrl = new URL(window.location.href);
    return parsedUrl.origin;
  };

  const url = `${getBaseUrl()}${docURL}`;

  const confirmDialog = (
    <ConfirmDialog
      cancelLabel={t('Close')}
      contentText={t(
        'Link copied! Please note that this link will only work if the recipient has a valid Nurau account.'
      )}
      handleClose={handleConfirmDialogClose}
      isOpen={true}
    />
  );

  const copyToClipboard = () => {
    posthog?.capture('user_clicks_share_document');
    navigator.clipboard
      .writeText(url)
      .then(handleConfirmDialogOpen)
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  const ButtonComponent = onPage ? Header.ToolbarButton : Styled.Button;

  return (
    <>
      <ButtonComponent onClick={copyToClipboard}>
        <General.Share03 size={'15'} />
        {t('Share')}
      </ButtonComponent>
      {isOpenConfirmDialog && confirmDialog}
    </>
  );
}

DocumentShareButton.propTypes = {
  docURL: PropTypes.string,
  onPage: PropTypes.bool,
};

// React essentials
import React from 'react';
import { Formik } from 'formik';

// Localization
import { useTranslation } from 'react-i18next';

// Form validation and initial values
import { getInitialValues } from 'components/pageDemoRegistration/initialValues';
import { getValidationSchema } from 'components/pageDemoRegistration/validationSchema';

// Custom hooks
import useRegister from 'hooks/demo/useRegister';

// Handlers
import {
  onRegistrationError,
  onRegistrationSuccess,
} from 'components/pageDemoRegistration/registrationHandlers';

// Components and styles
import { Card } from 'components/pageDemoRegistration/_styles/RegistrationCard.style';
import IntroCard from 'components/pageDemoRegistration/introCard/IntroCard';
import RegistrationForm from 'components/pageDemoRegistration/RegistrationForm';

const DemoRegistration = () => {
  const { t } = useTranslation('pageDemoRegistration');
  const { register } = useRegister(onRegistrationSuccess, onRegistrationError);

  return (
    <>
      <IntroCard t={t} />
      <Card id="registration">
        <Formik
          initialValues={getInitialValues()}
          validationSchema={getValidationSchema(t)}
          onSubmit={register}
        >
          {(formikProps) => <RegistrationForm {...formikProps} />}
        </Formik>
      </Card>
    </>
  );
};

export default DemoRegistration;

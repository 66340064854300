import React from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { UserProvider } from './contexts/UserContext';
import App from './App';
// eslint-disable-next-line
import dayjs from 'helpers/dayjs';
// eslint-disable-next-line import/no-named-as-default
import reportWebVitals from './reportWebVitals';
import './i18n';

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
  integrations: [new Integrations.BrowserTracing()],
  environment: `${process.env.REACT_APP_SENTRY_ENVIRONMENT}`,
  tracesSampleRate: 0.1,
});

const root = createRoot(document.getElementById('root'));

const isPosthogEnabled = process.env.REACT_APP_POSTHOG_ENABLED === 'true';

if (isPosthogEnabled) {
  posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_HOST,
    person_profiles: 'identified_only',
    session_recording: {
      maskTextSelector: '*',
    },
  });
}

const children = (
  <UserProvider>
    <App />
  </UserProvider>
);

root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      {isPosthogEnabled ? (
        <PostHogProvider client={posthog}>{children}</PostHogProvider>
      ) : (
        children
      )}
    </GoogleReCaptchaProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export const Input = styled(TextField)`
  background-color: #f5f5f3;
  border: none;
  border-radius: 50px;
  margin-top: 30px;

  fieldset {
    border: none;
    border-radius: 50px;
  }

  input {
    align-items: center;
    font-size: 16px;
  }

  label {
    font-size: 16px;
  }

  label.Mui-focused,
  label.MuiFormLabel-filled {
    top: -10px;
  }

  .MuiSelect-select {
    align-items: center;
    display: flex;
    font-size: 16px;
  }
`;

export const SelectInput = styled(Input)`
  border-radius: ${(props) =>
    props.selectopen === 'true' ? '25px 25px 0 0' : '50px'};
`;

export const OtherPronounsInput = styled(Input)`
  margin-top: 10px;
`;

export const ListItem = styled(MenuItem)`
  font-size: 16px;
`;

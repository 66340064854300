import styled from 'styled-components';
import * as Text from 'components/_styles/Text.style';
import device from 'constants/devices';

export const Container = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  min-width: 100px;

  @media (max-width: ${device.tablet}) {
    align-items: flex-start;
    margin-right: 0;
    min-width: 70px;
  }

  @media (max-width: ${device.mobileL}) {
    margin-right: 10px;
    min-width: unset;
  }
`;

export const IconsWrapper = styled('div')`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: flex;
  height: 50px;
  justify-content: center;
  margin-bottom: 5px;
  position: relative;
  width: 50px;

  @media (max-width: ${device.tablet}) {
    height: 40px;
    width: 40px;
  }
`;

export const StatusIcons = styled('div')`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 20px;
  justify-content: center;
  position: absolute;
  right: -5px;
  top: -5px;
  width: 20px;
`;

export const PinkIcon = styled(StatusIcons)`
  background-color: #ff85ce;
`;

export const GreenIcon = styled(StatusIcons)`
  background-color: #20b249;
`;

export const YellowIcon = styled(StatusIcons)`
  background-color: #ffc120;
`;

export const Label = styled(Text.CaptionCap)`
  text-align: center;

  @media (max-width: ${device.tablet}) {
    display: none;
  }
`;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// components
import { Files } from 'untitledui-js';

import ApiLoading from 'components/_pageLayout/ApiLoading';
import ShareButton from 'components/pageECoach/documentDisplay/DocumentShareButton';

// hooks
import useApi from 'hooks/useApi';
import useBrowserDetection from 'hooks/useBrowserDetection';
import { isMobile } from 'react-device-detect';

// styles
import * as Styled from 'components/pageECoach/_styles/DocumentRow.style';

export default function DocumentRow({
  docParentId,
  docTitle,
  language,
  onPage,
  session,
}) {
  const { data, callApi: getDocument } = useApi();
  const browser = useBrowserDetection();

  const docPageURL = `/documents/${docParentId}/pdf?language=${language}`;
  const docPageURLWithSource = `${docPageURL}&source=${session}`;

  useEffect(() => {
    getDocument(docPageURL, 'pdf');
  }, [getDocument, docPageURL]);

  if (data.status === 'loading') return <ApiLoading />;

  const routerLink = () => (
    <Styled.StyledLink
      as={Styled.RouterLink}
      href={docPageURLWithSource}
      to={docPageURLWithSource}
      target="_blank"
      rel="noopener noreferrer"
    >
      {docTitle}
    </Styled.StyledLink>
  );

  const downloadLink = () => (
    <Styled.StyledLink
      download={docTitle}
      href={data?.response.pdfUrl}
      style={{ textDecoration: 'none', fontSize: `${onPage && '18px'}` }}
    >
      {docTitle}
    </Styled.StyledLink>
  );

  if (browser === 'safari' && !isMobile)
    return (
      <Styled.Row>
        {routerLink()}
        <ShareButton docURL={docPageURL} />
      </Styled.Row>
    );

  if (['safari', 'edge', 'ie'].includes(browser) && onPage)
    return (
      <Styled.StyledCard>
        <div style={{ width: 'fit-content' }}>
          <Files.File05 size={'80'} strokeWidth={'1.25'} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {downloadLink()}
          <ShareButton docURL={docPageURL} />
        </div>
      </Styled.StyledCard>
    );

  if (['safari', 'edge', 'ie'].includes(browser) && isMobile && !onPage)
    return (
      <Styled.Row>
        {downloadLink()}
        <ShareButton docURL={docPageURL} />
      </Styled.Row>
    );

  return (
    <Styled.Row>
      {routerLink()}
      <ShareButton docURL={docPageURL} />
    </Styled.Row>
  );
}

DocumentRow.propTypes = {
  docParentId: PropTypes.string,
  docTitle: PropTypes.string,
  language: PropTypes.string,
  onPage: PropTypes.bool,
  session: PropTypes.string,
};

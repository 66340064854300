import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// components
import DetailsCard from 'components/pageCalendar/DetailsCard';

// helpers & hooks
import checkIfLive from 'helpers/checkIfLive';
import useUserLanguage from 'hooks/useUserLanguage';

function DetailsCardMember({ details }) {
  const lang = useUserLanguage();
  const [isLive, setIsLive] = useState(false);
  const startDate = details.schedule.starts_at.toString();

  useEffect(() => {
    if (checkIfLive(startDate)) setIsLive(true);
  }, [setIsLive, startDate]);

  return (
    <DetailsCard
      bookingId={details.id}
      caption={details.schedule.microlearning.journeys[0][`title_${lang}`]}
      dateString={dayjs(details.schedule.starts_at).locale(lang).format('LLL')}
      isLive={isLive}
      journeyId={details.schedule.microlearning.journeys[0].id}
      microlearningId={details.schedule.microlearning.id}
      microlearningTitle={details.schedule.microlearning[`title_${lang}`]}
    />
  );
}

export default DetailsCardMember;

DetailsCardMember.propTypes = {
  details: PropTypes.object,
};

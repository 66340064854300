import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

// assets
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

// components
import AvatarIcons from 'components/pageOnboarding/AvatarIcons';

// constants
import { avatars, colours } from 'constants/avatars';

// hooks
import useApi from 'hooks/useApi';

// styles
import * as Styled from 'components/pageOnboarding/_styles/AvatarForm.style';
import * as StyledCard from 'components/pageOnboarding/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';

function AvatarForm(props) {
  const {
    handleSectionReturn,
    markComplete,
    setSubmittedAvatarForm,
    submittedAvatarForm,
  } = props;

  const { data, callApi } = useApi();
  const { t } = useTranslation('pageOnboarding');
  const posthog = usePostHog();

  const [selectedAvatar, setSelectedAvatar] = useState(
    submittedAvatarForm.avatar
  );
  const [selectedColour, setSelectedColour] = useState(
    submittedAvatarForm.avatar_color
  );
  const [selectedTab, setSelectedTab] = useState('icon');
  const nextPageClick = () => {
    const params = {
      avatar: selectedAvatar,
      avatar_color: selectedColour,
    };
    callApi('/members/avatar/', 'put', params);
    setSubmittedAvatarForm(params);
    posthog?.capture('user_set_their_avatar');
  };

  useEffect(() => {
    if (data.status === 'success') markComplete(4);
  }, [data.status, markComplete]);

  const backPageClick = () => handleSectionReturn(4);
  const tabClick = (tab) => setSelectedTab(tab);
  const avatarClick = (avatar) => setSelectedAvatar(avatar);
  const colourClick = (colour) => setSelectedColour(colour);

  return (
    <StyledCard.Card>
      <Styled.TopSection>
        <StyledCard.InnerSection>
          <Styled.AvatarSelectedArea>
            <Text.H5Bold style={{ margin: '20px' }}>{t('avatar')}</Text.H5Bold>
            <Styled.SelectedAvatar style={{ backgroundColor: selectedColour }}>
              <AvatarIcons avatar={selectedAvatar} colour={selectedColour} />
            </Styled.SelectedAvatar>
            <Styled.Tabs>
              <Styled.TabButton
                aria-label={t('icon')}
                onClick={() => tabClick('icon')}
                style={{
                  background: selectedTab === 'colour' && '#f4f4f2',
                  color: selectedTab === 'colour' && 'black',
                }}
              >
                {t('icon')}
              </Styled.TabButton>
              <Styled.TabButton
                aria-label={t('colour')}
                onClick={() => tabClick('colour')}
                style={{
                  background: selectedTab === 'icon' && '#f4f4f2',
                  color: selectedTab === 'icon' && 'black',
                }}
              >
                {t('colour')}
              </Styled.TabButton>
            </Styled.Tabs>
          </Styled.AvatarSelectedArea>
          {selectedTab === 'icon' ? (
            <Styled.AvatarArea>
              {avatars.map((avatar) => (
                <Styled.Cell key={avatar}>
                  <Styled.AvatarSelect
                    onClick={() => avatarClick(avatar)}
                    selected={avatar === selectedAvatar}
                  >
                    <AvatarIcons avatar={avatar} colour={selectedColour} />
                  </Styled.AvatarSelect>
                </Styled.Cell>
              ))}
            </Styled.AvatarArea>
          ) : (
            <Styled.AvatarArea>
              {colours.map((colour) => (
                <Styled.Cell key={colour}>
                  <Styled.AvatarSelect
                    onClick={() => colourClick(colour)}
                    selected={colour === selectedColour}
                  >
                    <AvatarIcons avatar={selectedAvatar} colour={colour} />
                  </Styled.AvatarSelect>
                </Styled.Cell>
              ))}
            </Styled.AvatarArea>
          )}
        </StyledCard.InnerSection>
      </Styled.TopSection>
      <StyledCard.Navigation>
        <StyledCard.BackButton aria-label={t('back')} onClick={backPageClick}>
          <NavigateBeforeIcon />
        </StyledCard.BackButton>
        <StyledCard.NavButton aria-label={t('next')} onClick={nextPageClick}>
          <NavigateNextIcon />
        </StyledCard.NavButton>
      </StyledCard.Navigation>
    </StyledCard.Card>
  );
}

AvatarForm.propTypes = {
  handleSectionReturn: PropTypes.func,
  markComplete: PropTypes.func,
  setSubmittedAvatarForm: PropTypes.func,
  submittedAvatarForm: PropTypes.object,
};

export default AvatarForm;

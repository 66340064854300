import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import ConfirmDialog from 'components/_pageLayout/ConfirmDialog';

// styles
import * as Buttons from 'components/_styles/Buttons.style';

const MemberFormDelete = ({ deleteCallApi, member }) => {
  const { t } = useTranslation();

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const handleConfirmDialogOpen = () => setIsOpenConfirmDialog(true);
  const handleConfirmDialogClose = () => setIsOpenConfirmDialog(false);

  const deleteMember = (event) => {
    const params = { email: member.user.email };
    deleteCallApi(`/members/${member.id}`, 'delete', params);
    handleConfirmDialogClose();
    event.preventDefault();
  };

  const confirmDialog = (
    <ConfirmDialog
      cancelLabel={t('pageOrganization:cancel')}
      contentText={t('pageOrganization:deleteConfirmText')}
      handleClose={handleConfirmDialogClose}
      isOpen={true}
      successCallback={deleteMember}
      successLabel={t('pageOrganization:confirm')}
      title={t('pageOrganization:deleteConfirmHeader')}
    />
  );

  return (
    <>
      <Buttons.Secondary onClick={handleConfirmDialogOpen}>
        {t('pageOrganization:delete')}
      </Buttons.Secondary>
      {isOpenConfirmDialog && confirmDialog}
    </>
  );
};

MemberFormDelete.propTypes = {
  deleteCallApi: PropTypes.func,
  member: PropTypes.object,
};

export default MemberFormDelete;

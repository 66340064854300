// eslint-disable-next-line import/prefer-default-export
export const getInitialValues = () => ({
  first_name: '',
  last_name: '',
  email: '',
  language: '',
  industry: '',
  additional_infos: {
    user_specific_industry: '',
    job_title: '',
    experience_in_years: '',
    company_size: '',
    team_size: '',
    issues: '',
  },
});

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// components
import ApiLoading from 'components/_pageLayout/ApiLoading';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import MemberJourneyId from 'components/pageJourneyId/memberView/_index';
import OrgAdminJourneyId from 'components/pageJourneyId/orgAdminView/_index';

// context
import { useUser } from 'contexts/UserContext';

// constants
import userRoles from 'constants/userRoles.json';

// hooks
import useApi from 'hooks/useApi';
import OwlJourneyId from './owlView/_index';

export default function JourneyIdPage({ testUserRole }) {
  const { data, callApi: getJourney } = useApi();
  const params = useParams();
  const { userRole } = useUser();

  const { id } = params;
  const role = testUserRole || userRole;

  useEffect(() => {
    if (role === userRoles.MEMBER) getJourney(`/members/journeys/${id}`);
    if (role === userRoles.OWL) getJourney(`/owls/journeys/${id}`);
    if (role === userRoles.ADMIN) getJourney(`/hr/journeys/${id}/`);
  }, [role, getJourney, id]);

  if (data.status === 'loading') return <ApiLoading />;
  if (role === userRoles.MEMBER)
    return (
      <ErrorBoundary>
        <MemberJourneyId journey={data.response} />
      </ErrorBoundary>
    );

  if (role === userRoles.OWL)
    return (
      <ErrorBoundary>
        <OwlJourneyId journey={data.response} />
      </ErrorBoundary>
    );

  if (role === userRoles.ADMIN)
    return (
      <ErrorBoundary>
        <OrgAdminJourneyId journey={data.response} />
      </ErrorBoundary>
    );

  return null;
}

JourneyIdPage.propTypes = {
  testUserRole: PropTypes.string,
};

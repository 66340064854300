import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';
import ClickableInput from 'components/pageECoach/ClickableInput';
import ECoachChatGuideMessage from 'components/pageECoach/chatStart/ECoachChatGuideMessage';

// contexts
import { useUser } from 'contexts/UserContext';

// styles
import * as InputStyle from 'components/pageECoach/_styles/MessageInputs.style';
import { Stack } from '@mui/material';

export default function ChatGuide({
  openConvoPrep,
  openPromptHelper,
  openSampleCases,
}) {
  const { t } = useTranslation('pageEcoach');
  const { data: user } = useUser();

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Stack spacing={3}>
        <ECoachChatGuideMessage />
        <ChatBubble pointer={true} sentByUser>
          <InputStyle.ButtonsColumn>
            <ClickableInput
              inputAction={openPromptHelper}
              inputText={t(
                "I need advice on a challenge I'm currently facing."
              )}
              inputType="light"
            />
            <ClickableInput
              inputAction={openConvoPrep}
              inputText={t(
                'I need help preparing for a conversation with someone.'
              )}
              inputType="light"
            />
            {user.is_demo && (
              <ClickableInput
                inputAction={openSampleCases}
                inputText={t('sampleCases.I want to try out some examples')}
                inputType="light"
              />
            )}
          </InputStyle.ButtonsColumn>
        </ChatBubble>
      </Stack>
    </div>
  );
}

ChatGuide.propTypes = {
  openConvoPrep: PropTypes.func,
  openPromptHelper: PropTypes.func,
  openSampleCases: PropTypes.func,
};

// useIndustries.js
import { useEffect, useState } from 'react';
import useApi from 'hooks/useApi';

const useCompanySizeChoices = (currentLanguage) => {
  const { data, callApi } = useApi();
  const [companySizeChoices, setCompanySizeChoices] = useState([]);

  useEffect(() => {
    callApi('/demo/company-size-choices', 'get', null, {
      'Accept-Language': currentLanguage,
    });
  }, [callApi, currentLanguage]);

  useEffect(() => {
    if (data.status === 'success' && data.response) {
      setCompanySizeChoices(data.response.company_sizes);
    }
  }, [data]);

  return companySizeChoices;
};

export default useCompanySizeChoices;

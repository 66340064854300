import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { VideoSDKMeeting } from '@videosdk.live/rtc-js-prebuilt';
import { Redirect, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Logo from 'assets/logo.svg';
import { useUser } from 'contexts/UserContext';
import useApi from 'hooks/useApi';
import ApiLoading from 'components/_pageLayout/ApiLoading';

function ChatroomPage() {
  const { data: user } = useUser();
  const { callApi: completeBooking } = useApi();
  const { state } = useLocation();
  const { bookingId, journeyId, microlearningId, microlearningTitle, roomId } =
    state || {};
  const { t } = useTranslation();
  const displayName = user.nickname || user.first_name;

  useEffect(() => {
    if (!roomId) return <Redirect to="/" />;
    const complete = () => {
      if (bookingId) {
        completeBooking(`/bookings/${bookingId}/complete/`, 'post');
      }
    };

    setTimeout(complete, 300000); // 300000 = 5 minutes in milliseconds

    const genericConfig = {
      name: `${displayName} ${user.last_name[0]}.`,
      meetingId: `${roomId}`,
      apiKey: `${process.env.REACT_APP_VIDEO_SDK_API_KEY}`,
      containerId: null,
      webcamEnabled: true,
      participantCanToggleSelfWebcam: true,
      participantCanToggleSelfMic: true,
      participantCanLeave: true,
      raiseHandEnabled: true,
      chatEnabled: true,
      waitingScreen: {
        imageUrl: `${process.env.REACT_APP_APP_URL}${Logo}`,
        text: `${t('pageChatroom:joining')} "${microlearningTitle}"`,
      },
      branding: {
        enabled: true,
        logoURL: `${process.env.REACT_APP_APP_URL}${Logo}`,
        name: `${microlearningTitle}`,
        poweredBy: false,
      },
      theme: 'LIGHT',
      joinScreen: {
        visible: true,
        title: `${microlearningTitle}`,
      },
      layout: {
        type: 'GRID',
        priority: 'PIN',
        gridSize: 25,
      },
      hls: {
        enabled: false,
        autoStart: false,
        playerControlsVisible: false,
      },
      livestream: {
        autoStart: false,
        enabled: false,
      },
      debug: `${process.env.REACT_APP_DEBUG}`,
    };
    const owlConfig = {
      redirectOnLeave: `${process.env.REACT_APP_APP_URL}/journeys/`,
      screenShareEnabled: true,
      whiteboardEnabled: true,
      micEnabled: true,
      maxResolution: 'hd',
      toggleWhiteboard: true,
      videoConfig: {
        resolution: 'h720p_w960p',
        optimizationMode: 'motion',
        multiStream: true,
      },
      audioConfig: {
        quality: 'high_quality',
      },
      screenShareConfig: {
        resolution: 'h720p_15fps',
        optimizationMode: 'text',
      },
      permissions: {
        pin: true,
        askToJoin: false,
        toggleParticipantMic: true,
        toggleParticipantWebcam: true,
        toggleParticipantScreenshare: true,
        toggleParticipantMode: true,
        canCreatePoll: true,
        toggleHls: false,
        drawOnWhiteboard: true,
        toggleWhiteboard: true,
        toggleRecording: false,
        removeParticipant: true,
        endMeeting: true,
        changeLayout: true,
      },
    };
    const memberConfig = {
      redirectOnLeave: `${process.env.REACT_APP_APP_URL}/journeys/${journeyId}?open_feedback_form=${microlearningId}`,
      screenShareEnabled: false,
      whiteboardEnabled: false,
      micEnabled: false,
      maxResolution: 'sd',
      videoConfig: {
        resolution: 'h360p_w640p',
        optimizationMode: 'motion',
        multiStream: false,
      },
      audioConfig: {
        quality: 'speech_low_quality',
      },
      permissions: {
        pin: false,
        askToJoin: false,
        toggleParticipantMic: false,
        toggleParticipantWebcam: false,
        toggleParticipantScreenshare: false,
        toggleParticipantMode: false,
        canCreatePoll: false,
        toggleHls: false,
        drawOnWhiteboard: true,
        toggleWhiteboard: false,
        toggleRecording: false,
        removeParticipant: false,
        endMeeting: false,
        changeLayout: false,
      },
    };

    const config = user.owl
      ? { ...genericConfig, ...owlConfig }
      : { ...genericConfig, ...memberConfig };

    const meeting = new VideoSDKMeeting();
    meeting.init(config);

    return () => {
      clearTimeout(complete);
    };
  }, [
    user,
    displayName,
    journeyId,
    microlearningId,
    microlearningTitle,
    roomId,
    bookingId,
    completeBooking,
    t,
  ]);
  if (!roomId) return <Redirect to="/" />;
  return <ApiLoading />;
}

ChatroomPage.propTypes = {
  bookingId: PropTypes.string,
  journeyId: PropTypes.string,
  microlearningTitle: PropTypes.string,
  roomId: PropTypes.string,
};

export default ChatroomPage;

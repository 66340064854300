import * as yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const getValidationSchema = (t) =>
  yup.object({
    first_name: yup
      .string()
      .required(t('pageDemoRegistration:validation:first_name_required')),
    last_name: yup
      .string()
      .required(t('pageDemoRegistration:validation:last_name_required')),
    email: yup
      .string()
      .email(t('pageDemoRegistration:validation:invalid_email'))
      .required(t('pageDemoRegistration:validation:email_required')),
    language: yup
      .string()
      .required(t('pageDemoRegistration:validation:language_required')),
    industry: yup
      .string()
      .required(t('pageDemoRegistration:validation:industry_required')),
    additional_infos: yup.object({
      user_specific_industry: yup.string(),
      job_title: yup
        .string()
        .required(t('pageDemoRegistration:validation:job_title_required')),
      experience_in_years: yup
        .number()
        .typeError(
          t('pageDemoRegistration:validation:experience_in_years_invalid')
        )
        .required(
          t('pageDemoRegistration:validation:experience_in_years_required')
        ),
      company_name: yup
        .string()
        .required(t('pageDemoRegistration:validation:company_name_required')),
      company_size: yup
        .string()
        .required(t('pageDemoRegistration:validation:company_size_required')),
      issues: yup.string(),
    }),
  });

import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';

// styles
import * as Text from 'components/_styles/Text.style';

export default function ECoachChatGuideMessage() {
  const { t } = useTranslation('pageEcoach');

  return (
    <ChatBubble pointer>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Text.P2>
          {t("Let's get started by better understanding your goals.")}
        </Text.P2>
        <Text.P2>
          {t('Which of the following would you like help with right now?')}
        </Text.P2>
      </div>
    </ChatBubble>
  );
}

import styled, { keyframes } from 'styled-components';
import Alert from '@mui/material/Alert';
import * as Text from 'components/_styles/Text.style';

export const AlertArea = styled('div')`
  padding: 15px 0;
`;

export const ButtonsArea = styled('div')`
  display: flex;
`;

const slideInDown = keyframes`
  from,
  50%,
  75%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  0% {
    opacity: 0;
    transform: translate3d(0, -200px, 0) scaleY(3);
  }

  50% {
    transform: translate3d(0, -20, 0) scaleY(0.95);
  }

  75% {
    transform: translate3d(0, -5px, 0) scaleY(0.985);
  }

  to {
    transform: translate3d(0, -15px, 0);
  }
`;

export const Container = styled(Alert)`
  align-items: center;
  background-color: black;
  border-radius: 0;
  box-sizing: border-box;
  color: white;
  left: 0;
  padding: 15px 10px 0px 10px;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 100000;
  animation-duration: 0.75s;
  animation-fill-mode: both;
  animation-name: ${slideInDown};
`;

export const Message = styled(Text.P1)`
  align-items: center;
  color: white;
  display: flex;
  justify-content: center;
`;

import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { Maps, Time } from 'untitledui-js';

// components
import ActiveCard from 'components/pageJourneyList/ActiveCard';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageJourneyList/_styles/ActiveCardOwl.style';
import * as Text from 'components/_styles/Text.style';

function ActiveCardOwlUpcoming({ journey, schedules }) {
  const { t } = useTranslation();
  const lang = useUserLanguage();
  const formatOrg = (schedule) =>
    schedule.organization ? `${schedule.organization} | ` : '';

  const featuredInfo = () => (
    <Styled.UpcomingSection>
      <Styled.UpcomingData>
        <Text.H5Bold>{t('pageJourneyList:upcoming')}</Text.H5Bold>
        <div>
          {schedules.slice(0, 3).map((schedule) => (
            <Styled.UpcomingRow key={schedule.id}>
              <Text.P2Semibold>
                {schedule.microlearning[`title_${lang}`]}
              </Text.P2Semibold>
              <Styled.RowInfo>
                <Styled.RowCol>
                  <Time.Clock />
                  <Text.P2Semibold>
                    {`${dayjs(schedule.starts_at)
                      .locale(lang)
                      .format('LLL')} (${dayjs.tz.guess()})`}
                  </Text.P2Semibold>
                </Styled.RowCol>
                <Styled.RowCol>
                  <Maps.Globe02 />
                  <Text.P2Semibold>
                    {`${formatOrg(schedule)}${
                      schedule.language === 'en' ? 'English' : 'Français'
                    }`}
                  </Text.P2Semibold>
                </Styled.RowCol>
              </Styled.RowInfo>
            </Styled.UpcomingRow>
          ))}
        </div>
      </Styled.UpcomingData>
      <Styled.SeeMore>
        <Text.P2Semibold>{t('pageJourneyList:details')}</Text.P2Semibold>
      </Styled.SeeMore>
    </Styled.UpcomingSection>
  );

  return <ActiveCard featuredInfo={featuredInfo} journey={journey} />;
}

ActiveCardOwlUpcoming.propTypes = {
  journey: PropTypes.object,
  schedules: PropTypes.array,
};

export default ActiveCardOwlUpcoming;

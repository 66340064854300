import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isBetween);
dayjs.extend(isToday);

function checkIfLive(startsAt) {
  const callDuration = 20;
  const start = dayjs(startsAt);
  const earliestArrival = start.subtract(5, 'minute');
  const latestArrival = start.add(callDuration, 'minute');
  return start.isToday() && dayjs().isBetween(earliestArrival, latestArrival);
}

export default checkIfLive;

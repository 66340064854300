import React, { useEffect, useState, useMemo } from 'react';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';
import Chatbar from 'components/pageECoach/chatbar/Chatbar';
import Loader from 'components/pageECoach/Loader';

// context & hooks
import { useSessionContext } from 'contexts/ECoachSessionContext';
import useWebsocketSetup from 'hooks/useWebsocketSetup';

// styles
import * as Page from 'components/pageECoach/_styles/ChatArea.style';

// utils
import routesConfig from 'constants/routesConfig.json';

export default function ECoachBackAndForth() {
  // #region Setup hooks call
  const { session } = useSessionContext();
  const [steps, setSteps] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebsocketSetup(
    routesConfig.ECOACH.BACK_AND_FORTH_WS
  );
  // #endregion Setup hooks call

  const sendUserInput = (message) => {
    sendJsonMessage({
      action: 'get',
      request_id: Date.now(),
      pk: session.id,
      data: {
        user_input: message,
      },
    });

    // Temporarily add new user input as a step in order to display it while loading
    setSteps((prevSteps) => [...prevSteps, { user_inputs: [{ message }] }]);
    setIsLoading(true);
  };

  // #region Initial conversation loading
  useEffect(() => {
    if (readyState === WebSocket.OPEN && session?.id) {
      setIsLoading(true);

      sendJsonMessage({
        action: 'list',
        request_id: Date.now(),
        pk: session.id,
      });
    }
  }, [readyState, sendJsonMessage, session?.id]);
  // #endregion Initial conversation loading

  // #region Receive bot response
  useEffect(() => {
    if (lastJsonMessage === null) {
      return;
    }

    const { action, data } = lastJsonMessage;

    if (action === 'list') {
      setSteps(data);
    } else if (action === 'get') {
      setSteps((prevSteps) => {
        // Remove inputs where output was not received as it will be included with output
        const filteredSteps = prevSteps.filter(
          (step) => step.outputs !== undefined
        );
        return [...filteredSteps, data];
      });
    }

    setIsLoading(false);
  }, [lastJsonMessage]);
  // #endregion Receive bot response

  const messageCards = useMemo(
    () =>
      steps?.map((step) => {
        const userInputCards = [];
        if (step.user_inputs) {
          userInputCards.push(
            Object.values(step.user_inputs).map((userInput, index) => (
              <ChatBubble
                key={index}
                simpleString={userInput.message}
                pointer={true}
                sentByUser
              />
            ))
          );
        }

        const outputCards = [];
        if (step.outputs) {
          outputCards.push(
            Object.values(step.outputs).map((output, index) => (
              <ChatBubble
                key={index}
                simpleString={output.message}
                pointer={true}
              />
            ))
          );
        }

        return [...userInputCards, ...outputCards];
      }),
    [steps]
  );

  return (
    <>
      {messageCards}
      {isLoading && (
        <Page.ECoachSection>
          <ChatBubble pointer={true}>
            <Loader inline />
          </ChatBubble>
        </Page.ECoachSection>
      )}
      {!isLoading && (
        <Chatbar autoFocus initialInput="" onSubmit={sendUserInput} />
      )}
    </>
  );
}

import styled, { css } from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';

export const BottomSection = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 15px;
  justify-content: flex-end;
  width: 100%;
`;

export const Card = styled('div')`
  background-color: ${colours.shade[0]};
  border-radius: 50px;
  box-shadow: ${shadows.XS};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 700px;
  padding: 40px;

  @media (max-width: ${device.laptopL}) {
    border-radius: 35px;
    max-width: unset;
    min-width: 100%;
    padding: 30px;
  }

  ${(props) =>
    props.noCalendarOnPage &&
    css`
      justify-content: flex-start;

      @media (max-width: ${device.desktop}) {
        flex-basis: 30%;
        max-width: unset;
      }

      @media (max-width: ${device.laptopL}) {
        border-radius: 35px;
        flex-basis: 47%;
        min-width: unset;
        padding: 30px;
      }

      @media (max-width: ${device.tablet}) {
        max-width: unset;
        min-width: 100%;
      }
    `}
`;

export const DetailRow = styled('div')`
  align-items: center;
  display: flex;
  gap: 13px;
  padding: 5px 10px 5px 5px;

  svg path {
    stroke: ${colours.shade[100]};
  }
`;

export const LiveIndicator = styled('div')`
  align-items: center;
  background-color: ${colours.primary[500]};
  border-radius: 30px;
  display: flex;
  justify-content: center;
  gap: 13px;
  padding: 5px 10px 5px 5px;
  width: fit-content;

  svg path {
    stroke: ${colours.shade[0]};
  }

  p {
    color: white;
  }
`;

export const TopSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import AddMemberButton from 'components/pageOrganization/AddMemberButton';
import Alert from 'components/_pageLayout/Alert';
import ApiLoading from 'components/_pageLayout/ApiLoading';
import ContentLayout from 'components/_pageLayout/ContentLayout';
import MembersList from 'components/pageOrganization/MembersList';
import MembersListSearch from 'components/pageOrganization/MembersListSearch';

// hooks
import useApi from 'hooks/useApi';

// styles
import * as Styled from 'components/pageOrganization/_styles/AdminHeader.style';
import * as Text from 'components/_styles/Text.style';

export default function OrganizationPage({ testData }) {
  const { t } = useTranslation();
  const { data, callApi: getMembersList } = useApi();
  const membersList = testData || data;

  const [alert, setAlert] = useState();
  const [refreshmembersList, setRefreshMembersList] = useState();
  const [membersListFiltered, setMembersListFiltered] = useState([]);

  const closeAlert = () => setAlert(null);

  useEffect(() => {
    if (!testData) getMembersList('/members/');
  }, [getMembersList, testData]);

  useEffect(() => {
    if (refreshmembersList) getMembersList('/members/');
    setRefreshMembersList(false);
  }, [refreshmembersList, getMembersList]);

  if (membersList.status !== 'success') return <ApiLoading />;
  return (
    <ContentLayout>
      {alert && <Alert handleClose={closeAlert} message={alert} />}
      <Text.H1>{t('pageOrganization:header')}</Text.H1>
      <Styled.Layout>
        <Styled.MemberActions>
          <MembersListSearch
            membersList={membersList.response}
            setMembersListFiltered={setMembersListFiltered}
          />
          <AddMemberButton
            setAlert={setAlert}
            setRefreshMembersList={setRefreshMembersList}
          />
        </Styled.MemberActions>
      </Styled.Layout>
      <MembersList
        getMembersList={getMembersList}
        membersList={membersListFiltered}
        setAlert={setAlert}
      />
    </ContentLayout>
  );
}

OrganizationPage.propTypes = {
  testData: PropTypes.object,
};
